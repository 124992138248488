import { setAlert } from './alert';

// ACTION TYPES
const BU_DETAILS = 'BU_DETAILS';
const BU_DETAILS_S = 'BU_DETAILS_S';
const BU_DETAILS_F = 'BU_DETAILS_F';

const START_BU = 'START_BU';
const START_BU_S = 'START_BU_S';
const START_BU_F = 'START_BU_F';

const GET_LOGS = 'GET_LOGS';
const GET_LOGS_S = 'GET_LOGS_S';
const GET_LOGS_F = 'GET_LOGS_F';

const RESTORE_NOW = 'RESTORE_NOW';
const RESTORE_NOW_S = 'RESTORE_NOW_S';
const RESTORE_NOW_F = 'RESTORE_NOW_F';

const SET_BU_IN = 'SET_BU_IN';
const SET_BU_IN_S = 'SET_BU_IN_S';
const SET_BU_IN_F = 'SET_BU_IN_F';

// INITIAL
const initialState = {
    backups: [],
    logs: [],
    interval: ''
};

// REDUCER
export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case BU_DETAILS_S:
            return {
                ...state,
                backups: payload.result || [],
                interval: payload.interval || ''
            };
        case GET_LOGS_S:
            return {
                ...state,
                logs: payload.result || [],
                totalLogs: payload.count || 0
            };
        default:
            return state;
    }
}

// ACTION CREATORS
export function getBackUpDetails() {
    return {
        types: [BU_DETAILS, BU_DETAILS_S, BU_DETAILS_F],
        promise: fetch => fetch.get('api/Clientbackup')
    };
}

export function startBackup() {
    return dispatch => dispatch({
        types: [START_BU, START_BU_S, START_BU_F],
        promise: fetch => fetch.post('api/Clientbackup')
    }).then(() => {
        return dispatch(getBackUpDetails());
    })
}

export function getLogs(offset, limit) {
    return {
        types: [GET_LOGS, GET_LOGS_S, GET_LOGS_F],
        promise: fetch => fetch.get('api/Notificationslist', {
            params: {
                offset,
                limit
            }
        })
    };
}

export function restoreNow(backup_id) {
    return {
        types: [RESTORE_NOW, RESTORE_NOW_S, RESTORE_NOW_F],
        promise: fetch => fetch.post('api/Restoredb', {
            params: {
                backup_id
            }
        })
    };
}

export function setBUInterval(interval) {
    return dispatch => dispatch({
        types: [SET_BU_IN, SET_BU_IN_S, SET_BU_IN_F],
        promise: fetch => fetch.post('api/Auto_backup', {
            data: {
                interval
            }
        })
    }).then((res) => {
        dispatch(setAlert(`Backup interval set succesfully.`, "success"));
    
        return res;
      }).catch(() => {
        dispatch(setAlert(`Error setting backup interval.`, "error"));
    
        return Promise.reject();
      });
}
