import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  TextField,
  Button,
  Container,
  Select,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  Paper,
  Autocomplete,
} from "@mui/material";
import { Remove, Add } from "@mui/icons-material";
import { updateAForm, getAForm } from "../../redux/modules/forms";
import { Helmet } from "react-helmet";
import { validateEmail } from "../../utils/validators";
import config from '../../config';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import EmailInput from "../CreateForm/EmailInput";
const reorder = (list, startIndex, endIndex) => {
  var result = Array.from(list);
  var removed = result.splice(startIndex, 1)[0];
  result.splice(endIndex, 0, removed);
  return result;
}

function EditForm(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [inputFields, setInputFields] = useState([
    {
      field_id: "",
      label: "",
      type: "",
      placeholder: "",
      is_required: "false",
      options: [],
      editable: "0",
      filter: "0",
      common: "0",
      lead_timeline: "0",
    },
  ]);


  /*Formfileds */
  const [formfields, setformfields] = useState({
    title: "",
    description: "",
    description: "",
    category: "",
    fields: [],
    email_subject: "",
    email_to: [],
    email_cc: [],
    success_message: "",
    auto_reply: "",
  });

  const [open, setOpen] = React.useState(false);
  const [selectedField, setSelectedField] = useState();
  const [checkedE, setCheckedE] = useState(false);
  const [currentOptions, setCurrentOptions] = useState([""]);
  // const [emaileditor, setEmaileditor] = useState();

  useEffect(() => {
    props.getAForm(params.id);
  }, []);

  useEffect(() => {
    setformfields(props.form);
    setInputFields(props.fields);
  }, [props.form]);

  const handleClose = () => {
    setOpen(false);
  };
  const formChange = (event, newValue, name) => {
    event?.persist();
    if (name == "email_cc" || name == "email_to") {
      setformfields(() => ({ ...formfields, [name]: newValue }));
    } else if (event.target.name == "recaptcha") {
      setformfields(() => ({
        ...formfields,
        [event.target.name]: event.target.checked ? 1 : 0,
      }));
    } else {
      setformfields(() => ({
        ...formfields,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const updateForm = (event) => {
    event.preventDefault();
    formfields.fields = inputFields;

    props
      .updateAForm(formfields)
      .then(() => {
        navigate("/forms");
      })
      .catch((error) => {
        console.error("Something went wrong!", error);
      });
  };

  const addField = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const promptEditOption = (index, event) => {
    inputFields.map((i, ind) => {
      if (ind === index) {
        if (i.options) {
          setCurrentOptions(i.options);
        } else {
          setCurrentOptions([""]);
        }
        setSelectedField(index);
        setOpen(true);
      }
    });
  };

  const handleChangeInput = (index, event, check) => {
    const newInputFields = inputFields.map((i, ind) => {
      const item = i;
      if (item.errorFieldId === index) {
        if (item.label.trim().toLowerCase() != event.target.value.trim().toLowerCase()) {
          item.error = "";
          item.errorFieldId = -1;
        }
      }

      if (ind === index) {
        if (event.target.name === 'label') {
          if (item.common === "0" && config.commonFields.includes((event.target.value.trim()).toLowerCase())) {
            item.error = "You cannot use this label.";
          } else if ( item.common === "0" && inputFields.filter((inF, k) => (k != ind) && !inF.error && (inF.label.trim()).toLowerCase() === (event.target.value.trim()).toLowerCase()).length) {
            item.errorFieldId = inputFields.findIndex((inF, k) => (k != ind) && !inF.error && (inF.label.trim()).toLowerCase() === (event.target.value.trim()).toLowerCase());
            item.error = "Field with same name already exist.";
          } else {
            item.error = "";
          }
        }

        item[event.target.name] = check
          ? event.target.checked
            ? "1"
            : "0"
          : event.target.value;

        if (event.target.value == "radio" || event.target.value == "checkbox") {
          item["editable"] = "0";
        }

        if (
          !check &&
          event.target.name == "type" &&
          !["text", "select"].includes(event.target.value)
        ) {
          item["filter"] = "0";
        }

        if (event.target.name == "type" && ["select", "radio", "checkbox"].includes(event.target.value)) {
          item.options = [''];
          setCurrentOptions(['']);
          setSelectedField(index)
          setOpen(true);
        }
      }
      return item;
    });

    setInputFields(newInputFields);
  };

  const handleOptionChange = (index, event) => {
    const updatedArray = [...currentOptions];
    updatedArray[index] = event.target.value;
    setCurrentOptions(updatedArray);
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        field_id: "",
        label: "",
        type: "",
        placeholder: "",
        is_required: "false",
        options: [""],
        editable: "0",
        filter: "0",
        common: "0",
        lead_timeline: "0",
        order: `${inputFields.length + 1}`
      },
    ]);
  };

  const handleAddOptions = () => {
    setCurrentOptions([...currentOptions, ""]);
  };

  const handleRemoveFields = (fieldIndex) => {
    const values = [...inputFields];
    values.splice(fieldIndex, 1);
    setInputFields(values.map((field, i) => ({ ...field, order: `${i + 1}` })));
  }
  const handleRemoveOptions = option => {
    const values = [...currentOptions];
    values.splice(
      values.findIndex((value) => value === option),
      1
    );
    setCurrentOptions(values);
  };

  const saveOptions = () => {
    const newInputFields = inputFields.map((i, index) => {
      if (selectedField === index) {
        i["options"] = currentOptions.map((co) => co.trim());
      }

      return i;
    });

    setInputFields(newInputFields);
    setCurrentOptions([""]);
    setOpen(false);
  };




  const onDragEnd = ({ destination, source }) => {
    console.log("ONDRAGEND")
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(inputFields, source.index, destination.index);

    // Create a new array here!
    const newItemOrdered = newItems.map(
      (el, index) => (el[index] = { ...el, order: index + 1 })
    );

    setInputFields(newItemOrdered);
  }



  const totalFilterFields = inputFields.filter((inFields => inFields.filter == "1")).length;
  const optionsIncomplete = !!inputFields.filter(inFi => ['select', 'radio', 'checkbox'].includes(inFi.type) && inFi.options.filter(o => !o.trim()).length).length;
  const errorExist = !!inputFields.filter(inFi => inFi.error).length;

  return (
    <React.Fragment>
      <Helmet>
        <title>Edit Form</title>
        <meta name="description" content="Edit Form page" />
      </Helmet>

      <Typography
        className="form_heading"
        variant="h6"
        gutterBottom
        align="center"
      >
        UPDATE FORM {formfields.id}
      </Typography>

      <Container margin="10" maxWidth="md">
        <form onSubmit={updateForm} className="Addform">
          <Paper elevation={2} className="Addform">
            <Typography variant="h6" gutterBottom align="left">
              Update Form details
            </Typography>
            {/* style={{ backgroundColor: '#f0f0f0', height: '100vh' }} */}
            <Grid container spacing={4}>
              <Grid className="grid_pad_x" xs={12}>
                <TextField
                  onChange={formChange}
                  required
                  id="title"
                  name="title"
                  label="Title"
                  fullWidth
                  autoComplete="off"
                  value={formfields.title}
                  variant="standard"
                />
              </Grid>
              <Grid className="grid_pad_x" xs={12}>
                <TextField
                  onChange={formChange}
                  required
                  id="category"
                  name="category"
                  label="Category"
                  fullWidth
                  autoComplete="category"
                  value={formfields.category}
                  variant="standard"
                />
              </Grid>
              <Grid className="grid_pad_x" xs={12}>
                <TextField
                  onChange={formChange}
                  id="description"
                  name="description"
                  label="Description"
                  fullWidth
                  autoComplete="description"
                  value={formfields.description}
                  variant="standard"
                />
              </Grid>
              <Grid className="grid_pad_x" xs={12}>
                <EmailInput
                  formfields={formfields}
                  formChange={formChange}
                  validateEmail={validateEmail}
                  fieldName={"email_to"}
                  label={"Email to"}
                  valueField={formfields.email_to}
                />
              </Grid>
              <Grid className="grid_pad_x" xs={12}>
                  <EmailInput
                  formfields={formfields}
                  formChange={formChange}
                  validateEmail={validateEmail}
                  fieldName={"email_cc"}
                  label={"Email cc"}
                  valueField={formfields.email_cc}
                />
              </Grid>
              <Grid className="grid_pad_x" xs={12}>
                <TextField
                  onChange={formChange}
                  id="email_subject"
                  name="email_subject"
                  label="Email Subject"
                  fullWidth
                  autoComplete="email_subject"
                  value={formfields.email_subject}
                  variant="standard"
                />
              </Grid>
              <Grid className="grid_pad_x" xs={12}>
                <TextField
                  onChange={formChange}
                  id="success_message"
                  name="success_message"
                  label="Success Message"
                  fullWidth
                  autoComplete="success_message"
                  value={formfields.success_message}
                  variant="standard"
                />
              </Grid>

              <Grid className="grid_pad_x" xs={12}>
                <TextField
                  onChange={formChange}
                  id="auto_reply"
                  name="auto_reply"
                  label="Auto Reply"
                  fullWidth
                  autoComplete="success_message"
                  value={formfields.auto_reply}
                  variant="standard"
                />
              </Grid>

              <Grid xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formfields.recaptcha == "1"}
                      onChange={formChange}
                      name="recaptcha"
                    />
                  }
                  label="Enable Recaptcha"
                />
              </Grid>
            </Grid>


            <DragDropContext onDragEnd={onDragEnd} >
                <Droppable droppableId="droppable-list" >
            {(provided) => (<div className="addFormFeild" ref={provided.innerRef} {...provided.droppableProps} >
              <Typography className="form_sec_head sub-head" variant="h6" align="left" >Update Form Fields</Typography>
              {inputFields.map((inputField, index) => {
                const commonFields = inputField.common == "1";

                return (
                  <Draggable
                    draggableId={`${index + 1}`}
                    index={index}
                    key={index + 1}
                  >
                    {(provided, snapshot) => (
                    <div
                      key={index + 1}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`form_fields ${commonFields ? 'common_fields' : ''} ${snapshot.isDragging ? 'dragging_list_item' : ""}`}
                    >
                  <div className='field_editor' >
                    <div className="field_attr_wrapper" >

                      <TextField className="text-field"
                        size="small"
                        required
                        name="label"
                        label="Label"
                        variant="outlined"
                        value={inputField.label}
                        error={!!inputField.error}
                        helperText={inputField.error || (commonFields && inputField.field_name) || ''}
                        // className={classes.textField}
                        onChange={event => handleChangeInput(index, event)}
                      />
                        <FormControl
                          variant="outlined"
                          className="select-field"
                          disabled={commonFields}
                          size="small"
                        >
                          <InputLabel id="field_type">Type *</InputLabel>
                          <Select
                            required
                            label="Type"
                            name="type"
                            value={inputField.type}
                            onChange={(event) =>
                              handleChangeInput(index, event)
                            }
                            fullWidth
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={"text"}>Text</MenuItem>
                            <MenuItem value={"email"}>Email</MenuItem>
                            <MenuItem value={"number"}>Number</MenuItem>
                            <MenuItem value={"checkbox"}>Checkbox</MenuItem>
                            <MenuItem value={"radio"}>Radio</MenuItem>
                            <MenuItem value={"select"}>Select</MenuItem>
                          </Select>
                          {["select", "radio", "checkbox"].includes(
                            inputFields[index].type
                          ) ? (
                            <Button
                              variant="outlined"
                              className={`add-option-btn ${
                                inputFields[index].options[0]
                                  ? "bdr-btn-style-white"
                                  : "bdr-btn-style-chu"
                              }`}
                              onClick={(event) =>
                                promptEditOption(index, event)
                              }
                            >
                              {inputFields[index].options[0]
                                ? "Edit options"
                                : "Add some options"}
                            </Button>
                          ) : null}
                        </FormControl>

                        <TextField
                          className="text-field"
                          required
                          name="placeholder"
                          label="Placeholder"
                          variant="outlined"
                          value={inputField.placeholder}
                          onChange={(event) => handleChangeInput(index, event)}
                          size="small"
                        />

                        <FormControl
                          variant="outlined"
                          className="select-field"
                          size="small"
                        >
                          <InputLabel>Is Required</InputLabel>
                          <Select
                            name="is_required"
                            label="isrequired"
                            value={inputField.is_required}
                            onChange={(event) =>
                              handleChangeInput(index, event)
                            }
                          >
                            <MenuItem value={"true"}>True</MenuItem>
                            <MenuItem value={"false"}>False</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="editNAddWrapper">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={inputField.filter == "1"}
                              name="filter"
                              onChange={(event) =>
                                handleChangeInput(index, event, "checkbox")
                              }
                              disabled={
                                (inputField.filter != "1" &&
                                  totalFilterFields === 3) ||
                                !["text", "select"].includes(inputField.type)
                              }
                            />
                          }
                          label="Add as Filter"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={inputField.lead_timeline == "1"}
                              name="lead_timeline"
                              onChange={(event) =>
                                handleChangeInput(index, event, "checkbox")
                              }
                            />
                          }
                          label="Show in timeline"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={inputField.editable == "1"}
                              name="editable"
                              onChange={(event) =>
                                handleChangeInput(index, event, "checkbox")
                              }
                            />
                          }
                          label="Admin Editable"
                        />
                      </div>
                    </div>
                    <div className="form_add_del_wrap">
                      <IconButton
                        disabled={commonFields || inputFields.length === 1}
                        onClick={() => handleRemoveFields(index)}
                      >
                        <Remove />
                      </IconButton>
                      {
                        inputFields.length !== (index + 1)
                        ? null
                        : <IconButton onClick={handleAddFields} >
                          <Add />
                        </IconButton>
                      }
                      </div>
                </div>)}
                              
               </Draggable>
              )
              })
              }
              
              {provided.placeholder}

            </div>)}
            

              </Droppable>
            </DragDropContext>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={optionsIncomplete || errorExist}
            >
              Update Form
            </Button>
            {optionsIncomplete ? (
              <Typography
                component="div"
                className="form_submit__error error-container"
                color="error"
              >
                Update options to submit
              </Typography>
            ) : null}
            <div>
              <Typography component="div" className="form_submit__info">
                * Mandatory inputs
              </Typography>
              <Typography component="div" className="form_submit__info">
                <span className="man_field_clr"></span> Mandatory fields
              </Typography>
            </div>
          </Paper>

          {/* <Paper elevation={2} className="Addform">
            <div className="form-data">
              <pre>
                <code>{props.formData}</code>
              </pre>
            </div>
          </Paper> */}
        </form>
      </Container>

      <div>
        {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Add Field Properties
          </DialogTitle>
          <DialogContent dividers>
            {currentOptions.map((option, index) => {
              const optionLength = currentOptions.length;

              return (
                <div key={index}>
                  <TextField
                    className="text-field"
                    required
                    name="item"
                    label="Option"
                    variant="outlined"
                    onChange={(event) => handleOptionChange(index, event)}
                    value={option}
                    size="small"
                  />
                  <IconButton
                    disabled={optionLength === 1}
                    onClick={() => handleRemoveOptions(option)}
                  >
                    <Remove />
                  </IconButton>

                  <IconButton
                    disabled={optionLength !== index + 1}
                    onClick={handleAddOptions}
                  >
                    <Add />
                  </IconButton>
                </div>
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={saveOptions}
              color="primary"
              disabled={!!currentOptions.filter((o) => !o.trim()).length}
            >
              Save changes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
}

export default connect(
  (state) => ({
    form: state.forms.form,
    fields: state.forms.fields,
    formData: state.forms.formData,
  }),
  { updateAForm, getAForm }
)(EditForm);
