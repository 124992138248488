export function maskEmail(value) {
  const [name, domain] = value.split("@");

  return `${name[0]}....${name[name.length - 1]}@${domain}`;
}

// This function is for getting client name from URL (subdomain)
export function getClientNameFromURL() {
  const baseURLList = window.location.host.split(".");
  const domainLoc = process.env.NODE_ENV === "production" ? 2 : 1;

  return (baseURLList[domainLoc] && baseURLList[0]) || null;
}

export function getULDomain() {
  const baseURLList = window.location.host.split(".");
  const domainLoc = process.env.NODE_ENV === "production" ? 2 : 1;

  if (baseURLList.length > domainLoc) {
    baseURLList.shift();

    return baseURLList.join(".");
  }

  return baseURLList.join(".");
}

export function truncateString(str, n) {
  if (str && str.length > n) {
    return str.substring(0, n) + "...";
  } else {
    return str;
  }
}

export function getJSONFromUrl(url) {
  if (!url) url = window.location.search;
  var query = url.substr(1);
  var result = {};
  query.split("&").forEach(function (part) {
    var item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

export function reverseArray(input) {
  var ret = [];
  for (var i = input.length - 1; i >= 0; i--) {
    ret.push(input[i]);
  }
  return ret;
}

export function isValidUrl(urlString) {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
}

export function giveKeysWithValue(obj) {
  const asArray = Object.entries(obj);
  const filtered = asArray.filter(([key, value]) => !!value);

  return Object.fromEntries(filtered);
}

export function isEqualArrays(a, b) {
  return (
    a &&
    b &&
    a.length === b.length &&
    a.filter((aa, i) => aa == b[i]).length === a.length
  );
}

export function csvToArray(str, delimiter = ",") {
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  const headers = str
    .slice(0, str.indexOf("\n"))
    .replace("\r", "")
    .split(delimiter);

  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = str.slice(str.indexOf("\n") + 1).split("\n");

  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array
  const arr = rows.map(function (row) {
    const values = row.split(delimiter);
    const el = headers.reduce(function (object, header, index) {
      object[header] = values[index];
      return object;
    }, {});
    return el;
  });

  // return the array
  return arr;
}

export function getCsvHeaders(str, delimiter = ",") {
  const headers = str
    .slice(0, str.indexOf("\n"))
    .replace("\r", "")
    .split(delimiter);

  return headers;
}

export function removeWhiteSpaces(str) {
  return str.replace(/\s+/g, "");
}

export function normalizeHtml(str) {
  return str && str.replace(/&nbsp;|\u202F|\u00A0/g, " ");
}

export function makeFirstLetterCaps(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
