import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  Typography,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  DialogActions,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  getNotiSwitches,
  postNotiSwitches,
} from "../../../redux/modules/settings";

function Notifications() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const notis = useSelector((state) => state.settings.notification);
  const [access, setAccess] = useState(notis);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getNotiSwitches());
  }, []);

  useEffect(() => {
    setAccess(notis);
  }, [notis]);

  const onChangeNoti = () => {
    setOpen(true);
  };

  const onDialogClose = () => {
    setOpen(false);
    setAccess(notis);
  };

  const onRightCheck = () => {
    setAccess(access === "1" ? "0" : "1");
  };

  const onRightsave = () => {
    setLoading(true);
    dispatch(postNotiSwitches(access)).then(() => {
      setLoading(false);
    });
  };

  return (
    <div>
      <Card className="list_paper">
        <List className="settings_list">
          <ListItem className="list_item">
            <ListItemText
              primary={<Typography>Email Notifications</Typography>}
              secondary={
                <Typography variant="caption">
                  You can switch email notifications here.
                </Typography>
              }
            />
            <Button
              autoFocus
              onClick={onChangeNoti}
              color="primary"
              className="sec-button"
            >
              Change
            </Button>
          </ListItem>
        </List>
      </Card>
      <Dialog open={open} onClose={onDialogClose}>
        <DialogTitle>Notifications settings</DialogTitle>
        <Divider />
        <DialogContent>
          <div className="right_holder">
            <Typography>Enable notifications</Typography>
            <Switch
              checked={!!Number(access)}
              onChange={onRightCheck}
              color="primary"
              name="Enable notifications"
            />
          </div>
        </DialogContent>
        {loading ? <LinearProgress /> : null}
        <DialogActions>
          <Button variant="contained" color="primary" onClick={onRightsave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Notifications;
