import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import clientMiddleware from "./middlewares/clientMiddleware";
import ApiHandler from "../helpers/ApiHandler";
import appReducer from "./reducers";
import { LOGOUT } from "./modules/auth";

const initialState = {};

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    return appReducer({ ...initialState, alert: state.alert }, action);
  }

  return appReducer(state, action);
};
const middleware = [clientMiddleware(new ApiHandler()), thunk];

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
