import React from "react";

const DocContent = ({ content }) => {
  return (
    <div className="content-section">
      {content.map((item, i) => {
        return (
          <div key={i} className="each-doc-section">
            <h2 id={item.id}>{`${i + 1}. ${item.title}`}</h2>
            <div dangerouslySetInnerHTML={{ __html: item.html }}></div>

            {/* {
                                item.sub_items.map((subitem, j) => {
                                    return(
                                        <>
                                            <article className='content-block'>
                                                <h3 id={'subsection_' + (i + 1) + '_' + (j + 1)}>{`${i + 1}.${j + 1} ${subitem.sub_title}`}</h3>
                                                <p>{subitem.sub_content}</p>
                                            </article>
                                        </>
                                    )
                                })
                            } */}
          </div>
        );
      })}
    </div>
  );
};

export default DocContent;
