import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  IconButton,
  Button,
  Select,
  MenuItem,
  LinearProgress,
} from "@mui/material";
import React, { useEffect, useState, Fragment } from "react";
import { getDateTimeWith12R } from "../../../utils/format_date";
import {
  Backup as BackupIcon,
  SettingsBackupRestore as SettingsBackupRestoreIcon,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  getBackUpDetails,
  startBackup,
  restoreNow,
  setBUInterval,
} from "../../../redux/modules/manage";

function BUItem({ bu }) {
  const dispatch = useDispatch();
  const [restoring, setRestoring] = useState(false);

  const onRestore = (backupId) => () => {
    setRestoring(true);
    dispatch(restoreNow(backupId)).then(() => {
      setRestoring(false);
    }).catch(() => {
      setRestoring(false);
    });
  }

  return (
    <ListItem>
      <ListItemIcon>
        {
          bu.type === 'auto' ? <SettingsBackupRestoreIcon className='bu-logo' /> : null
        }
        {
          bu.type === 'manual' ? <BackupIcon className='bu-logo' /> : null
        }
      </ListItemIcon>
      <ListItemText primary={
        <div>
          <Typography>{bu.file_name}</Typography>
          <div className='size-n-create' >
            <Typography variant='caption' >Size: {bu.file_size}</Typography>
            <Typography variant='caption' >Created: {getDateTimeWith12R(bu.created_time)}</Typography>
          </div>
        </div>
      } />
      <ListItemSecondaryAction>
        <div className='restore-bu' >
          <Button variant='outlined' color='primary' onClick={onRestore(bu.tb_id)} disabled={restoring} >Restore</Button>
          {
            restoring
            ? <LinearProgress className='restore-lp' />
            : null
          }
        </div>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

function Backup() {
  const dispatch = useDispatch();
  const [buInterval, setBuInterval] = useState("");
  const [loading, setLoading] = useState(false);
  const [settingInterval, setSI] = useState(false);
  const [backing, setBacking] = useState(false);
  const backups = useSelector(state => state.manage.backups);
  const interval = useSelector(state => state.manage.interval);

  useEffect(() => {
    if (buInterval != interval) {
      setBuInterval(interval);
    }
  }, [interval]);

  useEffect(() => {
    setLoading(true);
    dispatch(getBackUpDetails())
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const onBuIntChange = (e) => {
    const newValue = e.target.value === "select" ? "" : e.target.value;

    setBuInterval(newValue);
    setSI(true);
    dispatch(setBUInterval(newValue))
      .then(() => {
        setSI(false);
      })
      .catch(() => {
        setSI(false);
      });
  };

  const onBackUp = () => {
    setBacking(true);
    dispatch(startBackup())
      .then(() => {
        setBacking(false);
      })
      .catch(() => {
        setBacking(false);
      });
  };

  return (
    <div>
      <div className='tble-bdr log-l-cont' >
        <List className='log-list' >
            {
              backups.map((bu, b) => (
                <Fragment key={b} >
                  <BUItem bu={bu} />
                  <Divider />
                </Fragment>
              ))
            }
            {
              !backups.length & !loading ? <Typography variant='caption' className='empty-message' style={{ margin: '15px' }} component='div' >No backups found...</Typography> : null 
            }
          <ListItem>
            <div className="bu-change">
              <div className="bu-action1">
                <Typography>Automatically backup database:</Typography>
                <div className="auto-bu-select">
                  <Select
                    style={{ maxHeight: 40 }}
                    variant="outlined"
                    margin="dense"
                    value={buInterval || "select"}
                    onChange={onBuIntChange}
                    disabled={settingInterval}
                  >
                    <MenuItem value="select">-- Select --</MenuItem>
                    <MenuItem value="1">Daily</MenuItem>
                    <MenuItem value="7">Weekly</MenuItem>
                    <MenuItem value="30">Monthly</MenuItem>
                    <MenuItem value="365">Annually</MenuItem>
                  </Select>
                  {settingInterval ? (
                    <LinearProgress className="select-progress" />
                  ) : null}
                </div>
              </div>
              <Divider orientation="vertical" flexItem />
              <div className="bu-action2">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={backing}
                  onClick={onBackUp}
                >
                  Backup now
                </Button>
                {backing ? <LinearProgress className="btn-progress" /> : null}
              </div>
            </div>
          </ListItem>
        </List>
      </div>
    </div>
  );
}

export default Backup;
