import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  LinearProgress,
  Dialog,
  DialogContent,
  Divider,
} from "@mui/material";
import DealForm from "../../../components/DealForm/DealForm";
import DialogTitle from "../../../components/DialogTitle";

function CreateDeal() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const onCreateClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        className="btn-style"
        variant="contained"
        color="primary"
        onClick={onCreateClick}
      >
        Create Deal
      </Button>
      <Dialog open={open} onClose={handleClose} scroll="body">
        <DialogTitle onClose={handleClose}>Create new deal</DialogTitle>
        <Divider />
        <DialogContent>
          <DealForm cancel={handleClose} create />
        </DialogContent>
        {isLoading ? <LinearProgress /> : null}
      </Dialog>
    </>
  );
}

export default CreateDeal;
