import { setAlert } from './alert';
import { getClientNameFromURL, getJSONFromUrl } from '../../utils';

// ACTION TYPES
const LOGIN = 'LOGIN';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

const LOGIN_EXPIRED = 'LOGIN_EXPIRED';

const VERIFY_AUTH = 'VERIFY_AUTH';
const VERIFY_AUTH_SUCCESS = 'VERIFY_AUTH_SUCCESS';
const VERIFY_AUTH_FAILED = 'VERIFY_AUTH_FAILED';

const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
const RESET_PASSWORD_REQUEST_SUCCESS = 'RESET_PASSWORD_REQUEST_SUCCESS';
const RESET_PASSWORD_REQUEST_FAILED = 'RESET_PASSWORD_REQUEST_FAILED';

const ADD_NEW_PASSWORD = 'ADD_NEW_PASSWORD';
const ADD_NEW_PASSWORD_SUCCESS = 'ADD_NEW_PASSWORD_SUCCESS';
const ADD_NEW_PASSWORD_FAILED = 'ADD_NEW_PASSWORD_FAILED';

const VERIFY_PASS_TOKEN = 'VERIFY_PASS_TOKEN';
const VERIFY_PASS_TOKEN_SUCCESS = 'VERIFY_PASS_TOKEN_SUCCESS';
const VERIFY_PASS_TOKEN_FAILED = 'VERIFY_PASS_TOKEN_FAILED';

const CHANGE_P_DETAILS = 'CHANGE_P_DETAILS';
const CHANGE_P_DETAILS_SUCCESS = 'CHANGE_P_DETAILS_SUCCESS';
const CHANGE_P_DETAILS_FAILED = 'CHANGE_P_DETAILS_FAILED';

const VERIFY_ET = 'VERIFY_ET';
const VERIFY_ET_SUCCESS = 'VERIFY_ET_SUCCESS';
const VERIFY_ET_FAILED = 'VERIFY_ET_FAILED';

const RESET_ERROR = 'RESET_ERROR';

const SET_VERIFIED = 'SET_VERIFIED';

const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';

const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
const GET_NOTIFICATIONS_SUC = 'GET_NOTIFICATIONS_SUC';
const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';

const SET_NOTIFICATION = 'SET_NOTIFICATION';

const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

const D_ALL_NOTIS = 'D_ALL_NOTIS';
const D_ALL_NOTIS_SUC = 'D_ALL_NOTIS_SUC';
const D_ALL_NOTIS_FAIL = 'D_ALL_NOTIS_FAIL';

const GET_S_ACT = 'GET_S_ACT';
const GET_S_ACT_S = 'GET_S_ACT_S';
const GET_S_ACT_F = 'GET_S_ACT_F';

const A_LOGIN = 'A_LOGIN';
const A_LOGIN_SUCCESS = 'A_LOGIN_SUCCESS';
const A_LOGIN_FAIL = 'A_LOGIN_FAIL';

const GET_S_INVITE = 'GET_S_INVITE';
const GET_S_INVITE_S = 'GET_S_INVITE_S';
const GET_S_INVITE_F = 'GET_S_INVITE_F';

const RESEND_PR_EMAIL = 'RESEND_PR_EMAIL';
const RESEND_PR_EMAIL_S = 'RESEND_PR_EMAIL_S';
const RESEND_PR_EMAIL_F = 'RESEND_PR_EMAIL_F';

const SET_P_SWITCH = 'SET_P_SWITCH';
const SET_P_SWITCH_S = 'SET_P_SWITCH_S';
const SET_P_SWITCH_F = 'SET_P_SWITCH_F';

const GET_COM_E = 'GET_COM_E';
const GET_COM_E_S = 'GET_COM_E_S';
const GET_COM_E_F = 'GET_COM_E_F';

const CHANGE_CE = 'CHANGE_CE';
const CHANGE_CE_S = 'CHANGE_CE_S';
const CHANGE_CE_F = 'CHANGE_CE_F';

const D_A_NOTI = 'D_A_NOTI';
const D_A_NOTI_S = 'D_A_NOTI_S';
const D_A_NOTI_F = 'D_A_NOTI_F';

const SET_TIME_ZONE = 'SET_TIME_ZONE';

const POST_TZ = 'POST_TZ';
const POST_TZ_S = 'POST_TZ_S';
const POST_TZ_F = 'POST_TZ_F';

const localToken = localStorage.getItem("token");
const localAToken = localStorage.getItem("a_token");

const defaultAccess = {
  "1": "1",
  "2": "1",
  "3": "1",
  "4": "1",
  "5": "1",
  "6": "1",
  "7": "1",
  "8": "1",
  "9": "1",
  "10": "1",
  "11": "1",
  "12": "1",
  "13": "1",
  "14": "1",
  "15": "1",
  "16": "1",
  "17": "1",
  "18": "1",
  "19": "1",
  "20": "1",
  "21": "1",
  "22": "1",
  "23": "1",
  "24": "1",
  "25": "1",
  "26": "1",
  "27": "1",
  "28": "1",
  "29": "1",
  "30": "1",
  "31": "1",
  "32": "1",
  "33": "1",
  "34": "1",
  "35": "1",
  "36": "1",
  "37": "1",
  "38": "1",
  "39": "1",
  "40": "1",
  "41": "1",
  "42": "1",
  "43": "0",
  "44": "1"
};

// INITIAL STATE
const initialState = {
  subDomain: getClientNameFromURL(),
  clientName: getClientNameFromURL(),
  token: localToken,
  aToken: localAToken,
  isAuthenticated: !!localToken,
  isAAuth: !!localAToken,
  loading: true,
  accesses: defaultAccess,
  staffname: "",
  username: "",
  error: "",
  verified: false,
  passwordResponse: {},
  emailId: "",
  tokenSet: false,
  channelName: "",
  events: [],
  notifications: [],
  loadingNotis: false,
  active: 1,
  accounts: [],
  change_log: [],
  communicationEmail: "",
  timezone: "America/Los_Angeles",
  accountLocked: false,
  failedAttempt: 0
};


// REDUCER
export default function (state = initialState, action) {
  const { type, payload, error } = action;
  const subDomain = state.subDomain;

  switch (type) {
    case VERIFY_AUTH:
      return {
        ...state,
        accesses: subDomain ? {} : defaultAccess,
        loading: true,
        verified: false
      };
    case VERIFY_AUTH_SUCCESS:
      return {
        ...state,
        accesses: {
          ...state.accesses,
          ...(payload.clientname ? defaultAccess : {}),
          ...payload.permissions || {}
        },
        channelName: payload.channel || "",
        events: payload.events || [],
        staffname: payload.staffname || "",
        clientName: subDomain || payload.clientname || "",
        emailId: payload.email_id || "",
        isAuthenticated: true,
        loading: false,
        verified: true,
        accounts: payload.accounts || [],
        prioritySwitch: payload.lead_priority,
        change_log: payload.change_log || [],
        communicationEmail: payload.comm_email || "",
        timezone: payload.timezone || ""
      };
    case LOGIN_SUCCESS:
      if (!subDomain && payload.clientname) {
        window.location = process.env.REACT_APP_STAGING
        ? `${window.location.protocol}//${window.location.host}/staging/accounts`
        : `${window.location.protocol}//${window.location.host}/accounts`;
        localStorage.setItem("token", payload.token);

        return state;
      }

      localStorage.setItem(subDomain ? "a_token" : "token", payload.token);

      return {
        ...state,
        accesses: {
          ...state.accesses,
          ...(payload.clientname ? defaultAccess : {}),
          ...payload.permissions || {}
        },
        emailId: payload.email_id || "",
        isAuthenticated: true,
        loading: false,
        token: subDomain ? "" : payload.token,
        aToken: subDomain ? payload.token : "",
        verified: true,
        accounts: payload.accounts || [],
        channelName: payload.channel || "",
        events: payload.events || [],
        clientName: subDomain || payload.clientname || "",
        staffname: payload.staffname || "",
        prioritySwitch: payload.lead_priority,
        change_log: payload.change_log || [],
        communicationEmail: payload.comm_email || "",
        accountLocked: false,
        failedAttempt: 0,
        timezone: payload.timezone || ""
      };
    case A_LOGIN_SUCCESS:
      localStorage.setItem("a_token", payload.token);
      return {
        ...state,
        accesses: {
          ...state.accesses,
          ...(payload.clientname ? defaultAccess : {}),
          ...payload.permissions || {}
        },
        channelName: payload.channel || "",
        events: payload.events || [],
        staffname: payload.staffname || "",
        clientName: subDomain || payload.clientname || "",
        emailId: payload.email_id || "",
        isAuthenticated: true,
        loading: false,
        aToken: payload.token,
        verified: true,
        tokenSet: true,
        accounts: payload.accounts || [],
        prioritySwitch: payload.lead_priority,
        change_log: payload.change_log || [],
        communicationEmail: payload.comm_email || "",
        accountLocked: false,
        failedAttempt: 0,
        timezone: payload.timezone || ""
      };
    case VERIFY_PASS_TOKEN_SUCCESS:
      return {
        ...state,
        username: payload.clientname || payload.staffname || "",
        emailId: payload.email_id || ""
      };
    case LOGIN_FAIL:
      const errRes = error.response && error.response.data || {};
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        accountLocked: !!Number(errRes.locked),
        failedAttempt: Number(errRes.attempt) || 0,
        error: errRes.message || "Error login. Please check your credentials or client"
      };
    case LOGIN_EXPIRED:
    case LOGOUT:
      localStorage.removeItem("token");
      localStorage.removeItem("a_token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        verified: true,
        channelName: "",
        events: [],
        active: 1,
        accounts: [],
        staffname: "",
        tokenSet: false,
        communicationEmail: ""
      };
    case VERIFY_AUTH_FAILED:
      localStorage.removeItem("token");
      localStorage.removeItem("a_token");
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        verified: true,
        channelName: "",
        events: [],
        communicationEmail: ""
      };
    case SET_AUTH_TOKEN:
      const urlParams = getJSONFromUrl();
      const channel = urlParams.ch;
      const events = JSON.parse(urlParams.events);
      const emailId = urlParams.email_id;
      localStorage.setItem("token", action.token);

      return {
        ...state,
        isAuthenticated: true,
        verified: true,
        token: action.token,
        tokenSet: true,
        channelName: channel || "",
        events: events || [],
        emailId
      };
    case RESET_ERROR:
      return {
        ...state,
        error: ""
      };
    case SET_VERIFIED:
      return {
        ...state,
        verified: action.value
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        passwordResponse: {}
      };
    case RESET_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        passwordResponse: payload
      };
    case GET_NOTIFICATIONS:
      return {
        ...state,
        loadingNotis: true
      };
    case GET_NOTIFICATIONS_SUC:
      return {
        ...state,
        notifications: payload.result || [],
        loadingNotis: false
      };
    case GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        loadingNotis: false
      };
    case SET_NOTIFICATION:
      const notifications = [
        action.noti,
        ...state.notifications
      ];
      return {
        ...state,
        notifications
      };
    case CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notifications: []
      };
    case GET_S_ACT_S:
      return {
        ...state,
        active: payload.active
      };
    case GET_COM_E_S:
      return {
        ...state,
        communicationEmail: payload.result && payload.result.comm_email || ""
      };
    case SET_TIME_ZONE:
      return {
        ...state,
        timezone: action.tz
      };
    default:
      return state;
  }
}

// ACTION CREATORS
export function login(email, password) {
  return dispatch => {
    return dispatch({
      types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
      promise: fetch => fetch.post('api/Single_sign/signin', {
        data: {
          email,
          password: window.btoa(password),
          domain: getClientNameFromURL()
        }
      })
    })
    .then(() => dispatch(setAlert('Authenticated successfully', 'success')))
    .catch((error) => {
      dispatch(setAlert('Error Authenticating', 'error'));

      return Promise.reject();
    });
  }
}

export function loginForAccount(token) {
  return (dispatch, getState) => {
    const { auth: { subDomain } } = getState();
      return dispatch({
        types: [A_LOGIN, A_LOGIN_SUCCESS, A_LOGIN_FAIL],
        promise: fetch => fetch.post('api/Sign_bydomain/signin', {
          params: {
            domain: subDomain
          },
          token
        })
      });
    }
  }

export const logout = () => dispatch => {
  dispatch(setAlert('logout successful.', 'success'));
  dispatch({ type: LOGOUT });
}

export const loginExpired = () => dispatch => {
  dispatch(setAlert('Token Expired', 'error'));
  dispatch({ type: LOGIN_EXPIRED });
}

export function verifyAuth() {
  return (dispatch, getState) => {
    const { auth: { subDomain } } = getState();
    return dispatch({
      types: [VERIFY_AUTH, VERIFY_AUTH_SUCCESS, VERIFY_AUTH_FAILED],
      promise: fetch => fetch.post(subDomain ? "api/Tokenvalidation_bydomain" : "api/Tokenvalidation_byemail", {
        params: {
          domain: subDomain 
        }
      })
    });
  }
}

export function verifyFailed() {
  return {
    type: VERIFY_AUTH_FAILED
  }
}

export function resetError() {
  return {
    type: RESET_ERROR
  };
}

export function setVerified(value) {
  return {
    type: SET_VERIFIED,
    value
  };
}

export function setAuthToken(token) {
  return {
    type: SET_AUTH_TOKEN,
    token
  };
}

export function submitResetPasswordRequest(email) {
  return (dispatch, getState) => dispatch({
    types: [RESET_PASSWORD_REQUEST, RESET_PASSWORD_REQUEST_SUCCESS, RESET_PASSWORD_REQUEST_FAILED],
    promise: fetch => fetch.post("api/Resetpassword", {
      data: {
        email,
        username: getClientNameFromURL()
      }
    })
  }).then(() => getState().auth.passwordResponse);
}

export function resendPREmail(email) {
  return {
    types: [RESEND_PR_EMAIL, RESEND_PR_EMAIL_S, RESEND_PR_EMAIL_F],
    promise: fetch => fetch.post("api/Email_resend", {
      data: {
        email
      }
    })
  };
}

export function createNewAccWithPass(password, token) {
  return {
    types: [ADD_NEW_PASSWORD, ADD_NEW_PASSWORD_SUCCESS, ADD_NEW_PASSWORD_FAILED],
    promise: fetch => fetch.post("api/Staffpassword/" + token, { 
      data: {
        password
      }
     })
  };
}

export function addNewPassword(password, token) {
  return {
    types: [ADD_NEW_PASSWORD, ADD_NEW_PASSWORD_SUCCESS, ADD_NEW_PASSWORD_FAILED],
    promise: fetch => fetch.put("api/Resetpassword/" + token, { 
      data: {
        password
      }
     })
  };
}

export function verifyPasswordToken(token) {
  return {
    types: [VERIFY_PASS_TOKEN, VERIFY_PASS_TOKEN_SUCCESS, VERIFY_PASS_TOKEN_FAILED],
    promise: fetch => fetch.get('api/Staffpassword/' + token)
  };
}

export function changeProfileDetails(details) {
  return dispatch => dispatch({
    types: [CHANGE_P_DETAILS, CHANGE_P_DETAILS_SUCCESS, CHANGE_P_DETAILS_FAILED],
    promise: fetch => fetch.put('api/Changeprofile', {
      data: details
    })
  })
  .then((res) => {
    dispatch(setAlert('Profile updated successfully', 'success'));

    return res;
  });
}

export function getCommuniEmail() {
  return {
    types: [GET_COM_E, GET_COM_E_S, GET_COM_E_F],
    promise: fetch => fetch.get('api/Master_notification')
  };
}

export function changeCommuniEmail(master_email) {
  return {
    types: [CHANGE_CE, CHANGE_CE_S, CHANGE_CE_F],
    promise: fetch => fetch.put('api/Master_notification', {
      data: {
        master_email
      }
    })
  };
}

export function verifyEmailToken(token) {
  return {
    types: [VERIFY_ET, VERIFY_ET_SUCCESS, VERIFY_ET_FAILED],
    promise: fetch => fetch.get('api/Email_reset/' + token)
  };
}

export function getNotifications() {
  return {
    types: [GET_NOTIFICATIONS, GET_NOTIFICATIONS_SUC, GET_NOTIFICATIONS_FAIL],
    promise: fetch => fetch.get('api/Lead_notification')
  }
}

export function setNotfication(noti) {
  return {
    type: SET_NOTIFICATION,
    noti
  };
}

export function clearNotifications() {
  return {
    type: CLEAR_NOTIFICATIONS
  };
}

export function deleteAllNotifications() {
  return {
    types: [D_ALL_NOTIS, D_ALL_NOTIS_SUC, D_ALL_NOTIS_FAIL],
    promise: fetch => fetch.post('api/Lead_notification')
  };
}

export function deleteANoti(notification_id, error_log_id, release_id) {
  return {
    types: [D_A_NOTI, D_A_NOTI_S, D_A_NOTI_F],
    promise: fetch => fetch.delete('api/Lead_notification', {
      params: {
        ...(notification_id ? {notification_id} : {}),
        ...(error_log_id ? {error_log_id} : {}),
        ...(release_id ? {release_id} : {})
      }
    })
  };
}

export function getSessionActive() {
  return {
    types: [GET_S_ACT, GET_S_ACT_S, GET_S_ACT_F],
    promise: fetch => fetch.get('api/Staff_active')
  };
}

export function postStaffInvite(token) {
  return {
    types: [GET_S_INVITE, GET_S_INVITE_S, GET_S_INVITE_F],
    promise: fetch => fetch.post('api/Staff_invite/' + token)
  };
}

export function setPrioritySwitch(status) {
  return {
    types: [SET_P_SWITCH, SET_P_SWITCH_S, SET_P_SWITCH_F],
    promise: fetch => fetch.put('api/Lead_priority', {
      params: {
        status
      }
    })
  };
}

export function setUserTimezone(tz) {
  return {
    type: SET_TIME_ZONE,
    tz
  }
}

export function postTimezone(timezone) {
  return {
    types: [POST_TZ, POST_TZ_S, POST_TZ_F],
    promise: fetch => fetch.put('api/Timezone_setting', {
      data: {
        timezone
      }
    })
  }
}
