/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  List,
  ListItem,
  Button,
  colors,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";


const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <NavLink {...props} />
  </div>
));

const SidebarNav = (props) => {
  const { pages, className, onMenuClick, ...rest } = props;

  const navigate = useNavigate();

  return (
    <List {...rest} className={clsx(className)}>
      {pages.map((page) => (
        <ListItem className="item"  sx={{ padding: 0, marginLeft: 2, }} disableGutters key={page.title}>
          <ListItemButton onClick={() => navigate(page.href)}>
            <ListItemIcon sx={{ minWidth: 40 }} onClick={onMenuClick}>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "active" : "icon"
                }
                component={CustomRouterLink}
                to={page.href}
                onClick={onMenuClick}
              >
                {page.icon}
              </NavLink>
            </ListItemIcon>

            <NavLink
              className={({ isActive }) =>
                isActive ? "active" : "button"
              }
              component={CustomRouterLink}
              to={page.href}
              onClick={onMenuClick}
            >
              {page.title}
            </NavLink>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
  onMenuClick: PropTypes.func.isRequired,
};

export default SidebarNav;
