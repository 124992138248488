import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Sidebar, Topbar } from "./components";



const Main = (props) => {
  const { children } = props;

  const [isDesktop, setIsDesktop] = useState(false)

  const getScreenSize = () => {
    let currentScreenWidth = window.innerWidth;
    if (currentScreenWidth > 1199) {
      setIsDesktop(true)
    } else {
      setIsDesktop(false)
    }
  }

  window.addEventListener("resize", () => {
   getScreenSize()
  })

  useEffect(() => {
    getScreenSize()
  }, [])


  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={`main ${isDesktop ? "shift-content" : ""}`}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? "persistent" : "temporary"}
        onMenuClick={handleSidebarClose}
      />
      <main 
      className={"content"}
      >
        {children}
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
