import React, { useEffect, useState } from "react";
import config from "../../../config";
import {
  clearLeadTL,
  getLeadTimeline,
  getContactTimeline,
  setActiveLead,
  getEmailDetails,
} from "../../../redux/modules/leads";
import {
  Button,
  Collapse,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getDateTimeText } from "../../../utils/format_date";
import DialogTitle from "../../../components/DialogTitle";
import {
  MailOutline as MailOutlineIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";
import { truncateString } from "../../../utils";
import { convertTime } from "../../../utils/timeConverter";

function NoteViewer({ noteData }) {
  const [readM, setReadM] = useState(false);
  const note = (noteData && noteData.content) || "";
  const noteContent = readM ? note : note.slice(0, 240);
  const isLong = note.length > 240;
  const readMore =
    isLong && !readM ? (
      <>
        ...{" "}
        <span className="read-more-btn" onClick={() => setReadM(true)}>
          Read more
        </span>
      </>
    ) : isLong ? (
      <span className="read-more-btn" onClick={() => setReadM(false)}>
        {" "}
        Read less
      </span>
    ) : null;

  return note ? (
    <div className="tl-e-details">
      <Typography>
        {noteContent}
        {readMore}
      </Typography>
    </div>
  ) : null;
}

function EmailViewer({ emailData, activityId }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailDetails, setEmailDetails] = useState({});
  const [readM, setReadM] = useState(false);

  const loadEmailDetails = () => {
    setLoading(true);
    dispatch(getEmailDetails(activityId))
      .then((res) => {
        setLoading(false);
        setEmailDetails(res.result);

        setOpen(!open);
      })
      .catch(() => {
        setLoading(false);
        setEmailDetails({});

        setOpen(!open);
      });
  };

  const onExpand = () => {
    if (!open && !emailDetails.email_sub) {
      loadEmailDetails();
    } else {
      setOpen(!open);
    }
  };

  const ebc = (emailDetails && emailDetails.email_body) || "";
  const emailBodyContent = readM ? ebc : ebc.slice(0, 240);
  const isLong = ebc.length > 240;
  const readMore =
    isLong && !readM ? (
      <>
        ...{" "}
        <span className="read-more-btn" onClick={() => setReadM(true)}>
          Read more
        </span>
      </>
    ) : isLong ? (
      <span className="read-more-btn" onClick={() => setReadM(false)}>
        {" "}
        Read less
      </span>
    ) : null;

  return (
    <div>
      {emailData ? (
        <div className="tl-email-text" onClick={onExpand}>
          <div className="tl-email-details">
            <MailOutlineIcon />{" "}
            <span className="tl-email-sub">
              {truncateString(emailData.email_sub, 35)}
            </span>
          </div>
          <IconButton className="email-arrow-btn">
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
      ) : null}
      {loading ? <LinearProgress /> : null}
      <Collapse in={open}>
        <div className="tl-e-details">
          {emailDetails.email_to ? (
            <>
              <Typography variant="h6" className="tl-e-heads">
                To
              </Typography>
              <Typography className="tl-e-values">
                {emailDetails.email_to}
              </Typography>
            </>
          ) : null}

          {emailDetails.email_cc ? (
            <>
              <Divider />
              <Typography variant="h6" className="tl-e-heads">
                Cc
              </Typography>
              <Typography className="tl-e-values">
                {emailDetails.email_cc}
              </Typography>
            </>
          ) : null}
          {emailDetails.email_bcc ? (
            <>
              <Divider />
              <Typography variant="h6" className="tl-e-heads">
                Bcc
              </Typography>
              <Typography className="tl-e-values">
                {emailDetails.email_bcc}
              </Typography>
            </>
          ) : null}
          <Divider />
          <Typography variant="h6" className="tl-e-heads">
            Subject
          </Typography>
          <Typography className="tl-e-values">
            {emailDetails.email_sub}
          </Typography>
          <Divider />
          <Typography variant="h6" className="tl-e-heads">
            Body
          </Typography>
          <div className="tl-e-values">
            <Typography
              component="span"
              style={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{ __html: `${emailBodyContent}` }}
            ></Typography>
            {readMore}
          </div>
        </div>
      </Collapse>
    </div>
  );
}

function LeadTimeline({ leadId, contactId, last, lead }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [tlPage, setLeadTLPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loadStarts, setLoadStarts] = useState(false);
  const leadTimeline = useSelector((state) => state.leads.leadTimeline);
  const timelineCount = useSelector((state) => state.leads.timelineCount);
  const averageResponseRate = useSelector(state => state.leads.averageResponseRate);
  const loading = useSelector((state) => state.leads.loadingAcitviy);
  const clientName = (lead && lead.Name) || lead.contact_name || "";

  useEffect(() => {
    if (open) {
      if (leadTimeline.length) {
        dispatch(clearLeadTL());
        if (tlPage > 0) {
          setLeadTLPage(0);
        }
      }

      if (tlPage == 0) {
        setLoadStarts(true);
        (contactId
          ? dispatch(
              getContactTimeline(contactId, config.dTimeline.limit, tlPage)
            )
          : dispatch(getLeadTimeline(leadId, config.dTimeline.limit, tlPage))
        )
          .then(() => {
            setLoadStarts(false);
          })
          .catch(() => {
            setLoadStarts(false);
          });
      }
    } else {
      setLeadTLPage(0);
    }
  }, [open]);

  useEffect(() => {
    if (tlPage > 0) {
      setIsLoading(true);
      (contactId
        ? dispatch(
            getContactTimeline(contactId, config.dTimeline.limit, tlPage)
          )
        : dispatch(getLeadTimeline(leadId, config.dTimeline.limit, tlPage))
      )
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [tlPage]);

  const onLoadMore = () => {
    setLeadTLPage(tlPage + 1);
  };

  const handleOpenDetails = () => {
    if (leadId) {
      dispatch(setActiveLead(leadId));
    }

    setOpen(true);
  };

  const onClose = () => {
    if (leadId) {
      dispatch(setActiveLead(""));
    }

    setOpen(false);
  };

  return (
    <>
      <Button
        className="bdr-btn-style-white in_lead act-btn"
        variant="outlined"
        color="primary"
        onClick={handleOpenDetails}
      >
        {last
          ? truncateString(last, 20)
          : loading
          ? "loading..."
          : "No activities"}
      </Button>
      <Dialog
        onClose={onClose}
        open={open}
        maxWidth="xs"
        fullWidth
        scroll="body"
      >
        {loadStarts ? (
          <DialogContent>
            <LinearProgress style={{ margin: 3 }} />
          </DialogContent>
        ) : (
          <>
            <DialogTitle onClose={onClose}>
              <div className="tl-title">
                <Typography variant="h6" className="timeline-t-text">
                Activities
                </Typography>
                <Typography variant="caption" className="timeline-client">
                  {clientName}
                  {clientName && leadId ? ", " : ""}
                  {leadId ? "Lead #" : ""}
                  {leadId}
                </Typography>
              </div>
            </DialogTitle>
            <Divider />
            <Typography className="tl-arr" variant="caption" component="div" >Average response rate is {convertTime(averageResponseRate) || "zero"}</Typography>
            <DialogContent>
              {!leadTimeline.length && !isLoading ? (
                <Typography
                  className="empty-message"
                  component="div"
                  variant="caption"
                >
                  No activities done yet...
                </Typography>
              ) : null}
              <div className="tl-container">
                <div className="tl-line"></div>
                <div className="tl-content-holder">
                  {leadTimeline.map((dt, k) => {
                    const dateTime = getDateTimeText(dt.update_time);

                    return (
                      <div key={k} className="tl-dot">
                        <div className="tl-content">
                          <div className="tl-datetime">{dateTime}</div>
                          {dt.diff_time ? (
                            <div className="tl-time-diff-wrapper">
                              <span className="tl-time-diff">{`after ${
                                dt.diff_time
                              } from ${
                                contactId ? "contact" : "lead"
                              } creation`}</span>
                            </div>
                          ) : null}
                          <div className="tl-text">{dt.activity}</div>
                          <EmailViewer
                            emailData={dt.email}
                            activityId={dt.activity_id}
                          />
                          <NoteViewer
                            noteData={dt.notes_details}
                            activityId={dt.activity_id}
                          />
                          {dt.fields && dt.fields.length
                            ? dt.fields.map((fl) => (
                                <div className="tl-e-details">
                                  <div className="tl_fl_label">{fl.label}</div>
                                  <div className="tl_fl_value">{fl.value}</div>
                                </div>
                              ))
                            : null}
                          {dt.created_by ? (
                            <div className="notes-owner-sec">
                              <AccountCircleIcon
                                color="primary"
                                className="notes-owner-img"
                              />
                              <Typography variant="caption">
                                {dt.created_by}
                              </Typography>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div style={{ height: 3 }}>
                {isLoading ? <LinearProgress /> : null}
              </div>
              {leadTimeline.length ? (
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ marginTop: 15 }}
                  onClick={onLoadMore}
                  disabled={isLoading || leadTimeline.length == timelineCount}
                >
                  Load more
                </Button>
              ) : null}
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );
}

export default LeadTimeline;
