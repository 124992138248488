import React, { useState, useEffect } from "react";
import { Route, Navigate, redirect, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import {
  verifyAuth,
  setVerified,
  verifyFailed,
  logout,
  getSessionActive,
} from "../../redux/modules/auth";
import {
  CircularProgress,
  Dialog,
  Box,
  Typography,
  DialogContent,
} from "@mui/material";
import NotiPop from "../NotiPop";

let timerInstance = null;

function CircularProgressForSeconds() {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    if (timerInstance) {
      clearInterval(timerInstance);
    }

    timerInstance = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress === 10) {
          dispatch(logout());
          clearInterval(timerInstance);
        }
        return prevProgress - 10;
      });
    }, 1000);

    return () => {
      clearInterval(timerInstance);
    };
  }, []);

  return (
    <Box position="relative" display="inline-flex" textAlign="center">
      <CircularProgress variant="determinate" value={progress} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(progress / 10)}s`}</Typography>
      </Box>
    </Box>
  );
}

const RouteWithLayout = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    layout: Layout,
    component: Component,
    login,
    isAuthenticated,
    loading,
    verified,
    token,
    aToken,
    active,
    staffname,
    subDomain,
    auth,
    noSD,
    ...rest
  } = props;

  useEffect(() => {
    if (!auth) {
      props.setVerified(true);
    } else {
      if (token || (subDomain && aToken)) {
        props.verifyAuth();
      } else {
        props.verifyFailed();
      }
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && staffname) {
      timerInstance = setInterval(() => dispatch(getSessionActive()), 5000);
    }
  }, [isAuthenticated, staffname]);

  if (!isAuthenticated && !loading && auth && !login) {
    return (
      <Navigate
        to={{ pathname: "/login" }}
        state={{ from: location.pathname }}
      />
    );
  } else {
    if (verified) {
      if (auth && !subDomain && !noSD && isAuthenticated) {
        return (
          <Navigate
            to={{ pathname: "/accounts" }}
            state={{ from: location.pathname }}
          />
        );
      } else {
        return (
          <Layout>
            <Component {...rest} />
            <Dialog open={active === 0}>
              <DialogContent>
                <Typography>Login session timeout</Typography>
                <div className="progress-timeout">
                  <CircularProgressForSeconds />
                </div>
              </DialogContent>
            </Dialog>
          </Layout>
        );
      }
    } else {
      return (
        <div style={{ position: "absolute", left: "50%", top: "50%" }}>
          <CircularProgress />
        </div>
      );
    }
  }
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  verified: state.auth.verified,
  token: state.auth.token,
  timezone: state.auth.timezone,
  aToken: state.auth.aToken,
  active: state.auth.active,
  staffname: state.auth.staffname,
  subDomain: state.auth.subDomain,
  clientName: state.auth.clientName,
});

export default connect(mapStateToProps, {
  verifyAuth,
  setVerified,
  verifyFailed,
})(RouteWithLayout);
