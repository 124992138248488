import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Button,
  LinearProgress,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Divider,
} from "@mui/material";
import {
  getPriorityTimes,
  updateTimeList,
} from "../../../redux/modules/settings";
import { getSeconds } from "../../../utils/timeConverter";
import { Remove, Add } from "@mui/icons-material";
import DialogTitle from "../../../components/DialogTitle";

function isTimeDataEqual(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every(
      (obj, index) =>
        obj.time_value === b[index]["time_value"] &&
        obj.time_unit === b[index]["time_unit"] &&
        obj.time_from === b[index]["time_from"] &&
        obj.time_to === b[index]["time_to"]
    )
  );
}

function isTimeDataEmpty(t) {
  const emptyTimeData = t.filter(
    (i) =>
      !i.time_value ||
      !i.time_unit ||
      (i.time_from !== "0" && !i.time_from) ||
      !i.time_to
  );

  return !!emptyTimeData.length;
}

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  buttonContainer: {
    textAlign: "left",
    marginTop: 15,
  },
  titleCap: {
    marginLeft: 10,
  },
  errorShow: {
    marginLeft: 10,
    color: "#FF0000",
  },
};

class PriorityTime extends PureComponent {
  state = {
    priorityTimes: this.props.priorityTimes,
    disableSave: true,
    errorFields: [],
  };

  componentDidUpdate(preProps) {
    if (isTimeDataEqual(this.props.priorityTimes, this.state.priorityTimes)) {
      this.setState({ disableSave: true });
    }

    // Here the API call happens only when there is no data in the redux store.
    if (
      !this.state.priorityTimes.length &&
      this.props.open &&
      this.props.open !== preProps.open
    ) {
      if (!this.props.priorityTimes.length) {
        this.props.fetchPriorityTimes().then((res) => {
          this.setState({ priorityTimes: res.result || [] });
        });
      } else {
        this.setState({ priorityTimes: this.props.priorityTimes });
      }
    }
  }

  handleTimeValueChange = (j, p, value) => {
    const prevTimes = [...this.state.priorityTimes];
    const lastTime = prevTimes[j - 1] || {};
    const nextTime = prevTimes[j + 1] || {};
    let errorFields = [...this.state.errorFields];
    const i = errorFields.indexOf(p.time_id);
    const convertedValue = (value && getSeconds(value, p.time_unit)) || 0;

    if (
      convertedValue <= +lastTime.time_to ||
      convertedValue >= nextTime.time_to
    ) {
      if (i <= -1) errorFields.push(p.time_id);
    } else {
      if (i > -1) {
        errorFields.splice(i, 1);
      }
    }

    prevTimes[j] = {
      ...p,
      time_value: value,
      time_from: lastTime.time_to || "0",
      time_to: `${convertedValue}`,
    };
    if (j < prevTimes.length - 1) {
      prevTimes[j + 1] = {
        ...prevTimes[j + 1],
        time_from: `${convertedValue}`,
      };
    }

    const isEqual = isTimeDataEqual(this.props.priorityTimes, prevTimes);
    const isEmpty = isTimeDataEmpty(prevTimes);

    this.setState({
      priorityTimes: prevTimes,
      disableSave:
        isEqual || isEmpty || !value || errorFields.indexOf(p.time_id) > -1,
      errorFields,
    });
  };

  handleTimeUnitChange = (j, p, value) => {
    const prevTimes = [...this.state.priorityTimes];
    const lastTime = prevTimes[j - 1] || {};
    const nextTime = prevTimes[j + 1] || {};
    let errorFields = [...this.state.errorFields];
    const i = errorFields.indexOf(p.time_id);
    const convertedValue = getSeconds(p.time_value, value);

    if (
      convertedValue <= +lastTime.time_to ||
      convertedValue >= nextTime.time_to
    ) {
      if (i <= -1) errorFields.push(p.time_id);
    } else {
      if (i > -1) {
        errorFields.splice(i, 1);
      }
    }

    prevTimes[j] = {
      ...p,
      time_unit: value,
      time_from: lastTime.time_to,
      time_to: `${convertedValue}`,
    };
    if (j < prevTimes.length - 1) {
      prevTimes[j + 1] = {
        ...prevTimes[j + 1],
        time_from: `${convertedValue}`,
      };
    }

    const isEqual = isTimeDataEqual(this.props.priorityTimes, prevTimes);
    const isEmpty = isTimeDataEmpty(prevTimes);

    this.setState({
      priorityTimes: prevTimes,
      disableSave:
        isEqual || isEmpty || !value || errorFields.indexOf(p.time_id) > -1,
      errorFields,
    });
  };

  handleRemoveOptions = (j) => {
    const prevTimes = [...this.state.priorityTimes];
    const LastTimeTo = (prevTimes[j - 1] || {})["time_to"] || "0";
    if (j < prevTimes.length - 1) {
      prevTimes[j + 1] = { ...prevTimes[j + 1], time_from: LastTimeTo };
    }
    prevTimes.splice(j, 1);
    const isEmpty = isTimeDataEmpty(prevTimes);

    this.setState({ priorityTimes: prevTimes, disableSave: isEmpty });
  };

  handleAddOptions = (p) => {
    const priorityTimes = this.state.priorityTimes;
    const prevTimes = [...priorityTimes];
    prevTimes.push({
      time_id: Number(priorityTimes[priorityTimes.length - 1]["time_id"]) + 1,
      time_value: "",
      time_unit: "",
      time_from: "",
      time_to: "",
      slot_type: 1,
    });
    const isEmpty = isTimeDataEmpty(prevTimes);

    this.setState({ priorityTimes: prevTimes, disableSave: isEmpty });
  };

  onClose = () => {
    this.setState({ priorityTimes: this.props.priorityTimes, errorFields: [] });
    this.props.onClose();
  };

  saveStatuses = () => {
    this.props.updateTimeList(this.state.priorityTimes);
  };

  render() {
    return (
      <Dialog
        onClose={this.onClose}
        aria-labelledby="customized-dialog-title"
        open={this.props.open}
        scroll="body"
      >
        <DialogTitle id="customized-dialog-title" onClose={this.onClose}>
          Time Periods
          <Typography variant="caption" style={styles.titleCap}>
            ( Edit time periods for priority mapping. )
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {this.state.priorityTimes.map((pTime, i) => {
            const pTimeLength = this.state.priorityTimes.length;
            const errorFields = this.state.errorFields.find(
              (p) => p === pTime.time_id
            );

            return (
              <div style={styles.wrapper} key={i}>
                <TextField
                  size="small"
                  name="Time Label"
                  className="text-field"
                  label={`Time ${i + 1}`}
                  variant="outlined"
                  style={{ maxWidth: 100 }}
                  onChange={(e) =>
                    this.handleTimeValueChange(i, pTime, e.target.value)
                  }
                  value={pTime.time_value}
                  type="number"
                  error={!!(errorFields && errorFields.length)}
                  required
                  margin="dense"
                />
                <FormControl
                  variant="outlined"
                  error={false}
                  style={{ minWidth: 80 }}
                >
                  <InputLabel>Unit</InputLabel>
                  <Select
                    value={pTime.time_unit}
                    margin="dense"
                    style={{ minWidth: 100, margin: 0, maxHeight: 40 }}
                    onChange={(e) =>
                      this.handleTimeUnitChange(i, pTime, e.target.value)
                    }
                    label="Unit"
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="1">Seconds</MenuItem>
                    <MenuItem value="2">Minutes</MenuItem>
                    <MenuItem value="3">Hours</MenuItem>
                    <MenuItem value="4">Days</MenuItem>
                    <MenuItem value="5">Weeks</MenuItem>
                    <MenuItem value="6">Months</MenuItem>
                    <MenuItem value="7">Years</MenuItem>
                  </Select>
                </FormControl>
                <IconButton
                  disabled={pTimeLength === 1}
                  onClick={() => this.handleRemoveOptions(i)}
                >
                  <Remove />
                </IconButton>
                <IconButton
                  disabled={pTimeLength !== i + 1}
                  onClick={() => this.handleAddOptions()}
                >
                  <Add />
                </IconButton>
              </div>
            );
          })}
          {this.props.loading ? <LinearProgress /> : null}
          <div style={styles.buttonContainer}>
            <Button
              variant="contained"
              onClick={this.saveStatuses}
              color="primary"
              disabled={this.state.disableSave}
              autoFocus
            >
              Save Changes
            </Button>
            {!!this.state.errorFields.length ? (
              <Typography variant="caption" style={styles.errorShow}>
                Please check the time value
              </Typography>
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  priorityTimes: state.settings.priorityTimes,
  loading: state.settings.loading,
});

export default connect(mapStateToProps, {
  fetchPriorityTimes: getPriorityTimes,
  updateTimeList,
})(PriorityTime);
