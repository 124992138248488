import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Typography,
  Grid,
  Button,
  Box,
  LinearProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import { loginExpired } from "../../redux/modules/auth";
import {
  getForms,
  getAForm,
  deleteAForm,
  setPage,
  setRowsPerPage,
} from "../../redux/modules/forms";
import AccessControl from "../../components/AccessControl";
import getAccessFor from "../../helpers/getAccessFor";
import DeleteForm from "./components/DeleteForm";
import FormIntegration from "./components/FormIntegration";
import { Helmet } from "react-helmet";

const Forms = (props) => {
  var timeout = null;
  const navigate = useNavigate();
  const [sortOrder, setSortOrder] = useState({
    direction: "desc",
    name: ["last_seen"],
  });
  const [filterOptions, setFilterOptions] = useState();
  const [filterList, setFilterList] = useState({ searchText: "" });
  const [searchText, setSearchText] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    {
      name: "id",
      label: "Form id",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "title",
      label: "Title",
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: "category",
      label: "Category",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  if (getAccessFor("copy_snippet")) {
    columns.push({
      name: "Deploy",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return <FormIntegration formId={props.forms[rowIndex].id} />;
        },
      },
    });
  }

  if (getAccessFor("form_edit")) {
    columns.push({
      name: "Edit",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <Button
              variant="outlined"
              className="bdr-btn-style-green"
              onClick={(event) => editingForm(event, rowIndex)}
            >
              Edit
            </Button>
          );
        },
      },
    });
  }

  if (getAccessFor("form_deletion")) {
    columns.push({
      name: "Delete",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (dataIndex, tableData) => {
          return (
            <DeleteForm
              formId={tableData.rowData[0]}
              title={tableData.rowData[1]}
            />
          );
        },
      },
    });
  }

  const editingForm = (e, r) => {
    e.preventDefault();
    const formId = props.forms[r].id;
    navigate(`/form/edit/${formId}`);
  };

  function removeForm(e, r) {
    e.preventDefault();
    const formId = props.forms[r].id;
    props.deleteAForm(formId);
  }

  useEffect(() => {
    if (!props.forms.length && getAccessFor("view_forms")) {
      setIsLoading(true);
      props.getForms().then(() => {
        setIsLoading(isLoading);
      });
    }
  }, []);

  const changePage = (page, rows, sortOrder, filterList, searchText) => {
    props.setPage(page);
    props.setRowsPerPage(rows);
    setIsLoading(true);
    props.getForms(rows, page, sortOrder, filterList, searchText).then(() => {
      setSortOrder(sortOrder);
      setFilterList(filterList);
      setSearchText(searchText);
      setIsLoading(false);
    });
  };

  const options = {
    sort: false,
    draggable: true,
    filter: false,
    // filterType: "dropdown",
    responsive: "standard",
    serverSide: true,
    count: props.total,
    page: props.page,
    rowsPerPage: props.rowsPerPage,
    rowsPerPageOptions: [1, 2, 5, 10, 15, 20, 25, 30],
    sortOrder: sortOrder,
    searchText: searchText,
    selectableRows: "none",
    customToolbar: null,
    jumpToPage: true,
    search: false,
    print: false,
    download: false,
    textLabels: {
      body: {
        noMatch: isLoading ? (
          "Loading..."
        ) : (
          <Typography
            className="empty-message"
            component="div"
            variant="caption"
          >
            Sorry, no forms found...
          </Typography>
        ),
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          changePage(
            tableState.page,
            props.rowsPerPage,
            sortOrder,
            filterList,
            searchText
          );
          break;
        case "changeRowsPerPage":
          changePage(
            0,
            tableState.rowsPerPage,
            sortOrder,
            filterList,
            searchText
          );
          break;
        case "sort":
          changePage(
            props.page,
            props.rowsPerPage,
            tableState.sortOrder,
            filterList,
            searchText
          );
          break;
        case "filterChange":
          var res = tableState.filterList.toString().split(",");
          var resList = res.filter(function (el) {
            return el !== "";
          });

          changePage(
            props.page,
            props.rowsPerPage,
            sortOrder,
            { country: resList },
            searchText
          );
          break;
        case "search":
          let searchTerm;
          if (tableState.searchText && searchText != tableState.searchText) {
            searchTerm = tableState.searchText;

            if (timeout) {
              clearTimeout(timeout);
            }
            timeout = setTimeout(function () {
              setSearchText(searchText);
              changePage(
                props.page,
                props.rowsPerPage,
                sortOrder,
                filterList,
                searchTerm
              );
            }, 2000);
          }

          break;
        default:
          console.log("action not handled.");
      }
    },
  };

  return (
    <>
      <Helmet>
        <title>Forms</title>
        <meta name="description" content="Forms page" />
      </Helmet>
      <AccessControl name="view_forms" message>
        <div className="Form-page">
          {/* <MuiThemeProvider theme={theme}> */}
          <div className="page_t_head">
            <div className="page_t_wrapper">
              <Typography className="page_title">Forms</Typography>
              <AccessControl name="form_creation">
                <Button
                  className="btn-style"
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/form/create")}
                >
                  Create Form
                </Button>
              </AccessControl>
            </div>
          </div>
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            {isLoading && <LinearProgress />}
            <MUIDataTable
              className="tble-bdr"
              title={
                <Typography variant="h4">
                  {/* isLoading && (
                <CircularProgress
                  size={32}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )} */}
                </Typography>
              }
              data={props.forms}
              columns={columns}
              options={options}
            />
          </div>

          {/* </MuiThemeProvider> */}
        </div>
      </AccessControl>
    </>
  );
};

export default connect(
  (state) => ({
    forms: state.forms.forms,
    total: state.forms.total,
    page: state.forms.page,
    rowsPerPage: state.forms.rowsPerPage,
    accesses: state.auth.accesses,
  }),
  { loginExpired, getForms, getAForm, deleteAForm, setPage, setRowsPerPage }
)(Forms);
