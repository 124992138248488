import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { validateEmail, validatePhoneNumber } from "../../../utils/validators";
import {
  addStaff,
  updateStaff,
  deleteStaff,
} from "../../../redux/modules/staffs";
import DialogTitle from "../../../components/DialogTitle";

const keys = ["name", "email", "designation", "role", "mobile"];

function isEqual(a, b) {
  return !(
    keys
      .map((key) => {
        return a[key] === b[key];
      })
      .indexOf(false) > -1
  );
}

function isEmpty(a) {
  return !!keys.map((key) => a[key]).filter((i) => !i).length;
}

class StaffForm extends PureComponent {
  state = {
    staff: {},
    disableSave: false,
    confirmDelete: false,
    errorFields: [],
  };

  componentDidUpdate(preProps) {
    if (
      !Object.keys(this.state.staff).length &&
      this.props.open &&
      this.props.open !== preProps.open
    ) {
      if (!!Object.keys(this.props.staff).length) {
        this.setState({ staff: this.props.staff, disableSave: true });
      }
    }
  }

  handleStaffChange = (e) => {
    const updatedStaff = {
      ...this.state.staff,
      [e.target.name]: e.target.value,
    };
    const isStaffEqual = isEqual(this.props.staff, updatedStaff);
    const isStaffEmpty = isEmpty(updatedStaff);
    let errorFields = [...this.state.errorFields];

    if (e.target.name === "email") {
      const emailIndex = errorFields.indexOf("email");
      const errorExist = emailIndex > -1;

      if (!validateEmail(e.target.value)) {
        if (!errorExist) {
          errorFields.push("email");
        }
      } else {
        if (errorExist) {
          errorFields.splice(emailIndex, 1);
        }
      }
    }

    if (e.target.name === "mobile") {
      const mobileIndex = errorFields.indexOf("mobile");
      const errorExist = mobileIndex > -1;

      if (!validatePhoneNumber(e.target.value)) {
        if (!errorExist) {
          errorFields.push("mobile");
        }
      } else {
        if (errorExist) {
          errorFields.splice(mobileIndex, 1);
        }
      }
    }

    const isValidEmail =
      e.target.name === "email" ? !validateEmail(e.target.value) : false;
    const isValidPhone =
      e.target.name === "mobile" ? !validatePhoneNumber(e.target.value) : false;

    this.setState({
      staff: updatedStaff,
      disableSave: isStaffEqual || isStaffEmpty || isValidEmail || isValidPhone,
      errorFields,
      confirmDelete: false,
    });
  };

  handleRoleChange = (value) => {
    const updatedStaff = { ...this.state.staff, role: value };
    const isStaffEqual = isEqual(this.props.staff, updatedStaff);
    const isStaffEmpty = isEmpty(updatedStaff);

    this.setState({
      staff: updatedStaff,
      disableSave: isStaffEqual || isStaffEmpty,
    });
  };

  saveStaff = () => {
    if (this.props.new) {
      this.props.addStaff(this.state.staff).then(() => {
        this.props.onSave();
        this.onClose();
      });
    } else {
      this.props.updateStaff(this.state.staff).then(() => {
        this.props.onSave();
        this.setState({ disableSave: true });
      });
    }
  };

  onClose = () => {
    this.props.onClose();
    this.setState({
      staff: {},
      confirmDelete: false,
      errorFields: [],
      confirmDelete: false,
    });
  };

  onStaffRemove = () => {
    this.setState({ confirmDelete: true });
  };

  onStaffRemoveConfirmed = () => {
    this.props.deleteStaff(this.state.staff.staff_id).then(() => {
      this.onClose();
    });
  };

  render() {
    const {
      state: { staff },
    } = this;
    const isNew = this.props.new;
    const emailError = this.state.errorFields.indexOf("email") > -1;
    const mobileError = this.state.errorFields.indexOf("mobile") > -1;

    return (
      <Dialog onClose={this.onClose} open={this.props.open} scroll="body">
        <DialogTitle id="customized-dialog-title" onClose={this.onClose}>
          {!isNew ? "Edit Staff" : "Add new staff"}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <div className="wrapper">
            <div className="staff_section">
              <TextField
                size="small"
                className="staff_input"
                name="name"
                label="Full Name"
                variant="outlined"
                onChange={this.handleStaffChange}
                value={staff.name || ""}
                required
              />
              <TextField
                size="small"
                className="staff_input"
                name="email"
                label="Email ID"
                variant="outlined"
                onChange={this.handleStaffChange}
                value={staff.email || ""}
                type="email"
                error={emailError}
                helperText={emailError ? "Enter a valid email id" : ""}
                inputProps={{
                  autoComplete: "no",
                }}
                required
              />
              <TextField
                size="small"
                className="staff_input"
                name="mobile"
                label="Mobile Number"
                variant="outlined"
                onChange={this.handleStaffChange}
                value={staff.mobile || ""}
                type="tel"
                error={mobileError}
                helperText={mobileError ? "Enter a valid 10 digit number" : ""}
                required
              />
            </div>
            <div className="staff_section_two">
              <TextField
                size="small"
                className="staff_input"
                name="designation"
                label="Designation"
                variant="outlined"
                onChange={this.handleStaffChange}
                value={staff.designation || ""}
                required
              />
              <FormControl
                className="staff_input"
                variant="outlined"
                error={false}
                style={{ minWidth: 80 }}
              >
                <InputLabel size="small">Role</InputLabel>
                <Select
                  value={staff.role || ""}
                  style={{ minWidth: 100, margin: 0, maxHeight: 40 }}
                  onChange={(e) => this.handleRoleChange(e.target.value)}
                  label="Role"
                >
                  {this.props.roles.map((role, key) => (
                    <MenuItem key={key} value={role.role_id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="button-wrapper">
            <Button
              className="staff_buttons"
              variant="contained"
              onClick={this.saveStaff}
              color="primary"
              disabled={this.state.disableSave}
              autoFocus
            >
              Save Changes
            </Button>

            {!isNew ? (
              this.state.confirmDelete ? (
                <Button
                  variant="outlined"
                  className="staff_buttons staff_delete"
                  color="primary"
                  onClick={this.onStaffRemoveConfirmed}
                >
                  Confirm removal of this staff
                </Button>
              ) : (
                <Button
                  className="staff_buttons staff_delete"
                  color="primary"
                  onClick={this.onStaffRemove}
                >
                  Delete Staff
                </Button>
              )
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connect(() => ({}), { addStaff, updateStaff, deleteStaff })(
  StaffForm
);
