import { loginExpired } from "../modules/auth";

export default function clientMiddleware(fetch) {
  return ({ dispatch, getState }) => {
    return (next) => (action) => {
      if (typeof action === "function") {
        return action(dispatch, getState);
      }

      const { promise, types, ...rest } = action;
      if (!promise) {
        return next(action);
      }

      const [REQUEST, SUCCESS, FAILURE] = types;
      next({ ...rest, type: REQUEST });

      const actionPromise = promise(fetch);
      actionPromise
        .then(
          (payload) => next({ ...rest, payload, type: SUCCESS }),
          (error) => {
            // Checks whether there is authorisation error
            if (error.response.status === 401) {
              dispatch(loginExpired());
            }

            return next({ ...rest, error, type: FAILURE });
          }
        )
        .catch((error) => {
          console.error("Error occured in middleware:", error);
          next({ ...rest, error, type: FAILURE });
        });

      return actionPromise;
    };
  };
}
