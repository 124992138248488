import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  IconButton,
  Typography,
  LinearProgress,
  Collapse,
} from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import AddressForm from "../../../components/AddressForm/AddressForm";
import {
  getAllCountries,
  saveAccountDetails,
  updateAccountDetails,
} from "../../../redux/modules/settings";

function formatAddress(address) {
  return address && address.line1
    ? `${address.line1}, ${
        address.line2 ? `${address.line2}, ${address.city}` : `${address.city}`
      }, ${address.state}, ${address.country} - ${address.zipcode}`
    : "";
}

function CADetailsItem({ account }) {
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.settings.countries);
  const [edit, setEdit] = useState(false);

  const onEditClick = () => {
    setEdit(true);
  };

  const onCancelUpdate = () => {
    setEdit(false);
  };

  const onUpdate = (account) => {
    return dispatch(updateAccountDetails(account));
  };

  return (
    <Card className="c-account-card1">
      <Collapse in={!edit}>
        <div className="c-account-card">
          <div className="c-a-card-first">
            <Typography variant="h6">{account.name}</Typography>
            <Typography variant="caption">
              {formatAddress(account.address) || ""}
            </Typography>
            <div>
              <Typography variant="caption">
                {account.phone ? `Phone: ${account.phone}` : ""}
              </Typography>
            </div>
          </div>
          <div>
            <Button className="bdr-btn-style-green" onClick={onEditClick}>
              Edit
            </Button>
          </div>
        </div>
      </Collapse>
      <Collapse in={edit}>
        <Card className="address-form-card">
          <AddressForm
            cancel={onCancelUpdate}
            update={onUpdate}
            account={account}
            countries={countries}
            edit
          />
        </Card>
      </Collapse>
    </Card>
  );
}

function CAccountDetails({ load }) {
  const dispatch = useDispatch();
  const [addNew, setAddNew] = useState(false);
  const countries = useSelector((state) => state.settings.countries);
  const accounts = useSelector((state) => state.settings.detailedAccounts);
  const [loadingC, setLoadingC] = useState(false);

  useEffect(() => {
    if (!countries.length && !loadingC) {
      setLoadingC(true);
      dispatch(getAllCountries()).then(() => {
        setLoadingC(false);
      });
    }
  }, []);

  const onAddClick = () => {
    setAddNew(true);
  };

  const onCancelNew = () => {
    setAddNew(false);
  };

  const onSaveNew = (account) => {
    return dispatch(saveAccountDetails(account)).then(() => {
      return setAddNew(false);
    });
  };

  return (
    <div className="ca-details-cont">
      {load ? (
        <LinearProgress />
      ) : (
        <Collapse in={!addNew}>
          {accounts.length ? (
            accounts.map((account, i) => (
              <CADetailsItem key={i} account={account} />
            ))
          ) : (
            <Typography
              className="empty-message"
              component="div"
              variant="caption"
            >
              No Accounts available to show...Add one now.
            </Typography>
          )}
        </Collapse>
      )}
      <div className="address-add-wrapper">
        {!addNew && !load ? (
          <IconButton className="address-add-btn" onClick={onAddClick}>
            <AddCircleOutline fontSize="large" />
          </IconButton>
        ) : null}
        <Collapse in={addNew}>
          <Card className="address-form-card">
            <AddressForm
              cancel={onCancelNew}
              save={onSaveNew}
              countries={countries}
              account
            />
          </Card>
        </Collapse>
      </div>
    </div>
  );
}

export default CAccountDetails;
