import React, { useEffect, useState } from "react";
import { Card, Box, LinearProgress, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { verifyEmailToken } from "../../redux/modules/auth";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";

function ConfirmEmail(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const [notLoading, setNotLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    dispatch(verifyEmailToken(params.token))
      .then(() => {
        setNotLoading(false);
        setSuccess(true);
      })
      .catch(() => {
        setNotLoading(false);
        setSuccess(false);
      });
  }, []);

  return (
    <div>
      <div className="email-confirm-wrapper">
        <div className="logodiv">
          <Box
            component="img"
            style={{ height: 81, marginBottom: 20 }}
            src="/logo-FL.png"
          />
        </div>
        <div className="email-confirm-content">
          {notLoading ? (
            <LinearProgress />
          ) : (
            <>
              {success ? (
                <>
                  <CheckCircleIcon className="success-color" fontSize="large" />
                  <Typography className="success-container marginT15">
                    Your email has been added successfully to the FormsLeads
                    portal. Login with your email and old password to access the
                    portal.
                  </Typography>
                </>
              ) : (
                <>
                  <CancelIcon color="error" fontSize="large" />
                  <Typography className="error-container marginT15">
                    Email reset token expired or not valid. Please change your
                    email again in the FormsLeads admin portal to update.
                  </Typography>
                </>
              )}
              <Link className="go-to-link" to="/login">
                <Typography color="primary">Go to Login page</Typography>
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ConfirmEmail;
