import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Drawer } from "@mui/material";
import {
  Dashboard as DashboardIcon,
  People as PeopleIcon,
  ShoppingBasket as ShoppingBasketIcon,
  AccountBox as AccountBoxIcon,
  Settings as SettingsIcon,
  Assessment as AssessmentIcon,
  Assignment as AssignmentIcon,
  AssignmentInd as AssignmentIndIcon,
  Contacts as ContactsIcon,
  Business as BusinessIcon,
  MonetizationOn as MonetizationOnIcon,
  Email as EmailIcon,
  Help as HelpIcon,
  Tune as TuneIcon,
} from "@mui/icons-material";
import Footer from "../Footer";
import { SidebarNav } from "./components";


const Sidebar = (props) => {
  const { open, variant, onClose, onMenuClick, className, ...rest } = props;

  const pages = [
    {
      title: "Dashboard",
      href: "/dashboard",
      icon: <DashboardIcon />,
    },
    {
      title: "Forms",
      href: "/forms",
      icon: <AssignmentIcon />,
    },
    // {
    //   title: 'CreateForm',
    //   href: '/createform',
    //   icon: <HighlightIcon />
    // },
    {
      title: "Leads",
      href: "/leads",
      icon: <AssignmentIndIcon />,
    },
    {
      title: "Contacts",
      href: "/contacts",
      icon: <ContactsIcon />,
    },
    {
      title: "Accounts",
      href: "/contact_accounts",
      icon: <BusinessIcon />,
    },
    {
      title: "Deals",
      href: "/deals",
      icon: <MonetizationOnIcon />,
    },
    {
      title: "Staffs",
      href: "/staffs",
      icon: <PeopleIcon />,
    },
    {
      title: "Email",
      href: "/email",
      icon: <EmailIcon />,
    },
    // {
    //   title: 'Reports',
    //   href: '/reports',
    //   icon: <AssessmentIcon />
    // },
    {
      title: "Manage",
      href: "/manage",
      icon: <TuneIcon />,
    },
    {
      title: "Settings",
      href: "/settings",
      icon: <SettingsIcon />,
    },
    {
      title: "Help",
      href: "/docs",
      icon: <HelpIcon />,
    },
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: "sidebar-drawer"}}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={`${clsx(className)} sidebar-main`}>
        <SidebarNav
          className={"nav"}
          pages={pages}
          onMenuClick={onMenuClick}
        />

        <Footer className={"foot-style"} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  onMenuClick: PropTypes.func.isRequired,
};

export default Sidebar;
