import React, { PureComponent } from 'react';

const styles = {
    colorShower: {
        height: 5,
        width: 5,
        padding: 10,
        margin: "15px 0",
        borderRadius: 15,
        border: "none",
        display: "inline-block",
        cursor: "pointer"
      },
    input: {
        height: 0,
        width: 0,
        outline: "none",
        border: "none",
        background: "none",
        padding: 0,
        margin: 0,
        opacity: 0
    }
}

class ColorPicker extends PureComponent {
    constructor(props) {
        super(props);
        this.colorInput = React.createRef();
    }

    state = {
        color: this.props.color
    }

    componentDidUpdate(prevProps) {
        if (prevProps.color !== this.props.color) {
            this.setState({ color: this.props.color });
        }
    }

    onClick = () => {
        this.colorInput.current.click();
    }

    onColorChange = (e) => {
        const newColor = e.target.value;

        this.setState({ color: newColor })
        this.props.setColor(newColor);
    }

    render() {
        return (
            <div>
                <span style={{ ...styles.colorShower, backgroundColor: this.state.color }} onClick={this.onClick} ></span>
                <input ref={this.colorInput} type="color" value={this.props.color} style={styles.input} onChange={this.onColorChange} />
            </div>
        );
    }
}

export default ColorPicker;
