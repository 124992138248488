import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBodyFormats,
  getEmailTemplates,
  getTempDetails,
  updateFormat,
} from "../../../redux/modules/email";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
  LinearProgress,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
} from "@mui/material";
import DialogTitle from "../../../components/DialogTitle";

function SocialLinks({ links }) {
  return links.map((so, s) => (
    <div key={s} className="social-logo-holder">
      <Box
        key={s}
        className="social-logo"
        component="img"
        src={`data:image/png;base64,${so.image}`}
      />
    </div>
  ));
}

function FormatItem({ format }) {
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.email.templates);
  const [open, setOpen] = useState(false);
  const [body, setBody] = useState(format.body);
  const [saving, setSaving] = useState(false);
  const temp = (templates.length && templates[0]) || {};

  const onEditClick = () => {
    setOpen(true);
  };

  const onEditClose = () => {
    setOpen(false);
    setBody(format.body);
  };

  const onBodyChange = (e) => {
    setBody(e.target.value);
  };

  const onSaveformat = () => {
    setSaving(true);
    dispatch(updateFormat({ ...format, body })).then(() => {
      setSaving(false);
    });
  };

  return (
    <>
      <Button
        className="sec-button"
        variant="outlined"
        color="secondary"
        onClick={onEditClick}
      >
        Edit
      </Button>
      <Dialog open={open} onClose={onEditClose} maxWidth={false}>
        <DialogTitle onClose={onEditClose}>Edit ({format.name})</DialogTitle>
        <Divider />
        <DialogContent>
          <div className="email-container">
            <div className="email-header">
              <div className="email-logo-section">
                <Box
                  className="logo-in-email"
                  component="img"
                  src={`data:image/png;base64,${temp.header_logo}`}
                />
              </div>
              <Typography className="email-header-text">
                {temp.header_text}
              </Typography>
              <div className="email-blur"></div>
            </div>
            <div className="email-body-format-warpper">
              <textarea
                className="email-format-body"
                onChange={onBodyChange}
                value={body}
                autoFocus
              />
            </div>
            {saving ? <LinearProgress /> : null}
            <div className="email-footer-first">
              <div className="first-left">
                <Typography className="footer-texts-bold">
                  {temp.text1}
                </Typography>
                <Typography style={{ marginTop: 10 }}>{temp.text2}</Typography>
              </div>
              <div className="first-right">
                <Typography className="footer-texts-bold">
                  {temp.text3}
                </Typography>
                <Typography
                  style={{ marginTop: 10 }}
                  className="footer-texts-bold"
                >
                  {temp.text4}
                </Typography>
              </div>
              <div className="email-blur"></div>
            </div>
            <div className="email-footer-second">
              <Typography className="footer-texts email-center-text-input email-text-width">
                {temp.text5}
              </Typography>
              <Typography className="footer-text6 email-center-text-input email-text-width">
                {temp.text6}
              </Typography>
              <div className="social-links">
                {temp.social ? <SocialLinks links={temp.social} /> : null}
              </div>
              <Typography className="footer-text7 email-center-text-input email-text-width">
                {temp.text7}
              </Typography>
              <div className="email-blur"></div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: 15 }}
            onClick={onSaveformat}
          >
            Save Format
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function BodyFormats() {
  const dispatch = useDispatch();
  const formats = useSelector((state) => state.email.formats);
  const templates = useSelector((state) => state.email.templates);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!formats.length) {
      setLoading(true);
      dispatch(getBodyFormats()).then(() => {
        if (!templates.length) {
          dispatch(getEmailTemplates()).then((res) => {
            if (res["result"]) {
              dispatch(getTempDetails(res["result"][0]["temp_id"]));
            }
          });
        }

        setLoading(false);
      });
    }
  }, []);

  return (
    <div>
      <Paper className="staff_paper">
        {loading ? <LinearProgress /> : null}
        <List className="staff_list">
          {formats.map((obj, f) => (
            <ListItem key={f} className="list_item">
              <ListItemText
                primary={<Typography>{obj.name}</Typography>}
                secondary={
                  <Typography variant="caption">
                    You can edit the email body here.
                  </Typography>
                }
              />
              <FormatItem format={obj} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </div>
  );
}

export default BodyFormats;
