import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  TextField,
  Button,
  LinearProgress,
  Divider,
} from "@mui/material";
import {
  getPriorityList,
  updatePriorityList,
} from "../../../redux/modules/settings";
import { Remove, Close, Add } from "@mui/icons-material";
import ColorPicker from "../../../components/ColorPicker";
import DialogTitle from "../../../components/DialogTitle";

function isPrioritListEqual(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every(
      (obj, index) =>
        obj.priority_label === b[index]["priority_label"] &&
        obj.color === b[index]["color"]
    )
  );
}

function isPriorityListEmpty(p) {
  const emptyPriorities = p.filter((i) => !i.priority_label);

  return !!emptyPriorities.length;
}

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "row",
  },
  buttonContainer: {
    textAlign: "left",
    marginTop: 15,
  },
};

class PriorityDialog extends PureComponent {
  state = {
    priorities: this.props.priorityList,
    disableSave: true,
    openInfo: false,
    leadCount: "",
  };

  componentDidUpdate(preProps) {
    if (
      isPrioritListEqual(this.props.priorityList, this.state.priorities) &&
      !this.state.disableSave
    ) {
      this.setState({ disableSave: true });
    }

    // Here the API call happens only when there is no data in the redux store.
    if (
      !this.state.priorities.length &&
      this.props.open &&
      this.props.open !== preProps.open
    ) {
      if (!this.props.priorityList.length) {
        this.props.fetchPriorityList().then((res) => {
          this.setState({ priorities: res.result || [] });
        });
      } else {
        this.setState({
          priorities: this.props.priorityList,
          disableSave: true,
        });
      }
    }
  }

  handlePriorityChange = (j, p, value) => {
    const prevPriorities = [...this.state.priorities];
    const pInProps = this.props.priorityList.find(
      (t) => t.priority_id === p.priority_id
    );
    prevPriorities[j] = { ...p, priority_label: value };
    const isEqual = isPrioritListEqual(this.props.priorityList, prevPriorities);
    const isEmpty = isPriorityListEmpty(prevPriorities);

    this.setState({
      priorities: prevPriorities,
      disableSave: isEqual || isEmpty || (!pInProps && !value),
    });
  };

  handleRemoveOptions = (j) => {
    const prevPriorities = [...this.state.priorities];
    const leadCount = Number(prevPriorities[j]["lead_count"] || 0);
    const isEmpty = isPriorityListEmpty(prevPriorities);

    if (!!leadCount) {
      this.setState({ leadCount, openInfo: true });
    } else {
      prevPriorities.splice(j, 1);
      this.setState({ priorities: prevPriorities, disableSave: isEmpty });
    }
  };

  handleAddOptions = () => {
    const priorities = this.state.priorities;
    const prevPriorities = [...priorities];
    const newPID = Number(priorities[priorities.length - 1]["priority_id"]) + 1;
    prevPriorities.push({
      priority_id: newPID.toString(),
      priority_label: "",
      rank: newPID.toString(),
      color: "#000000",
    });
    this.setState({ priorities: prevPriorities, disableSave: true });
  };

  onClose = () => {
    this.setState({ priorities: this.props.priorityList, disableSave: true });
    this.props.onClose();
  };

  setColor = (j, p, color) => {
    const prevPriorities = [...this.state.priorities];
    prevPriorities[j] = { ...p, color: color };
    this.setState({
      priorities: prevPriorities,
      disableSave: !p.priority_label,
    });
  };

  savePriorities = () => {
    this.props.updatePriority(this.state.priorities);
  };

  infoClose = () => {
    this.setState({ openInfo: false });
  };

  render() {
    return (
      <div>
        <Dialog
          onClose={this.onClose}
          aria-labelledby="customized-dialog-title"
          open={this.props.open}
          scroll="body"
        >
          <DialogTitle id="customized-dialog-title" onClose={this.onClose}>
            Edit Priority List
          </DialogTitle>
          <Divider />
          <DialogContent>
            {this.state.priorities.map((priority, i) => {
              const priorityLength = this.state.priorities.length;

              return (
                <div style={styles.wrapper} key={i}>
                  <ColorPicker
                    color={priority.color}
                    setColor={(color) => this.setColor(i, priority, color)}
                  />
                  <TextField
                    size="small"
                    className="text-field"
                    required
                    name="item"
                    label={`Priority ${i + 1}`}
                    variant="outlined"
                    onChange={(e) =>
                      this.handlePriorityChange(i, priority, e.target.value)
                    }
                    value={priority.priority_label}
                    margin="dense"
                  />
                  <IconButton
                    disabled={priorityLength === 1}
                    onClick={() => this.handleRemoveOptions(i)}
                  >
                    <Remove />
                  </IconButton>
                  <IconButton
                    disabled={priorityLength !== i + 1}
                    onClick={this.handleAddOptions}
                  >
                    <Add />
                  </IconButton>
                </div>
              );
            })}
            {this.props.loading ? <LinearProgress /> : null}
            <div style={styles.buttonContainer}>
              <Button
                variant="contained"
                onClick={this.savePriorities}
                color="primary"
                disabled={this.state.disableSave}
                autoFocus
              >
                Save Changes
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog open={this.state.openInfo} onClose={this.infoClose}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Since ${this.state.leadCount} leads are using this priority, you cannot delete this.`}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  priorityList: state.settings.priorityList,
  loading: state.settings.loading,
});

export default connect(mapStateToProps, {
  fetchPriorityList: getPriorityList,
  updatePriority: updatePriorityList,
})(PriorityDialog);
