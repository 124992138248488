import { v4 as uuid } from "uuid";

// ACTION TYPES
const SET_ALERT = "SET_ALERT";
const REMOVE_ALERT = "REMOVE_ALERT";

// INITIAL
const initialState = [];

// REDUCER
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ALERT:
      return [...state, payload];
    case REMOVE_ALERT:
      return state.filter((alert) => alert.id !== payload);
    default:
      return state;
  }
}

// ACTION CREATORS
export const setAlert =
  (msg, alertType, timeout = 5000) =>
  (dispatch) => {
    const id = uuid();
    dispatch({
      type: SET_ALERT,
      payload: { msg, alertType, id },
    });

    setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
  };
