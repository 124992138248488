import React, { PureComponent, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  TextField,
  Typography,
  Popover,
  Divider,
} from "@mui/material";
import { AddCircleOutline as AddCircleOutlineIcon } from "@mui/icons-material";
import {
  getAllAccount,
  addAccount,
  removeAccount,
  updateAccount,
} from "../../../redux/modules/leads";
import { getAllAccountDetails } from "../../../redux/modules/settings";
import CAccountDetails from "./CAccountDetails";
import DialogTitle from "../../../components/DialogTitle";

function AccountItem({
  account,
  index,
  cancelNew,
  isNew,
  addNew,
  update,
  remove,
  updateAccountState,
}) {
  const [editable, setEditable] = useState(isNew);
  const [name, setName] = useState(account.name);
  const [disableSave, setDisableSave] = useState(!name);
  const [anchorEl, setAnchorEl] = useState(null);
  const [count, setCount] = useState(0);

  const handleAccountNameChange = (e) => {
    const value = e.target.value;
    const save = !value || value === account.name;

    if (disableSave != save) {
      setDisableSave(save);
    }

    setName(value);
  };

  const onEdit = () => {
    setEditable(true);
    setDisableSave(true);
  };

  const onCancel = () => {
    if (isNew) {
      cancelNew(index);
    } else {
      setEditable(false);
      setName(account.name);
    }
  };

  const onDelete = (e) => {
    e.persist();
    setAnchorEl(e.currentTarget);
    remove(account.account_id)
      .then(() => {
        updateAccountState();
      })
      .catch((error) => {
        const errorData = error.response.data;
        if (errorData.count) {
          setCount(errorData.count);
        }
      });
  };

  const onSave = () => {
    if (isNew) {
      addNew(name).then(() => {
        setEditable(false);
        updateAccountState();
      });
    } else {
      update({ ...account, name }).then(() => {
        setEditable(false);
        updateAccountState();
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="role_item">
      {editable ? (
        <TextField
          size="small"
          className="text-field"
          name="item"
          label="Account"
          variant="outlined"
          onChange={handleAccountNameChange}
          value={name}
          required
        />
      ) : (
        <Typography className="role_name">{account.name}</Typography>
      )}
      {editable ? (
        <div className="save_cancel_wrapper">
          <Button onClick={onSave} disabled={disableSave}>
            Save
          </Button>
          <Button onClick={onCancel} style={{ color: "#000" }}>
            Cancel
          </Button>
        </div>
      ) : (
        <div className="save_cancel_wrapper">
          <Button className="bdr-btn-style-green" onClick={onEdit}>
            Edit
          </Button>
          <Button className="bdr-btn-style-chu" onClick={onDelete}>
            Delete
          </Button>
          <Popover
            id={account.account_id}
            open={!!count && !!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography
              style={{ padding: 15, maxWidth: 200 }}
            >{`You cannot delete this account, since ${`${count} ${
              Number(count) === 1 ? "contact is" : "contacts are"
            }`} asigned to this account.`}</Typography>
          </Popover>
        </div>
      )}
    </div>
  );
}

class ContactAccounts extends PureComponent {
  state = {
    openEdit: false,
    disableSave: true,
    openAccountEdit: false,
    openAccountDetails: false,
    accounts: [],
    loading: false,
  };

  componentDidUpdate(_prevProps, prevState) {
    if (this.state.openAccountEdit && !prevState.openAccountEdit) {
      this.setState({ loading: true });
      this.props.getAllAccount().then((res) => {
        this.setState({ accounts: res.result, loading: false });
      });
    }

    if (this.state.openAccountDetails && !prevState.openAccountDetails) {
      this.setState({ loading: true });
      this.props.getAllAccountDetails().then(() => {
        this.setState({ loading: false });
      });
    }
  }

  onChangeClick = () => {
    this.setState({ openEdit: true });
  };

  onChangeAccountClick = () => {
    this.setState({ openAccountEdit: true });
  };

  onChangeADClick = () => {
    this.setState({ openAccountDetails: true });
  };

  onADClose = () => {
    this.setState({ openAccountDetails: false });
  };

  onAccountClose = () => {
    this.setState({ openAccountEdit: false, accounts: this.props.accounts });
  };

  onAddNewAccount = () => {
    const accounts = this.state.accounts;
    const prevAccounts = [...accounts];
    prevAccounts.push({
      account_id: "",
      name: "",
    });

    this.setState({ accounts: prevAccounts });
  };

  onCancelNew = (i) => {
    const prevAccounts = [...this.state.accounts];

    prevAccounts.splice(i, 1);
    this.setState({ accounts: prevAccounts });
  };

  updateAccountState = () => {
    this.setState({ accounts: this.props.accounts });
  };

  render() {
    return (
      <div>
        <Card className="list_paper">
          <List className="settings_list">
            <ListItem className="list_item">
              <ListItemText
                primary={<Typography>Accounts</Typography>}
                secondary={
                  <Typography variant="caption">
                    You can add or edit contact accounts here.
                  </Typography>
                }
              />
              <Button
                autoFocus
                onClick={this.onChangeAccountClick}
                color="primary"
                className="sec-button"
              >
                Change
              </Button>
            </ListItem>
            <ListItem className="list_item">
              <ListItemText
                primary={<Typography>Account Details</Typography>}
                secondary={
                  <Typography variant="caption">
                    You can view or edit contact account details here.
                  </Typography>
                }
              />
              <Button
                autoFocus
                onClick={this.onChangeADClick}
                color="primary"
                className="sec-button"
              >
                Change
              </Button>
            </ListItem>
          </List>
        </Card>
        <Dialog open={this.state.openAccountEdit} onClose={this.onAccountClose}>
          <DialogTitle onClose={this.onAccountClose}>
            Contact Accounts
          </DialogTitle>
          <Divider />
          <DialogContent>
            {this.state.loading ? (
              <LinearProgress />
            ) : this.state.accounts.length ? (
              this.state.accounts.map((account, i) => (
                <AccountItem
                  key={account.account_id}
                  index={i}
                  account={account}
                  isNew={!account.name}
                  cancelNew={this.onCancelNew}
                  addNew={this.props.addAccount}
                  update={this.props.updateAccount}
                  remove={this.props.removeAccount}
                  updateAccountState={this.updateAccountState}
                  total={this.props.accounts.length}
                />
              ))
            ) : (
              <Typography
                className="empty-message"
                variant="caption"
                component="div"
              >
                No Accounts available to show...Add one now.
              </Typography>
            )}
            {this.props.loading ? <LinearProgress /> : null}
            <div className="role_add_btn_wrapper">
              <Button
                className="role_add_button"
                onClick={this.onAddNewAccount}
                disabled={
                  this.props.accounts.length !== this.state.accounts.length
                }
              >
                <AddCircleOutlineIcon />
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.openAccountDetails}
          onClose={this.onADClose}
          scroll="body"
        >
          <DialogTitle onClose={this.onADClose}>Contact Accounts</DialogTitle>
          <Divider />
          <DialogContent>
            <CAccountDetails load={this.state.loading} />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    accounts: state.leads.accounts,
    loading: state.settings.loading,
  }),
  {
    getAllAccount,
    addAccount,
    removeAccount,
    updateAccount,
    getAllAccountDetails,
  }
)(ContactAccounts);
