import React, { useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  LinearProgress,
} from "@mui/material";
import {
  MoreVert,
  DeleteForever as DeleteForeverIcon,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { deleteADeal } from "../../../redux/modules/deals";
import AccessControl from "../../../components/AccessControl";

function Options({ deal_id, setLoading }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDeleteClick = () => {
    setLoading(true);
    dispatch(deleteADeal(deal_id)).then(() => {
      setLoading(false);
      handleClose();
    });
  };

  return (
    <>
      <IconButton style={{ padding: 0 }} onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <AccessControl name="delete_deals">
          <MenuItem onClick={onDeleteClick}>
            <ListItemIcon>
              <DeleteForeverIcon fontSize="small" />
              <Typography variant="inherit" style={{ marginLeft: 5 }}>
                Delete
              </Typography>
            </ListItemIcon>
          </MenuItem>
        </AccessControl>
      </Menu>
    </>
  );
}

export default Options;
