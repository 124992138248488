import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  TextField,
  Typography,
  Divider,
  DialogActions,
} from "@mui/material";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";

import DialogTitle from "../../../components/DialogTitle";
import {
  getAppKey,
  generateAppKey,
  appKeyDomainCreation,
  deleteAppKeyDomain,
} from "../../../redux/modules/settings";

function AppKey() {
  const dispatch = useDispatch();
  const [isDomainModal, setIsDomainModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [appKeyData, setAppKeyData] = useState({});
  const [IsAppKeyAvailble, setIsAppKeyAvailble] = useState(false);
  const domainValidator =
    /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i;
  const [
    regenerateAppkeyConfirmationModal,
    setRegenerateAppKeyConfirmationModal,
  ] = useState(false);

  const fetchAppKey = () => {
    dispatch(getAppKey()).then((res) => {
      try {
        if (res.appKey) {
          setAppKeyData(res);
          setIsAppKeyAvailble(true);
        }
      } catch (err) {
        console.error(err);
      }
    });
  };

  useEffect(() => {
    fetchAppKey();
  }, []);

  const handleDomainOpen = () => {
    setIsDomainModal(true);
  };

  const handleDomainClose = () => {
    setIsDomainModal(false);
  };

  const handleAddNewDomain = () => {
    setAppKeyData({
      ...appKeyData,
      domains: [
        ...appKeyData.domains,
        {
          domain: "",
          isNew: true,
        },
      ],
    });
  };

  const handleRemoveNewDomain = () => {
    setAppKeyData({
      ...appKeyData,
      domains: appKeyData.domains.filter((i) => i.isNew !== true),
    });
  };

  const handleGenerateNewAppKey = () => {
    setRegenerateAppKeyConfirmationModal(false);
    setIsLoading(true);
    dispatch(generateAppKey()).then((res) => {
      if (res.status) {
        fetchAppKey();
      }
      setIsLoading(false);
    });
  };

  const handleCloseAppKeyRegenerateConfirmationModal = () => {
    setRegenerateAppKeyConfirmationModal(false);
  };

  const handleOpenAppKeyRegenerateConfirmationModal = () => {
    setRegenerateAppKeyConfirmationModal(true);
  };

  // DOMAIN ITEMS
  const DomainItems = ({
    item,
    removeNewDomain,
    index,
    fetchAppKey,
    setIsLoading,
  }) => {
    const [isDomainEdit, setIsDomainEdit] = useState(item.isNew || false);
    const [value, setValue] = useState(item.domain);
    const [disableSave, setDisableSave] = useState(true);
    const [validationErr, setValidationErr] = useState(false);

    const handleCancelEditDomain = () => {
      setIsDomainEdit(false);
      setValue(item.domain);
      if (item.isNew) {
        removeNewDomain();
      }
    };

    const handleDomainNameChange = (e) => {
      const value = e.target.value;
      setValue(value);
      if (value) {
        setDisableSave(false);
      } else {
        setDisableSave(true);
        setValidationErr(false);
      }
    };

    const onHandleSave = () => {
      const validateDomain = domainValidator.test(value.trim());
      if (validateDomain) {
        dispatch(
          appKeyDomainCreation({
            domain: value.trim(),
          })
        ).then((res) => {
          setIsLoading(true);
          if (res.status) {
            fetchAppKey();
            setIsDomainEdit(false);
          } else if ((res.message = "Invalid Domain")) {
            setValidationErr(true);
          }
          setIsLoading(false);
        });
      } else {
        setValidationErr(true);
      }
    };

    const handleDeleteDomain = () => {
      setIsLoading(true);
      dispatch(
        deleteAppKeyDomain({
          domain_id: item.doman_id,
        })
      ).then((res) => {
        if (res.status) {
          fetchAppKey();
        }
        setIsLoading(false);
      });
    };

    return (
      <div className="role_item" key={index}>
        {isDomainEdit ? (
          <>
            {" "}
            <TextField
              error={validationErr}
              size="small"
              className="text-field"
              name="item"
              label="Domain"
              variant="outlined"
              onChange={handleDomainNameChange}
              value={value}
              required
              helperText={validationErr && "Invalid domain."}
            />
          </>
        ) : (
          <Typography className="role_name">{value}</Typography>
        )}
        {isDomainEdit ? (
          <div className="save_cancel_wrapper">
            <Button onClick={onHandleSave} disabled={disableSave}>
              Save
            </Button>
            <Button onClick={handleCancelEditDomain} style={{ color: "#000" }}>
              Cancel
            </Button>
          </div>
        ) : (
          <div className="save_cancel_wrapper">
            <Button className="bdr-btn-style-chu" onClick={handleDeleteDomain}>
              Delete
            </Button>
          </div>
        )}
      </div>
    );
  };
  // DOMAIN ITEMS***

  return (
    <>
      {IsAppKeyAvailble ? (
        <>
          <Typography className="settings_section" variant="h6">
            App Key
          </Typography>
          <Card className="list_paper">
            {isLoading && <LinearProgress />}
            <List className="settings_list">
              <ListItem className="list_item">
                <ListItemText
                  primary={<Typography>{appKeyData?.appKey}</Typography>}
                  secondary={
                    <Typography variant="caption">
                      This is your app key.
                    </Typography>
                  }
                />
                <Button
                  autoFocus
                  onClick={handleOpenAppKeyRegenerateConfirmationModal}
                  color="primary"
                  className="sec-button"
                >
                  Generate new app key
                </Button>
              </ListItem>
            </List>
          </Card>
          <Typography className="settings_section" variant="h6">
            Domains
          </Typography>
          <Card className="list_paper">
            <List className="settings_list">
              <ListItem className="list_item">
                <ListItemText
                  primary={<Typography>Domains</Typography>}
                  secondary={
                    <Typography variant="caption">
                      You can add or edit domains here.
                    </Typography>
                  }
                />
                <Button
                  autoFocus
                  onClick={handleDomainOpen}
                  color="primary"
                  className="sec-button"
                >
                  Change
                </Button>
              </ListItem>
            </List>
          </Card>

          <Dialog open={isDomainModal} onClose={handleDomainClose}>
            <DialogTitle onClose={handleDomainClose}>Domains</DialogTitle>
            {isLoading ? <LinearProgress /> : null}
            <Divider />
            <DialogContent>
              {appKeyData?.domains?.length ? (
                appKeyData.domains.map((item, index) => {
                  return (
                    <DomainItems
                      item={item}
                      removeNewDomain={handleRemoveNewDomain}
                      index={index}
                      fetchAppKey={fetchAppKey}
                      setIsLoading={setIsLoading}
                    />
                  );
                })
              ) : (
                <Typography
                  className="empty-message"
                  component="div"
                  variant="caption"
                >
                  No domain(s) available...
                </Typography>
              )}

              <div className="domain_add_button">
                <Button
                  className="role_add_button"
                  onClick={handleAddNewDomain}
                >
                  <AddCircleOutlineIcon />
                </Button>
              </div>
            </DialogContent>
          </Dialog>
          <Dialog
            open={regenerateAppkeyConfirmationModal}
            onClose={handleCloseAppKeyRegenerateConfirmationModal}
          >
            <DialogContent>
              <Typography className="form-wc">
                <i>
                  <InfoIcon style={{ marginRight: 5 }} color="action" />
                </i>
                <div className="app-key-warning">
                  <span>
                    Warning: Regenerating the app key will replace the existing
                    key. This will affect all the APIs using the current app
                    key. Make sure to change the app key in places where APIs
                    are used, after confirming here.
                  </span>
                  <span>Do you really want to regenerate?</span>
                </div>
              </Typography>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button
                onClick={handleCloseAppKeyRegenerateConfirmationModal}
                color="primary"
                style={{ fontSize: 14 }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleGenerateNewAppKey}
                style={{ color: "red", fontSize: 14 }}
                autoFocus
              >
                {" "}
                Confirm{" "}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <>
          <Typography
            className="empty-message"
            component="div"
            variant="caption"
          >
            No app key available...
          </Typography>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleGenerateNewAppKey}
          >
            Generate app key
          </Button>
        </>
      )}
    </>
  );
}

export default AppKey;
