import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Info as InfoIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import {
  CircularProgress,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  Select,
  MenuItem,
  TextField,
  Divider,
  LinearProgress,
  Typography,
} from "@mui/material";
import {
  addToContacts,
  getAllAccountAndSetFirst,
  addAccount,
  setSelectedAccount,
  getLeads,
  getLastActivity,
  updateAContact,
  createAContact,
  setSelectedNewAccount,
  getLeadDetails,
  setActiveLead,
} from "../../../redux/modules/leads";
import {
  checkPhoneNumber,
  validateEmail,
  validatePhoneNumber,
} from "../../../utils/validators";
import DealForm from "../../../components/DealForm/DealForm";
import {
  AddContactToDeal,
  setSelectedDeal,
} from "../../../redux/modules/deals";
import DialogTitle from "../../../components/DialogTitle";
import { getAContactForm } from "../../../redux/modules/forms";
import { isEqualArrays } from "../../../utils";

function EmailAdd({ emailFields, emails, leadDetails, setEmails, dealTag }) {
  const [addNew, setAddNew] = useState(false);
  const [value, setValue] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [editEmail, setEditEmail] = useState("");
  const onAddNewEmail = () => {
    setAddNew(true);
  };

  const onFieldBlur = () => {
    const newEmails = [...emails];
    if (!emailError) {
      if (value) {
        if (editEmail !== "") {
          newEmails[editEmail] = value;
        } else {
          newEmails.push(value);
        }

        setEmails(newEmails);
        setAddNew(false);
        setValue("");
        setEditEmail("");
      } else {
        if (editEmail !== "") {
          newEmails.splice(editEmail, 1);
        }

        setEmails(newEmails);
        setAddNew(false);
        setValue("");
        setEditEmail("");
      }
    }
  };

  const onEmailClick = (loc) => {
    setEditEmail(loc);
    setValue(emails[loc]);
    setAddNew(false);
  };

  const onEmailChange = (e) => {
    setValue(e.target.value);
    setEmailError(!!e.target.value && !validateEmail(e.target.value));
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      onFieldBlur();
    }
  };

  return (
    <div className="email_add_section">
      <Typography className="key_style" variant="h6">
        Emails
      </Typography>
      {emailFields
        ? emailFields.map((field, i) =>
            leadDetails[field.label] ? (
              <Typography key={i} className="email_strip default_one">
                {leadDetails[field.label]}
              </Typography>
            ) : null
          )
        : null}
      {emails.map((email, i) => (
        <div key={i}>
          {i !== editEmail ? (
            <Typography className="email_strip" onClick={() => onEmailClick(i)}>
              {email}
            </Typography>
          ) : (
            <TextField
              size="small"
              className={"contact_value " + (dealTag ? "deal_c_value" : "")}
              margin="dense"
              type="email"
              variant="outlined"
              value={value}
              onChange={onEmailChange}
              onKeyDown={handleKeyDown}
              onBlur={onFieldBlur}
              autoFocus
              error={emailError}
            />
          )}
        </div>
      ))}
      {addNew ? (
        <TextField
          size="small"
          className={"contact_value " + (dealTag ? "deal_c_value" : "")}
          margin="dense"
          type="email"
          variant="outlined"
          value={value}
          onChange={onEmailChange}
          onKeyDown={handleKeyDown}
          onBlur={onFieldBlur}
          autoFocus
          error={emailError}
        />
      ) : null}
      <div>
        <IconButton
          className="email_add_wrapper"
          onClick={onAddNewEmail}
          style={{ padding: 0 }}
          disabled={addNew || editEmail !== ""}
        >
          <AddCircleOutlineIcon />
          <Typography variant="caption" style={{ marginLeft: 5, fontWeight:500 }}>
            Add Email
          </Typography>
        </IconButton>
      </div>
    </div>
  );
}

function PhoneNumberAdd({ phones, setPhones, dealTag, leadPhones }) {
  const [addNew, setAddNew] = useState(false);
  const [value, setValue] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [editPhone, setEditPhone] = useState("");

  const onAddNewPhone = () => {
    setAddNew(true);
  };

  const onFieldBlur = () => {
    const newPhones = [...phones];
    if (!phoneError) {
      if (value) {
        if (editPhone !== "") {
          newPhones[editPhone] = value;
        } else {
          newPhones.push(value);
        }

        setPhones(newPhones);
        setAddNew(false);
        setValue("");
        setEditPhone("");
      } else {
        if (editPhone !== "") {
          newPhones.splice(editPhone, 1);
        }

        setPhones(newPhones);
        setAddNew(false);
        setValue("");
        setEditPhone("");
      }
    }
  };

  const onPhoneClick = (loc) => {
    setEditPhone(loc);
    setValue(phones[loc]);
    setAddNew(false);
  };

  const onPhoneChange = (e) => {
    setValue(e.target.value);
    setPhoneError(!!e.target.value && !validatePhoneNumber(e.target.value));
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      onFieldBlur();
    }
  };

  return (
    <div className="phone_section">
      <Typography className="key_style" variant="h6">
        Mobile
      </Typography>
      {leadPhones.map((lPhones, l) => (
        <div key={l}>
          <Typography className="phone_strip default_one">{lPhones}</Typography>
        </div>
      ))}
      {phones.map((phone, i) => (
        <div key={i}>
          {i !== editPhone ? (
            <Typography className="phone_strip" onClick={() => onPhoneClick(i)}>
              {phone}
            </Typography>
          ) : (
            <TextField
              size="small"
              className={"contact_value " + (dealTag ? "deal_c_value" : "")}
              margin="dense"
              type="email"
              variant="outlined"
              value={value}
              onChange={onPhoneChange}
              onKeyDown={handleKeyDown}
              onBlur={onFieldBlur}
              autoFocus
              error={phoneError}
            />
          )}
        </div>
      ))}
      {addNew ? (
        <TextField
          size="small"
          className={"contact_value " + (dealTag ? "deal_c_value" : "")}
          margin="dense"
          variant="outlined"
          value={value}
          onChange={onPhoneChange}
          onKeyDown={handleKeyDown}
          onBlur={onFieldBlur}
          autoFocus
          error={phoneError}
        />
      ) : null}
      <div>
        <IconButton
          className="phone_add_wrapper"
          onClick={onAddNewPhone}
          style={{ padding: 0 }}
        >
          <AddCircleOutlineIcon />
          <Typography variant="caption" style={{ marginLeft: 5, fontWeight:500 }}>
            Add Mobile
          </Typography>
        </IconButton>
      </div>
    </div>
  );
}

function FirstField({ field, leadDetails, setFieldValue, ttf }) {
  const fieldValue =
    leadDetails[field.label] || (ttf && leadDetails[ttf.label]) || "";
  const [value, setValue] = useState(fieldValue);
  const onValueChange = (e) => {
    setFieldValue(e.target.value);
    setValue(e.target.value);
  };

  return (
    <div className="lead_contact">
      <Typography className="contact_key" variant="h5">
        Name
      </Typography>
      <TextField
        size="small"
        className="contact_value"
        margin="dense"
        variant="outlined"
        value={value}
        onChange={onValueChange}
      />
    </div>
  );
}

const DialogComponent = ({ children, tag, maxWidth, ...props }) =>
  tag ? (
    <div {...props}>{children}</div>
  ) : (
    <Dialog maxWidth={maxWidth} {...props}>
      {children}
    </Dialog>
  );

function AddToContact({
  leadDetails,
  contact,
  edit,
  create,
  dealTag,
  cancel,
  complete,
  setLeadsLoading,
  lead,
}) {
  const dispatch = useDispatch();
  const accounts = useSelector((state) => state.leads.accounts);
  const dealsList = useSelector((state) => state.deals.dealsList);
  const selectedAccount = useSelector((state) => state.leads.selectedAccount);
  const selectedNewAccount = useSelector(
    (state) => state.leads.selectedNewAccount
  );
  const selectedFormId = useSelector((state) => state.leads.selectedFormId);
  const selectedDeal = useSelector((state) => state.deals.selectedDeal);
  const finalDealList = selectedAccount
    ? dealsList.filter((dl) => selectedAccount == dl.contact_account)
    : dealsList;
  const fields = useSelector((state) => state.forms.contactFields);
  const [isLoading, setIsLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [savingAccount, setSavingAccount] = useState(false);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const [newAccount, setNew] = useState(false);
  const [emails, setEmails] = useState([]);
  const [phones, setPhones] = useState([]);
  const [phonesFromLead, setPhoneFL] = useState([]);
  const isContactAdded = !!Number(contact || "0");

  useEffect(() => {
    if (open && fields.length) {
      const phoneNumbers = fields
        .map((field) =>
          (field.type === "number" || field.type === "text") &&
          checkPhoneNumber(leadDetails[field.label])
            ? leadDetails[field.label]
            : ""
        )
        .filter((f) => f);
      setPhoneFL(phoneNumbers);
    }
  }, [open, fields]);

  useEffect(() => {
    if (open && leadDetails.form_id && !edit) {
      setIsLoading(true);
      dispatch(getAContactForm(leadDetails.form_id))
        .then(() => {
          if (leadDetails.lead_id) {
            dispatch(getLeadDetails(leadDetails.lead_id))
              .then(() => {
                setIsLoading(false);
              })
              .catch(() => {
                setIsLoading(false);
              });
          } else {
            setIsLoading(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }

    if (open && edit) {
      setValue(leadDetails.contact_name || "");
      setEmails(leadDetails.emails || []);
      setPhones(leadDetails.mobiles || []);

      if (leadDetails.form_id) {
        setIsLoading(true);
        dispatch(getAContactForm(leadDetails.form_id))
          .then(() => {
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      }

      if (!create) {
        dispatch(setSelectedNewAccount(leadDetails.account_id));
      }
    }
  }, [open]);

  const onAddClick = () => {
    setOpen(true);
    if (lead) {
      dispatch(setActiveLead(leadDetails.lead_id));
    }

    if (create || !edit) {
      if (!accounts.length) {
        dispatch(getAllAccountAndSetFirst());
      } else {
        // dispatch(setSelectedAccount(accounts[0]["account_id"]));
      }
    }
  };

  const onAccountCancel = () => {
    setNew(false);
    setName("");
  };

  const onClose = () => {
    setOpen(false);
    if (lead) {
      dispatch(setActiveLead(""));
    }

    onAccountCancel();
    setPhones([]);
    setEmails([]);
    dispatch(setSelectedAccount(""));
  };

  const onAccountAdd = () => {
    setSavingAccount(true);
    dispatch(addAccount(name))
      .then((accountList) => {
        dispatch(
          setSelectedAccount(accountList[accountList.length - 1]["account_id"])
        );
        setSavingAccount(false);
        onAccountCancel();
      })
      .catch(() => {
        setSavingAccount(false);
      });
  };

  const onSaveContact = () => {
    setUpdating(true);
    const nameValue = value || leadDetails[fields.slice(0, 1)[0]["label"]];
    dispatch(
      addToContacts(
        leadDetails.lead_id,
        selectedAccount,
        emails,
        phones,
        nameValue,
        selectedDeal,
        leadDetails.name
      )
    )
      .then(() => {
        setLeadsLoading(true);
        dispatch(getLeads(selectedFormId))
          .then(({ result }) => {
            setLeadsLoading(false);
            dispatch(getLastActivity(result.map((r) => r.lead_id)));
          })
          .catch(() => {
            setLeadsLoading(false);
          });
        setUpdating(false);
        setOpen(false);
      })
      .catch(() => {
        setUpdating(false);
      });
  };

  const onContactUpdate = () => {
    setUpdating(true);
    dispatch(
      updateAContact(
        leadDetails.contact_id,
        leadDetails.contact_name != value && value,
        !isEqualArrays(leadDetails.emails, emails) && emails,
        !isEqualArrays(leadDetails.mobiles, phones) && phones,
        leadDetails.account_id != selectedNewAccount && selectedNewAccount
      )
    )
      .then(() => {
        setUpdating(false);
      })
      .catch(() => {
        setUpdating(false);
      });
  };

  const onContactCreate = () => {
    setUpdating(true);
    dispatch(createAContact(selectedAccount, emails, phones, value))
      .then(() => {
        setUpdating(false);
        onClose();
      })
      .catch(() => {
        setUpdating(false);
      });
  };

  const onContactAddToDeal = () => {
    setUpdating(true);
    dispatch(
      AddContactToDeal(
        dealTag.contact_account,
        emails,
        phones,
        value,
        dealTag.deal_id
      )
    )
      .then(() => {
        setUpdating(false);
        onClose();
        complete();
      })
      .catch(() => {
        setUpdating(false);
      });
  };

  const onAccountValueChange = (e) => {
    dispatch(setSelectedDeal(""));
    edit && !create
      ? dispatch(
          setSelectedNewAccount(
            e.target.value == "select" ? "" : e.target.value
          )
        )
      : dispatch(
          setSelectedAccount(e.target.value == "select" ? "" : e.target.value)
        );
  };

  const addContactEnable =
    value && (emails.length || phones.length) && !isLoading;
  const createEnable = selectedAccount && addContactEnable;
  const disableUpdate =
    !selectedNewAccount ||
    isLoading ||
    (leadDetails.contact_name == value &&
      leadDetails.account_id == selectedNewAccount &&
      isEqualArrays(leadDetails.emails, emails) &&
      isEqualArrays(leadDetails.mobiles, phones));

  const contactStatus = isContactAdded ? (
    <Button
      className="contact-add-edit-button"
      onClick={onAddClick}
      color="primary"
    >
      <AddCircleOutlineIcon />
    </Button>
  ) : create && !dealTag ? (
    <Button
      variant="contained"
      className="btn-style"
      color="primary"
      disableElevation
      onClick={onAddClick}
    >
      Create Contact
    </Button>
  ) : (
    <Button
      className="contact-add-edit-button"
      onClick={onAddClick}
      color="primary"
    >
      {edit ? <EditIcon /> : <AddCircleOutlineIcon />}
    </Button>
  );

  const nameField = { label: "name" };
  const textTypeField = fields.find((field) => field.type === "text");

  return (
    <>
      {isLoading && !dealTag ? (
        <CircularProgress style={{ width: 24, height: 24 }} />
      ) : dealTag ? null : (
        contactStatus
      )}
      <DialogComponent
        open={open}
        onClose={onClose}
        maxWidth={isLoading ? "xs" : false}
        scroll="body"
        tag={dealTag}
        fullWidth={isLoading}
      >
        {isLoading ? (
          <DialogContent style={{ margin: 3 }}>
            <LinearProgress />
          </DialogContent>
        ) : (
          <>
            <div className="add_to_contact_sections">
              <div className={dealTag ? "contact-details-sec" : ""}>
                <DialogTitle
                  onClose={!edit && !create && !lead ? onClose : null}
                >
                  {edit
                    ? create
                      ? dealTag
                        ? "Create/Tag new contact"
                        : "Create new contact"
                      : "Edit Contact"
                    : "Add Contact"}
                </DialogTitle>
                <Divider />
                <DialogContent className="dialog-section">
                  {create ? null : leadDetails.lead_id ? (
                    <div className="lead_for_contact">
                      <Typography className="lead-head fnt16" variant="h6">
                        Lead ID
                      </Typography>
                      <Typography className="lead-data">
                        {leadDetails.lead_id}
                      </Typography>
                    </div>
                  ) : null}

                  <div className="lead-contact_table">
                    {edit || !nameField || !textTypeField ? (
                      <div className="lead_contact">
                        <Typography className="contact_key" variant="h6">
                          Contact Name
                        </Typography>
                        <TextField
                          size="small"
                          className={
                            "contact_value " + (dealTag ? "deal_c_value" : "")
                          }
                          margin="dense"
                          variant="outlined"
                          value={value}
                          onChange={(e) => setValue(e.target.value)}
                        />
                      </div>
                    ) : (
                      <FirstField
                        field={nameField}
                        ttf={textTypeField}
                        leadDetails={leadDetails}
                        setFieldValue={setValue}
                      />
                    )}
                  </div>
                  <EmailAdd
                    emailFields={
                      create
                        ? null
                        : fields.filter((field) => field.type === "email")
                    }
                    emails={emails}
                    leadDetails={leadDetails}
                    setEmails={setEmails}
                    dealTag={dealTag}
                  />
                  <PhoneNumberAdd
                    phones={phones}
                    leadPhones={phonesFromLead}
                    setPhones={setPhones}
                    dealTag={dealTag}
                  />
                </DialogContent>
              </div>
              {dealTag ? null : (
                <>
                  <Divider orientation="vertical" flexItem />
                  <div style={{ minWidth: 250 }}>
                    <DialogTitle onClose={edit || create ? onClose : null}>
                      To Account
                    </DialogTitle>
                    <Divider />
                    <DialogContent className="account_list_and_add dialog-section">
                      {newAccount ? (
                        <>
                          <TextField
                            size="small"
                            variant="outlined"
                            style={{ minWidth: 200, margin: 0 }}
                            margin="dense"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            autoFocus
                          />
                          {savingAccount ? (
                            <LinearProgress style={{ margin: 3 }} />
                          ) : null}
                          <div className="cancelAddWrapper">
                            <Button
                              color="primary"
                              onClick={onAccountCancel}
                              disabled={savingAccount}
                            >
                              Cancel
                            </Button>
                            <Divider orientation="vertical" flexItem />
                            <Button
                              color="primary"
                              onClick={onAccountAdd}
                              disabled={savingAccount || !name}
                            >
                              Add
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          {accounts.length ? (
                            <Select
                              variant="outlined"
                              margin="dense"
                              value={
                                Number(edit && !create
                                  ? selectedNewAccount
                                  : selectedAccount) || "select"
                              }
                              style={{ minWidth: 200, maxHeight: 40 }}
                              onChange={onAccountValueChange}
                            >
                              <MenuItem key={765654} value="select">
                                ----- Select an Account -----
                              </MenuItem>
                              {accounts.map((item, i) => (
                                <MenuItem key={i} value={item.account_id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          ) : null}
                          <Button
                            className="addNewAccount"
                            color="primary"
                            onClick={() => setNew(true)}
                          >
                            Add new
                          </Button>
                        </>
                      )}
                    </DialogContent>
                  </div>
                  {edit || create ? null : (
                    <>
                      <Divider orientation="vertical" flexItem />
                      <div>
                        <DialogTitle onClose={lead ? onClose : null}>
                          Add Deal
                        </DialogTitle>
                        <Divider />
                        <DialogContent className="dialog-section">
                          <DealForm accountId={selectedAccount} />
                          {finalDealList.length ? null : (
                            <Typography
                              style={{ width: 250 }}
                              className="empty-message"
                              component="div"
                            >
                              Please create a new deal. Since there is no deal
                              available with the selected account.
                            </Typography>
                          )}
                        </DialogContent>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            <Divider />
            {updating ? <LinearProgress /> : null}
            <DialogContent className="atc_button_bar">
              {dealTag ? (
                <Button
                  onClick={() => {
                    cancel();
                  }}
                >
                  Cancel
                </Button>
              ) : null}
              {edit ? (
                create ? (
                  dealTag ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onContactAddToDeal}
                      disabled={!addContactEnable}
                    >
                      Add Contact
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onContactCreate}
                      disabled={!createEnable}
                    >
                      Create Contact
                    </Button>
                  )
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onContactUpdate}
                    disabled={disableUpdate}
                  >
                    Update Contact
                  </Button>
                )
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onSaveContact}
                    disabled={
                      isLoading || !selectedAccount || (!nameField && !value)
                    }
                  >
                    Save Contact
                  </Button>
                  <Typography className="lead-contact-warning" component="div">
                    <InfoIcon style={{ marginRight: 5 }} color="action" /> This
                    lead will turn into a contact and it won't appear in the
                    Leads module anymore.
                  </Typography>
                </>
              )}
            </DialogContent>
          </>
        )}
      </DialogComponent>
    </>
  );
}

export default AddToContact;
