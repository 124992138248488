import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  LinearProgress,
  List,
  ListItem,
  Avatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  Divider,
  Collapse,
} from "@mui/material";
import DialogTitle from "../../../components/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { AssignmentInd as AssignmentIndIcon } from "@mui/icons-material";
import { getStaffsList } from "../../../redux/modules/staffs";
import {
  assignAStaff,
  assignANewStaff,
  removeAssign,
  setActiveLead,
} from "../../../redux/modules/leads";

export default function Assign({ leadId, setAnchorEl, loading, lead }) {
  const dispatch = useDispatch();
  const [openAssign, setAssign] = useState(false);
  const staffsList = useSelector((state) => state.staffs.staffsList);
  const [loadingStaffs, setLoading] = useState(false);
  const [confirmation, setConfirm] = useState(false);
  const [selectedStaff, setSS] = useState(null);
  const [confirming, setConfirming] = useState(false);
  const [removing, setRemoving] = useState(false);

  useEffect(() => {
    if (!staffsList.length && openAssign) {
      setLoading(true);
      dispatch(getStaffsList()).then(() => {
        setLoading(false);
      });
    }
  }, [openAssign]);

  const onAssignClick = () => {
    if (leadId) {
      dispatch(setActiveLead(leadId));
    }

    setAssign(true);
    setAnchorEl(null);
  };

  const onAssignClose = () => {
    if (leadId) {
      dispatch(setActiveLead(""));
    }

    setAssign(false);
    setConfirm(false);
  };

  const onStaffClick = (staff) => () => {
    if (staff.staff_id != lead.staff_id) {
      setConfirm(true);
      setSS(staff);
    }
  };

  const cancelAssign = () => {
    setConfirm(false);
    setSS(null);
  };

  const confirmAssign = () => {
    setConfirming(true);
    let apiCall = Promise.resolve();
    if (lead.staff_id) {
      apiCall = dispatch(
        assignANewStaff(
          leadId,
          lead.name,
          selectedStaff.staff_id,
          selectedStaff.name
        )
      );
    } else {
      apiCall = dispatch(
        assignAStaff(
          leadId,
          lead.name,
          selectedStaff.staff_id,
          selectedStaff.name
        )
      );
    }

    apiCall
      .then(() => {
        setAssign(false);
        setConfirming(false);
        setConfirm(false);
      })
      .catch(() => {
        setAssign(false);
        setConfirming(false);
        setConfirm(false);
      });
  };

  const onAssignRemove = () => {
    setRemoving(true);
    dispatch(removeAssign(leadId))
      .then(() => {
        setRemoving(false);
        setConfirm(false);
      })
      .catch(() => {
        setRemoving(false);
        setConfirm(false);
      });
  };

  const activeStaffs = staffsList.filter((ss) => ss.active == 1);

  return (
    <>
      <MenuItem onClick={onAssignClick} disabled={loading}>
        <ListItemIcon>
          <AssignmentIndIcon fontSize="small" />
          <Typography variant="inherit" style={{ marginLeft: 5 }}>
            Assign a Staff
          </Typography>
        </ListItemIcon>
      </MenuItem>
      <Dialog open={openAssign} onClose={onAssignClose} maxWidth="xs">
        <DialogTitle onClose={onAssignClose}>
          Assign a staff to the lead #{leadId}
        </DialogTitle>
        <Divider />
        {confirming || removing ? <LinearProgress /> : null}
        {lead.staff_id ? (
          <>
            <DialogContent>
              <div className="lead_assigned_msg">
                <Typography>
                  Assigned to: <b>{lead.staff_name}</b>
                </Typography>
                <Button onClick={onAssignRemove}>Remove</Button>
              </div>
            </DialogContent>
            <Divider />
          </>
        ) : null}
        <DialogContent>
          <Collapse in={!confirmation}>
            <List style={{ maxHeight: 400 }}>
              {!loadingStaffs && !activeStaffs.length ? (
                <Typography
                  className="empty-message"
                  component="div"
                  variant="caption"
                >
                  No active staffs available...
                </Typography>
              ) : loadingStaffs ? (
                <LinearProgress />
              ) : null}
              {activeStaffs
                .filter((ss) => ss.active == 1)
                .map((staff, s) => (
                  <ListItem key={s} button onClick={onStaffClick(staff)}>
                    <Avatar
                      className={
                        "staff-avatar" +
                        (staff.staff_id == lead.staff_id ? " active" : "")
                      }
                    >
                      {staff.name.slice(0, 1)}
                    </Avatar>
                    <ListItemText>{staff.name}</ListItemText>
                  </ListItem>
                ))}
            </List>
          </Collapse>
          <Collapse in={confirmation}>
            <Typography className="warning-container">
              Do you want to assign <b>{selectedStaff && selectedStaff.name}</b>{" "}
              to manage this lead (#{leadId})?
            </Typography>
            <div>
              <Button onClick={cancelAssign}>Cancel</Button>
              <Button onClick={confirmAssign}>Yes</Button>
            </div>
          </Collapse>
        </DialogContent>
      </Dialog>
    </>
  );
}
