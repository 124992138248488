import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";

const EmailInput = (props) => {
  const { formChange, validateEmail, fieldName, label, valueField } = props;
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (inputValue && inputValue.endsWith(",")) {
      if (validateEmail(inputValue.slice(0, -1))) {
        formChange(null, valueField.concat(inputValue.slice(0, -1)), fieldName);
        setInputValue("");
      }
    }
  },[inputValue]);

  return (
    <>
      <Autocomplete
        size="small"
        multiple
        freeSolo
        options={[]}
        onChange={(e, n) => formChange(e, n, fieldName)}
        value={valueField || []}
        onInputChange={(event, newInputValue) => {
          if (event && event.target.setCustomValidity) {
            event.target.setCustomValidity("");
            if (newInputValue.trim() && !validateEmail(newInputValue)) {
              event.target.setCustomValidity("Enter a valid email Id.");
            }
          }
        }}
        onBlur={(event) => {
          if (event.target.value && event.target.value.endsWith(",")) {
            if (validateEmail(event.target.value.slice(0, -1))) {
              formChange(
                event,
                valueField.concat(event.target.value.slice(0, -1)),
                fieldName
              );
              setInputValue("");
            }
          } else {
            if (validateEmail(event.target.value)) {
              formChange(
                event,
                valueField.concat(event.target.value),
                fieldName
              );
              setInputValue("");
            }
          }
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              required={!valueField?.length}
              id={fieldName}
              name={fieldName}
              label={label}
              inputProps={{ ...params.inputProps, value: inputValue }}
              fullWidth
              autoComplete="email"
              helperText="Enter email addresses in the format: example@example.com"
              variant="standard"
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                  if (e.target.value && validateEmail(e.target.value)) {
                    formChange(e, valueField.concat(e.target.value), fieldName);
                    setInputValue("");
                  }
                } else if (
                  e.key === "Backspace" &&
                  inputValue === "" &&
                  valueField.length
                ) {
                  formChange(e, valueField.slice(0, -1), fieldName);
                }
              }}
            />
          );
        }}
      />
    </>
  );
};

export default EmailInput;
