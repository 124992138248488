import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import {
  Divider,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
  Paper,
  Grid,
  Box,
  Card,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getStaffLoginCountFor,
  getStaffStats,
  getStats,
} from "../../redux/modules/dashboard";
import { Link } from "react-router-dom";
import { getDateTimeText } from "../../utils/format_date";
import { Helmet } from "react-helmet";

export default function Dashboard() {
  const dispatch = useDispatch();
  const stats = useSelector((state) => state.dashboard.stats) || {};
  const staffname = useSelector((state) => state.auth.staffname);
  const staffLogins = useSelector((state) => state.dashboard.staffLogins);
  const staffLoginCounts = useSelector(
    (state) => state.dashboard.staffLoginCounts
  );
  const [loading, setLoading] = useState(false);
  const [days, setDays] = useState(7);
  const isClient = !staffname;

  useEffect(() => {
    setLoading(true);
    if (isClient) {
      dispatch(getStaffStats(days));
      dispatch(getStaffLoginCountFor(days));
    }
    dispatch(getStats(days)).then(() => {
      setLoading(false);
    });
  }, [days]);

  const onDaysChange = (e) => {
    setDays(e.target.value);
  };

  const renderTooltip = ({ payload, label }) => {
    return (
      <div className="chart-tooltip">
        <Typography variant="h5">{label}</Typography>
        <Typography>{`Total Logins: ${
          payload && payload[0] ? payload[0].value : ""
        }`}</Typography>
        <Typography>{`Last Login: ${
          payload && payload[0]
            ? getDateTimeText(payload[0]["payload"].lastLogin)
            : ""
        }`}</Typography>
      </div>
    );
  };

  const renderLoginTT = ({ payload, label }) => {
    return (
      <div className="chart-tooltip">
        <Typography variant="h5">{label}</Typography>
        <Typography>{`Total staffs logged in: ${
          payload && payload[0] ? payload[0].value : ""
        }`}</Typography>
      </div>
    );
  };

  return (
    <div>
      <Helmet>
        <title>Dashboard</title>
        <meta name="description" content="Dashboard page" />
      </Helmet>
      <div className="page_t_head">
        <div className="page_t_wrapper">
          <Typography className="page_title">Dashboard</Typography>
        </div>
      </div>
      <Grid container spacing={1}>
        <Grid container className="grid_pad_xs" xs={12} spacing={4}>
          <Grid className="grid_pad_x" xs={12}>
            <div className="creative-container">
              <Box component="img" style={{ height: 96 }} src="/dash.png" />
              <Box>
                <div>Welcome to your Forms Leads Dashboard</div>
                <div className="txt-cntnr">
                  This is where you will find all the information with regards
                  to your leads.
                </div>
              </Box>
            </div>
          </Grid>
          {loading ? <LinearProgress /> : null}
          <Grid className="grid_pad_x" xs={3}>
            <Paper elevation={0} className="card-container-common">
              <Link className="card-container" to="/forms">
                <Box
                  component="img"
                  style={{ height: 60 }}
                  src="/forms-icon.png"
                />
                <Box>
                  <div className="card-count">
                    {stats.forms && stats.forms.count}
                  </div>
                  <div>Total Forms</div>
                </Box>
              </Link>
            </Paper>
          </Grid>
          <Grid className="grid_pad_x" xs={3}>
            <Paper elevation={0} className="card-container-common">
              <Link className="card-container" to="/leads">
                <Box
                  component="img"
                  style={{ height: 60 }}
                  src="/leads-icon.png"
                />
                <Box>
                  <div className="card-count">
                    {stats.leads && stats.leads.count}
                  </div>
                  <div>Total Leads</div>
                </Box>
              </Link>
            </Paper>
          </Grid>

          <Grid className="grid_pad_x" xs={3}>
            <Paper elevation={0} className="card-container-common">
              <Link className="card-container" to="/contacts">
                <Box
                  component="img"
                  style={{ height: 60 }}
                  src="/users-icon.png"
                />
                <Box>
                  <div className="card-count">
                    {stats.contacts && stats.contacts.count}
                  </div>
                  <div>Total Contacts</div>
                </Box>
              </Link>
            </Paper>
          </Grid>

          <Grid className="grid_pad_x" xs={3}>
            <Paper elevation={0} className="card-container-common">
              <Link className="card-container" to="/deals">
                <Box
                  component="img"
                  style={{ height: 60 }}
                  src="/flag-icon.png"
                />
                <Box>
                  <div className="card-count">
                    {stats.deals && stats.deals.count}
                  </div>
                  <div>Total Deals</div>
                </Box>
              </Link>
            </Paper>
          </Grid>

          <Grid xs={12} className="grid_pad_x">
            <Divider />
            <div className="chart-fltr-tls">
              <Select
                variant="outlined"
                value={days}
                onChange={onDaysChange}
                size="small"
              >
                <MenuItem value="7">Last 7 days</MenuItem>
                <MenuItem value="30">Last 30 days</MenuItem>
                <MenuItem value="60">Last 60 days</MenuItem>
                <MenuItem value="90">Last 90 days</MenuItem>
              </Select>
            </div>
            <Divider />
          </Grid>
          <Grid className="grid_pad_x" xs={4}>
            <Paper
              elevation={0}
              className="card-container-common"
              width="100%"
              height="100%"
            >
              <div className="chrt-txy-wrpr">
                <Typography variant="h6" className="chartTxt">
                  Leads & Forms
                </Typography>
              </div>
              <ResponsiveContainer width="100%" height={200}>
                <AreaChart
                  data={stats.forms && stats.forms.stat}
                  margin={{ top: 30, right: 30, left: 0, bottom: 0 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis
                    dataKey="leads"
                    domain={[
                      0,
                      Math.max.apply(
                        Math,
                        stats.forms &&
                          stats.forms.stat &&
                          stats.forms.stat.map(function (o) {
                            return o.leads;
                          })
                      ),
                    ]}
                  />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="leads"
                    stroke="#7D7D7D"
                    fill="#5997ED"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>

          <Grid className="grid_pad_x" xs={4}>
            <Paper
              elevation={0}
              className="card-container-common"
              width="100%"
              height="100%"
            >
              <div className="chrt-txy-wrpr">
                <Typography variant="h6" className="chartTxt">
                  Leads
                </Typography>
              </div>
              <ResponsiveContainer width="100%" height={200}>
                <AreaChart
                  data={stats.leads && stats.leads.stat}
                  margin={{ top: 30, right: 30, left: 0, bottom: 0 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="day" />
                  <YAxis
                    dataKey="leads"
                    domain={[
                      0,
                      Math.max.apply(
                        Math,
                        stats.leads &&
                          stats.leads.stat.map(function (o) {
                            return o.leads;
                          })
                      ),
                    ]}
                  />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="leads"
                    stroke="#7D7D7D"
                    fill="#5997ED"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>

          <Grid className="grid_pad_x" xs={4}>
            <Paper
              elevation={0}
              className="card-container-common"
              width="100%"
              height="100%"
            >
              <div className="chrt-txy-wrpr">
                <Typography variant="h6" className="chartTxt">
                  Deals
                </Typography>
              </div>
              <ResponsiveContainer width="100%" height={200}>
                <AreaChart
                  data={stats.deals && stats.deals.stat}
                  margin={{ top: 30, right: 30, left: 0, bottom: 0 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="day" />
                  <YAxis
                    dataKey="deals"
                    domain={[
                      0,
                      Math.max.apply(
                        Math,
                        stats.deals &&
                          stats.deals.stat.map(function (o) {
                            return o.deals;
                          })
                      ),
                    ]}
                  />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="deals"
                    stroke="#7D7D7D"
                    fill="#5997ED"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
          {isClient ? (
            <>
              <Grid xs={6} className="grid_pad_x">
                <Paper
                  elevation={0}
                  className="card-container-common"
                  width="100%"
                  height="100%"
                >
                  <div className="chrt-txy-wrpr">
                    <Typography variant="h6" className="chartTxt">
                      Staffs & logins
                    </Typography>
                  </div>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      margin={{ top: 30, right: 30, left: 0, bottom: 0 }}
                      data={staffLogins}
                    >
                      <XAxis dataKey="name" />
                      <YAxis
                        domain={[
                          0,
                          Math.max.apply(
                            Math,
                            staffLogins.map(function (o) {
                              return o.totalLogins;
                            })
                          ),
                        ]}
                      />
                      <Tooltip content={renderTooltip} />
                      <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                      <Bar dataKey="totalLogins" fill="#90befd" />
                    </BarChart>
                  </ResponsiveContainer>
                </Paper>
              </Grid>
              <Grid xs={6} className="grid_pad_x">
                <Paper
                  elevation={0}
                  className="card-container-common"
                  width="100%"
                  height="100%"
                >
                  <div className="chrt-txy-wrpr">
                    <Typography variant="h6" className="chartTxt">
                      Staff logins a day
                    </Typography>
                  </div>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      margin={{ top: 30, right: 30, left: 0, bottom: 0 }}
                      data={staffLoginCounts}
                    >
                      <XAxis dataKey="day" />
                      <YAxis
                        domain={[
                          0,
                          Math.max.apply(
                            Math,
                            staffLoginCounts.map(function (o) {
                              return o.logins;
                            })
                          ),
                        ]}
                      />
                      <Tooltip content={renderLoginTT} />
                      <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                      <Bar dataKey="logins" fill="#90befd" />
                    </BarChart>
                  </ResponsiveContainer>
                </Paper>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}
