import React, { useState } from "react";
import { Typography, Tabs, Tab, Box } from "@mui/material";
import EmailTemplates from "./components/EmailTemplates";
import BodyFormats from "./components/BodyFormats";
import AccessControl from "../../components/AccessControl";
import { Helmet } from "react-helmet";
import { Scheduled } from './components/Scheduled';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}


export default function Email() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    return (
        <div className='email_container' >
          <Helmet>
            <title>Email</title>
            <meta name="description" content="Email page" />
          </Helmet>
            <AccessControl name="view_email_template_settings" message >
            <div className="page_t_head">
              <div className="page_d_wrapper" >
                  <Typography className="page_title" >Email</Typography>
              </div>
            </div>
              <Tabs className="email-tabs" value={value} onChange={handleChange} >
                  <Tab className="email-tab" label="Scheduled Emails" />
                  <Tab className="email-tab" label="Formats" />
                  <Tab className="email-tab" label="Templates" />
              </Tabs>
              <TabPanel value={value} index={0}>
                  <Scheduled />
              </TabPanel>
              <TabPanel value={value} index={1}>
                  <BodyFormats />
              </TabPanel>
              <TabPanel value={value} index={2}>
                  <EmailTemplates />
              </TabPanel>
            </AccessControl>
    </div>
  );
}
