import React, { useEffect, useState } from "react";
import { Popover, TextField, Button, Badge } from "@mui/material";
import { Event as EventIcon } from "@mui/icons-material";
import { getCurrentDate, getTimePeriodsFor } from "../../../utils/format_date";
import { useDispatch, useSelector } from "react-redux";
import {
  resetLeadsPage,
  setDateFilter,
  setSelectedDates,
} from "../../../redux/modules/leads";
import config from "../../../config";

export default function DateFilter() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [btnText, setBtnTxt] = useState("Date");
  const [from, setFrom] = useState(getCurrentDate());
  const [to, setTo] = useState(getCurrentDate());
  const dispatch = useDispatch();
  const selectedDates = useSelector((state) => state.leads.selectedDates);
  const dateFilter = useSelector((state) => state.leads.dateFilter);

  useEffect(() => {
    if (anchorEl) {
      setFrom(selectedDates.from || getCurrentDate());
      setTo(selectedDates.to || getCurrentDate());
    }
  }, [anchorEl]);

  useEffect(() => {
    if (!((selectedDates.from && selectedDates.to) || !!dateFilter)) {
      setBtnTxt("Date");
    }

    setFrom(selectedDates.from.slice(0, 10) || getCurrentDate());
    setTo(selectedDates.to.slice(0, 10) || getCurrentDate());
  }, [selectedDates]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onFromChange = (e) => {
    setFrom(e.target.value);
  };

  const onToChange = (e) => {
    setTo(e.target.value);
  };

  const onApplyClick = () => {
    dispatch(setSelectedDates(from, to));
    dispatch(setDateFilter(0));
    setBtnTxt("Custom");
    setAnchorEl(null);
    dispatch(resetLeadsPage());
  };

  const onResetClick = () => {
    if (selectedDates.from && selectedDates.to) {
      dispatch(setSelectedDates("", ""));
      setBtnTxt("Date");
    }

    setFrom(getCurrentDate());
    setTo(getCurrentDate());
    dispatch(setDateFilter(0));
    dispatch(resetLeadsPage());
  };

  const onFilterSet = (index) => () => {
    const dateRange = getTimePeriodsFor(config.dateFilters[index]["code"]);
    dispatch(setSelectedDates(dateRange.from, dateRange.to));

    setBtnTxt(config.dateFilters[index]["text"]);
    dispatch(setDateFilter(config.dateFilters[index]["code"]));
    dispatch(resetLeadsPage());
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const onlyCFilter = selectedDates.from && selectedDates.to && !dateFilter;

  return (
    <div className="lead-active-status">
      <Badge
        color="primary"
        badgeContent=" "
        variant="dot"
        invisible={!(selectedDates.from && selectedDates.to)}
      >
        <Button
          aria-describedby={id}
          variant="outlined"
          className="dfilter_btn_common"
          style={{ minHeight: 40, fontWeight: 400, fontSize: 15 }}
          startIcon={<EventIcon color="action" />}
          onClick={handleClick}
        >
          {btnText}
        </Button>
      </Badge>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ padding: 15, display: "flex", flexDirection: "column" }}>
          <div className={`custom_date_filter ${onlyCFilter ? "filter-applied" : ""}`} >
            <div style={{ marginBottom: 15 }}>
              <TextField
                size="small"
                variant="standard"
                id="from_date"
                label="From"
                type="date"
                onChange={onFromChange}
                value={from}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                variant="standard"
                size="small"
                id="to_date"
                className="date_select"
                label="To"
                type="date"
                onChange={onToChange}
                value={to}
                pattern="\d{4}/\d{1,2}/\d{1,2}"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <Button className='dfilter_btn_common' variant="outlined" onClick={onResetClick}>
              Reset
            </Button>
            <Button
              style={{ marginLeft: 15 }}
              className="dfilter_btn_common"
              variant="outlined"
              onClick={onApplyClick}
            >
              Apply
            </Button>
          </div>
          {config.dateFilters.map((df, i) => (
            <Button
              key={i}
              className={dateFilter === df.code ? "active_dfilter_btns" : 'dfilter_btns'}
              variant="outlined"
              onClick={onFilterSet(i)}
            >
              {df.name}
            </Button>
          ))}
        </div>
      </Popover>
    </div>
  );
}
