import axios from "axios";
import { getClientNameFromURL } from "../utils";

const methods = ["get", "post", "delete", "put"];

export default class ApiHandler {
  constructor() {
    methods.forEach((reqMethod) => {
      this[reqMethod] = (
        endpoint,
        { params = {}, data = {}, headers = {}, token } = {}
      ) =>
        new Promise((resolve, reject) => {
          const subDomain = getClientNameFromURL();

          const config = {
            url: endpoint,
            baseURL: process.env.REACT_APP_API_URL
              ? process.env.REACT_APP_API_URL
              : `https://${window.location.hostname}/portal`,
            method: reqMethod,
            data,
            params,
            headers: {
              Authorization: `Bearer ${
                token
                  ? token
                  : subDomain
                  ? localStorage.getItem("a_token")
                  : localStorage.getItem("token")
              }`,
              ...headers,
            },
          };

          axios(config)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
    });
  }
}
