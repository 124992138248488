import { setAlert } from './alert';
import config from '../../config';

const GET_DEAL_PIPELINES = 'GET_DEAL_PIPELINES';
const GET_DEAL_PIPELINES_SUCCESS = 'GET_DEAL_PIPELINES_SUCCESS';
const GET_DEAL_PIPELINES_FAILED = 'GET_DEAL_PIPELINES_FAILED';

const GET_DEAL_STAGES = 'GET_DEAL_STAGES';
const GET_DEAL_STAGES_SUCCESS = 'GET_DEAL_STAGES_SUCCESS';
const GET_DEAL_STAGES_FAILED = 'GET_DEAL_STAGES_FAILED';

const SAVE_PIPELINE = 'SAVE_PIPELINE';
const SAVE_PIPELINE_SUCCESS = 'SAVE_PIPELINE_SUCCESS';
const SAVE_PIPELINE_FAILED = 'SAVE_PIPELINE_FAILED';

const GET_DEALS = 'GET_DEALS';
const GET_DEALS_SUCCESS = 'GET_DEALS_SUCCESS';
const GET_DEALS_FAILED = 'GET_DEALS_FAILED';

const GET_DEALS_LIST = 'GET_DEALS_LIST';
const GET_DEALS_LIST_SUCCESS = 'GET_DEALS_LIST_SUCCESS';
const GET_DEALS_LIST_FAILED = 'GET_DEALS_LIST_FAILED';

const SET_SELECTED_DEAL = 'SET_SELECTED_DEAL';

const SET_SELECTED_PIPELINE = 'SET_SELECTED_PIPELINE';

const SET_SELECTED_DEAL_STAGE = 'SET_SELECTED_DEAL_STAGE';

const SET_SELECTED_CURRENCY = 'SET_SELECTED_CURRENCY';

const GET_CURRENCIES = 'GET_CURRENCIES';
const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS';
const GET_CURRENCIES_FAILED = 'GET_CURRENCIES_FAILED';

const ADD_DEAL_STAGE = 'ADD_DEAL_STAGE';
const ADD_DEAL_STAGE_SUCCESS = 'ADD_DEAL_STAGE_SUCCESS';
const ADD_DEAL_STAGE_FAILED = 'ADD_DEAL_STAGE_FAILED';

const UPDATE_DEAL_STAGE = 'UPDATE_DEAL_STAGE';
const UPDATE_DEAL_STAGE_SUCCESS = 'UPDATE_DEAL_STAGE_SUCCESS';
const UPDATE_DEAL_STAGE_FAILED = 'UPDATE_DEAL_STAGE_FAILED';

const DELETE_DEAL_STAGE = 'DELETE_DEAL_STAGE';
const DELETE_DEAL_STAGE_SUCCESS = 'DELETE_DEAL_STAGE_SUCCESS';
const DELETE_DEAL_STAGE_FAILED = 'DELETE_DEAL_STAGE_FAILED';

const GET_A_DEAL = 'GET_A_DEAL';
const GET_A_DEAL_SUCCESS = ' GET_A_DEAL_SUCCESS';
const GET_A_DEAL_FAILED = 'GET_A_DEAL_FAILED';

const POST_A_DEAL = 'POST_A_DEAL';
const POST_A_DEAL_SUCCESS = 'POST_A_DEAL_SUCCESS';
const POST_A_DEAL_FAILED = ' POST_A_DEAL_FAILED';

const UPDATE_A_DEAL = 'UPDATE_A_DEAL';
const UPDATE_A_DEAL_SUCCESS = 'UPDATE_A_DEAL_SUCCESS';
const UPDATE_A_DEAL_FAILED = 'UPDATE_A_DEAL_FAILED';

const SET_SELECTED_OWNER = 'SET_SELECTED_OWNER';

const GET_CONTACTS_WITH_ACCOUNT = 'GET_CONTACTS_WITH_ACCOUNT';
const GET_CONTACTS_WITH_ACCOUNT_SUCCESS = 'GET_CONTACTS_WITH_ACCOUNT_SUCCESS';
const GET_CONTACTS_WITH_ACCOUNT_FAILED = 'GET_CONTACTS_WITH_ACCOUNT_FAILED';

const DELETE_A_DEAL = 'DELETE_A_DEAL';
const DELETE_A_DEAL_SUCCESS = 'DELETE_A_DEAL_SUCCESS';
const DELETE_A_DEAL_FAILED = 'DELETE_A_DEAL_FAILED';

const GET_A_PIPELINE = 'GET_A_PIPELINE';
const GET_A_PIPELINE_SUCCESS = 'GET_A_PIPELINE_SUCCESS';
const GET_A_PIPELINE_FAILED = 'GET_A_PIPELINE_FAILED';

const DELETE_A_PIPELINE = 'DELETE_A_PIPELINE';
const DELETE_A_PIPELINE_SUCCESS = 'DELETE_A_PIPELINE_SUCCESS';
const DELETE_A_PIPELINE_FAILED = 'DELETE_A_PIPELINE_FAILED';

const UPDATE_PIPELINE = 'UPDATE_PIPELINE';
const UPDATE_PIPELINE_SUCCESS = 'UPDATE_PIPELINE_SUCCESS';
const UPDATE_PIPELINE_FAILED = 'UPDATE_PIPELINE_FAILED';

const ADD_C_TO_D = 'ADD_C_TO_D';
const ADD_C_TO_D_SUCCESS = 'ADD_C_TO_D_SUCCESS';
const ADD_C_TO_D_FAILED = 'ADD_C_TO_D_FAILED';

const GET_DEAL_N = 'GET_DEAL_N';
const GET_DEAL_N_SUCCESS = 'GET_DEAL_N_SUCCESS';
const GET_DEAL_N_FAILED = 'GET_DEAL_N_FAILED';

const GET_DEAL_T = 'GET_DEAL_T';
const GET_DEAL_T_SUCCESS = 'GET_DEAL_T_SUCCESS';
const GET_DEAL_T_FAILED = 'GET_DEAL_T_FAILED';

const POST_D_NOTE = 'POST_D_NOTE';
const POST_D_NOTE_SUCCESS = 'POST_D_NOTE_SUCCESS';
const POST_D_NOTE_FAILED = 'POST_D_NOTE_FAILED';

const CLEAR_DEAL_TL = 'CLEAR_DEAL_TL';

const SET_DTL_PAGE = 'SET_DTL_PAGE';

const SET_PIPE_ID = 'SET_PIPE_ID';

const initialState = {
    pipelines: [],
    dealStages: [],
    deals: [],
    dealsList: [],
    selectedDeal: "",
    selectedPipeline: "",
    selectedDealStage: "",
    selectedCurrency: "USD",
    selectedOwner: "",
    accountedContacts: [],
    pipe: {},
    currencies: [],
    dealDetails: null,
    loading: false,
    dealNotes: [],
    noteCount: 0,
    dealTimeline: [],
    timelineCount: 0,
    tlPage: 0,
    selectedPipe: "all",
    selectedPipeStages: []
};


export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
        case SAVE_PIPELINE:
        case GET_DEAL_STAGES:
        case GET_DEAL_PIPELINES:
            return {
                ...state,
                loading: true
            };
        case GET_DEAL_PIPELINES_SUCCESS:
            return {
                ...state,
                pipelines: payload.result || [],
                loading: false
            };
        case GET_A_PIPELINE_SUCCESS:
            const pipelineDetails = payload.result || {};
            const newPipelines = state.pipelines.map(p => (p.pipeline_id === pipelineDetails.pipeline_id ? pipelineDetails : p));

            return {
                ...state,
                selectedPipeStages: pipelineDetails.pipeline_stages || [],
                pipelines: newPipelines || state.pipelines
            };
        case GET_DEAL_STAGES_SUCCESS:
            return {
                ...state,
                dealStages: payload.result || [],
                loading: false
            };
        case GET_DEALS_SUCCESS:
            return {
                ...state,
                deals: payload.result || []
            };
        case GET_DEALS_LIST_SUCCESS:
            return {
                ...state,
                dealsList: payload.result || []
            };
        case SET_SELECTED_DEAL:
            return {
                ...state,
                selectedDeal: action.deal_id
            };
        case SET_SELECTED_PIPELINE:
            const pipeSelected = action.pipeline_id ? (state.pipelines.find(pipe => pipe.pipeline_id === action.pipeline_id) || {}) : {};
            
            return {
                ...state,
                selectedPipeline: action.pipeline_id,
                pipe: pipeSelected
            };
        case SET_SELECTED_DEAL_STAGE:
            return {
                ...state,
                selectedDealStage: action.stage_id
            };
        case SET_SELECTED_CURRENCY:
            return {
                ...state,
                selectedCurrency: action.currency_id
            };
        case GET_CURRENCIES_SUCCESS:
            return {
                ...state,
                currencies: payload.result || []
            };
        case POST_A_DEAL_SUCCESS:
            return {
                ...state,
                selectedDeal: `${payload.deal_id}` || ""
            };
        case SET_SELECTED_OWNER:
            return {
                ...state,
                selectedOwner: action.value
            };
        case GET_CONTACTS_WITH_ACCOUNT_SUCCESS:
            return {
                ...state,
                accountedContacts: payload.result || state.accountedContacts
            };
        case GET_A_DEAL:
            return {
                ...state,
                dealDetails: null
            };
        case GET_A_DEAL_SUCCESS:
            return {
                ...state,
                dealDetails: payload.result || null
            };
        case GET_DEAL_N_SUCCESS:
            return {
                ...state,
                dealNotes: payload.result && payload.result.notes || [],
                noteCount: payload.count || 0
            };
        case GET_DEAL_T_SUCCESS:
            return {
                ...state,
                dealTimeline: [
                    ...state.dealTimeline,
                    ...(payload.result)
                ],
                timelineCount: payload.count || 0
            };
        case CLEAR_DEAL_TL:
            return {
                ...state,
                dealTimeline: [],
                timelineCount: 0
            };
        case SET_DTL_PAGE:
            return {
                ...state,
                tlPage: action.page
            };
        case SET_PIPE_ID:
            return {
                ...state,
                selectedPipe: action.pipe_id
            };
        case SAVE_PIPELINE_SUCCESS:
        case SAVE_PIPELINE_FAILED:
        case GET_DEAL_STAGES_FAILED:
        case GET_DEAL_PIPELINES_FAILED:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}

export function getDealPipelines() {
    return {
        types: [GET_DEAL_PIPELINES, GET_DEAL_PIPELINES_SUCCESS, GET_DEAL_PIPELINES_FAILED],
        promise: fetch => fetch.get('api/Pipelineview')
    };
}

export function getAPipeline(pipeline_id) {
    return {
        types: [GET_A_PIPELINE, GET_A_PIPELINE_SUCCESS, GET_A_PIPELINE_FAILED],
        promise: fetch => fetch.get('api/Pipelines', {
            params: {
                pipeline_id
            }
        })
    };
}

export function savePipeline(pipeline) {
    return dispatch => dispatch({
        types: [SAVE_PIPELINE, SAVE_PIPELINE_SUCCESS, SAVE_PIPELINE_FAILED],
        promise: fetch => fetch.post('api/Pipelines', {
            data: pipeline
        })
    }).then((res) => {
        if (res.status === 1) {
            dispatch(setAlert(`New pipeline (${pipeline.pipeline_name}) has been added successfully.`, "success"));
    
            return dispatch(getDealPipelines());
        } else if (res.status === 0) {
            dispatch(setAlert(`[Error] ${res.message}`, "error"));

            return Promise.reject();
        }
    });
}

export function updatePipeline(pipeline) {
    return dispatch => dispatch({
        types: [UPDATE_PIPELINE, UPDATE_PIPELINE_SUCCESS, UPDATE_PIPELINE_FAILED],
        promise: fetch => fetch.put('api/Pipelines', {
            data: pipeline
        })
    }).then((res) => {
        if (res.status === 1) {
            dispatch(setAlert(`Pipeline (${pipeline.pipeline_name}) has been updated successfully.`, "success"));

            return dispatch(getDealPipelines());
        } else if (res.status === 0) {
            dispatch(setAlert(`[Error] ${res.message}` , "error"));

            return Promise.reject();
        }
    });
}

export function deleteAPipeline(pipeline_id) {
    return dispatch => dispatch({
        types: [DELETE_A_PIPELINE, DELETE_A_PIPELINE_SUCCESS, DELETE_A_PIPELINE_FAILED],
        promise: fetch => fetch.delete('api/Pipelines', {
            params: {
                pipeline_id
            }
        })
    }).then((response) => {
        if (response.status === 0) {
            return Promise.reject(response);
        }
        dispatch(setAlert(`Pipeline has been deleted successfully.`, "success"));

        return dispatch(getDealPipelines());
    });
}

export function getAllDealStages() {
    return (dispatch, getState) => {
        return dispatch({
            types: [GET_DEAL_STAGES, GET_DEAL_STAGES_SUCCESS, GET_DEAL_STAGES_FAILED],
            promise: fetch => fetch.get('api/Dealstages')
        }).then(() => getState().deals.dealStages);
    };
}

export function addDealStage(stage_name) {
    return dispatch => {
        return dispatch({
            types: [ADD_DEAL_STAGE, ADD_DEAL_STAGE_SUCCESS, ADD_DEAL_STAGE_FAILED],
            promise: fetch => fetch.post('api/Dealstages', {
                data: {
                    fields: [
                        {
                            stage_name
                        }
                    ]
                }
            })
        }).then((res) => {
            if (res.status === 1) {
                dispatch(setAlert(`A new deal stage has been added successfully.`, "success"));

                return dispatch(getAllDealStages());
            } else if (res.status === 0) {
                dispatch(setAlert(`Deal stage with name (${stage_name}) already exist.`, "error"));

                return Promise.reject();
            }
        });
    };
}

export function updateDealStage(stage_details) {
    return dispatch => {
        return dispatch({
            types: [UPDATE_DEAL_STAGE, UPDATE_DEAL_STAGE_SUCCESS, UPDATE_DEAL_STAGE_FAILED],
            promise: fetch => fetch.put('api/Dealstages', {
                data: {
                    ...stage_details
                }
            })
        }).then((res) => {
            if (res.status === 1) {
                dispatch(setAlert(`Deal stage has been updated successfully.`, "success"));
    
                return dispatch(getAllDealStages());
            } else if (res.status === 0) {
                dispatch(setAlert(`Deal stage with name (${stage_details.stage_name}) already exist.`, "error"));

                return Promise.reject();
            }
        });
    };
}

export function deleteDealStage(stage_id) {
    return dispatch => {
        return dispatch({
            types: [DELETE_DEAL_STAGE, DELETE_DEAL_STAGE_SUCCESS, DELETE_DEAL_STAGE_FAILED],
            promise: fetch => fetch.delete('api/Dealstages', {
                params: { stage_id }
            })
        }).then((response) => {
            if (response.status === 0) {
                return Promise.reject(response);
            }

            dispatch(setAlert(`Deal stage has been deleted successfully.`, "success"));

            return dispatch(getAllDealStages());
        });
    };
}

export function getDeals(pid = "") {
    return {
        types: [GET_DEALS, GET_DEALS_SUCCESS, GET_DEALS_FAILED],
        promise: fetch => fetch.get('api/Deals', {
            params: {
                pipeline_id: pid == "all" ? "" : pid
            }
        })
    };
}

export function getDealsList() {
    return {
        types: [GET_DEALS_LIST, GET_DEALS_LIST_SUCCESS, GET_DEALS_LIST_FAILED],
        promise: fetch => fetch.get('api/Deallist')
    };
}

export function setSelectedDeal(deal_id) {
    return {
        type: SET_SELECTED_DEAL,
        deal_id
    };
}

export function getADeal(deal_id) {
    return {
        types: [GET_A_DEAL, GET_A_DEAL_SUCCESS, GET_A_DEAL_FAILED],
        promise: fetch => fetch.get('api/Dealview', {
            params: {
                deal_id
            }
        })
    };
}

export function postADeal(deal_data, create) {
    return dispatch => dispatch({
        types: [POST_A_DEAL, POST_A_DEAL_SUCCESS, POST_A_DEAL_FAILED],
        promise: fetch => fetch.post('/api/Deals', {
            data: deal_data
        })
    }).then((res) => {
        if (res.status === 1) {

            dispatch(setAlert(`New deal (${deal_data.deal_name}) created successfully.`, "success"));
    
            if (create) {
                return dispatch(getDeals());
            }
    
            return dispatch(getDealsList());
        } else if (res.status === 0) {
            dispatch(setAlert(`[Error] ${res.message}`, "error"));

            return Promise.reject();
        }
    });
}

export function updateADeal(deal_data) {
    return dispatch => dispatch({
        types: [UPDATE_A_DEAL, UPDATE_A_DEAL_SUCCESS, UPDATE_A_DEAL_FAILED],
        promise: fetch => fetch.put('/api/Dealview', {
            data: deal_data
        })
    }).then((res) => {
        if (res.status === 1) {
            dispatch(setAlert(`Deal (${deal_data.deal_name}) updated successfully.`, "success"));

            return dispatch(getADeal(deal_data.deal_id));
        } else if (res.status === 0) {
            dispatch(setAlert(`[Error] ${res.message}`, "error"));

            return Promise.reject();
        }
    })
    .then(() => dispatch(getDeals()));
}

export function deleteADeal(deal_id) {
    return dispatch => dispatch({
        types: [DELETE_A_DEAL, DELETE_A_DEAL_SUCCESS, DELETE_A_DEAL_FAILED],
        promise: fetch => fetch.delete('api/Deals', {
            params: {
                deal_id
            }
        })
    }).then(() => {
        dispatch(setAlert(`Deal has been deleted successfully.`, "success"));

        return dispatch(getDeals());
    });
}

export function setSelectedPipeline(pipeline_id) {
    return {
        type: SET_SELECTED_PIPELINE,
        pipeline_id
    };
}

export function setSelectedPipe(pipe_id) {
    return {
        type: SET_PIPE_ID,
        pipe_id
    };
}

export function setSelectedDealStage(stage_id) {
    return {
        type: SET_SELECTED_DEAL_STAGE,
        stage_id
    };
}

export function getPipeAndSetPipe(pipe_id) {
    return dispatch => {
        let pipePromise = Promise.resolve();
    
        if (pipe_id) {
            pipePromise = dispatch(getAPipeline(pipe_id))
        }

        return pipePromise
        .then(() => dispatch(setSelectedPipeline(pipe_id)));
    };
}

export function setSelectedCurrency(currency_id) {
    return {
        type: SET_SELECTED_CURRENCY,
        currency_id
    };
}

export function getCurrencies() {
    return {
        types: [GET_CURRENCIES, GET_CURRENCIES_SUCCESS, GET_CURRENCIES_FAILED],
        promise: fetch => fetch.get('/api/Currencies')
    };
}

export function setSelectedOwner(value) {
    return {
        type: SET_SELECTED_OWNER,
        value
    }
}

export function getContactsWithAccount(account_id) {
    return {
        types: [GET_CONTACTS_WITH_ACCOUNT, GET_CONTACTS_WITH_ACCOUNT_SUCCESS, GET_CONTACTS_WITH_ACCOUNT_FAILED],
        promise: fetch => fetch.get('api/Contactslist', {
            params: {
                account_id
            }
        })
    };
}

export function AddContactToDeal(account_id, emails, phones, name, deal_id) {
    return dispatch => dispatch({
      types: [ADD_C_TO_D, ADD_C_TO_D_SUCCESS, ADD_C_TO_D_FAILED],
      promise: fetch => fetch.post('api/Contactdeal', { 
        data: {
          account_id,
          name,
          email_ids: emails,
          phone_numbers: phones,
          deal_id
        }
      })
    }).then((res) => {
        if (res.status === 1) {
            dispatch(setAlert(`New contact created and tagged successfully.`, "success"));
        }
        
        dispatch(getADeal(deal_id));
      return dispatch(getContactsWithAccount(account_id));
    }).catch(() => {
      dispatch(setAlert(`Error creating the contact.`, "error"));
  
      return Promise.reject();
    });
  }

  export function getDealNotes(deal_id, limit, offset) {
      return {
          types: [GET_DEAL_N, GET_DEAL_N_SUCCESS, GET_DEAL_N_FAILED],
          promise: fetch => fetch.get('api/Dealnotes', {
              params: {
                  deal_id,
                  limit,
                  offset
              }
          })
      };
  }

  export function postDealNote(deal_id, deal_note, files) {
      
        const formData = new FormData();
        if (files.length) {
            files.forEach((file) => {
                formData.append( 
                    "file[]", 
                    file,
                    file["name"]
                );
            })
        }

        formData.append( 
            "deal_id",
            deal_id
        );

        formData.append( 
            "deal_note",
            deal_note
        );

      return {
          types: [POST_D_NOTE, POST_D_NOTE_SUCCESS, POST_D_NOTE_FAILED],
          promise: fetch => fetch.post('api/Dealnotes', {
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }
          })
      };
  }

  export function postDNoteAndGetNotes(deal_id, deal_note, limit, offset, files) {

    return dispatch => dispatch(postDealNote(deal_id, deal_note, files)).then(() => {
        return dispatch(getDealNotes(deal_id, limit, offset));
    });
  }

  export function getDealTimeline(deal_id, limit, offset) {
    return {
        types: [GET_DEAL_T, GET_DEAL_T_SUCCESS, GET_DEAL_T_FAILED],
        promise: fetch => fetch.get('api/Deal_timeline', {
            params: { deal_id, limit, offset }
        })
    };
}

export function clearDealTL() {
    return {
        type: CLEAR_DEAL_TL
    };
}

export function setDealTLPage(page) {
    return {
        type: SET_DTL_PAGE,
        page
    };
}

export function getFirstSetOfDL(deal_id) {
    return dispatch => {
        dispatch(clearDealTL());
        dispatch(setDealTLPage(0));

        return dispatch(getDealTimeline(deal_id, config.dTimeline.limit, 0));
    }
}
