import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Delete as DeleteIcon } from "@mui/icons-material";
import {
  Button,
  LinearProgress,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { removeContacts } from "../../../redux/modules/leads";

function RemoveContact({ lead: { contact_id, contact_name } }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const onRemoveClick = () => {
    setOpen(true);
  };

  const removeContact = () => {
    setIsLoading(true);
    dispatch(removeContacts(contact_id)).then(() => {
      setIsLoading(false);
      setOpen(false);
    });
  };

  const handleConfirmClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={onRemoveClick} className="colorred">
        <DeleteIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Do you want to remove the contact #${contact_id} (${contact_name})?`}
          </DialogContentText>
        </DialogContent>
        {isLoading ? <LinearProgress /> : null}
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">
            Cancel
          </Button>
          <Button onClick={removeContact} style={{ color: "red" }} autoFocus>
            {" "}
            Confirm{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RemoveContact;
