import { setAlert } from './alert';

// ACTION TYPES
const SET_PAGE = 'SET_PAGE';
const SET_ROWS_PER_PAGE = 'SET_ROWS_PER_PAGE';

const GET_FORMS = 'GET_FORMS';
const GET_FORMS_SUCCESS = 'GET_FORMS_SUCCESS';
const GET_FORMS_FAIL = 'GET_FORMS_FAIL';

const GET_A_FORM = 'GET_A_FORM';
const GET_A_FORM_SUCCESS = 'GET_A_FORM_SUCCESS';
const GET_A_FORM_FAIL = 'GET_A_FORM_FAIL';

const DELETE_A_FORM = 'DELETE_A_FORM';
const DELETE_A_FORM_SUCCESS = 'DELETE_A_FORM_SUCCESS';
const DELETE_A_FORM_FAIL = 'DELETE_A_FORM_FAIL';

const CREATE_FORM = 'CREATE_FORM';
const CREATE_FORM_SUCCESS = 'CREATE_FORM_SUCCESS';
const CREATE_FORM_FAIL = 'CREATE_FORM_FAIL';

const UPDATE_FORM = 'UPDATE_FORM';
const UPDATE_FORM_SUCCESS = 'UPDATE_FORM_SUCCESS';
const UPDATE_FORM_FAIL = 'UPDATE_FORM_FAIL';

const GET_CONTACT_FORM = 'GET_CONTACT_FORM';
const GET_CONTACT_FORM_SUCCESS = 'GET_CONTACT_FORM_SUCCESS';
const GET_CONTACT_FORM_FAILED = 'GET_CONTACT_FORM_FAILED';

const GET_ALL_FORMLIST = 'GET_ALL_FORMLIST';
const GET_ALL_FORMLIST_S = 'GET_ALL_FORMLIST_S';
const GET_ALL_FORMLIST_F = 'GET_ALL_FORMLIST_F';

const SET_FORMLIST_PAGE = 'SET_FORMLIST_PAGE';

// INITIAL STATE
const initialState = {
  forms: [],
  page: 0,
  rowsPerPage: 10,
  loading: false,
  total: 0,
  form: {},
  contactFields: [],
  cFormId: "",
  fields: [],
  formData: '',
  styleData: '',
  notchValue: '',
  formsList: [],
  formPage: 0,
  totalForms: 0,
};


// REDUCER
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_PAGE:
      return {
        ...state,
        page: action.page
      };
    case SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.rowsPerPage
      };
    case GET_FORMS:
    case GET_A_FORM:
        return {
            ...state,
            notchValue: '',
            loading: true
        };
      case GET_CONTACT_FORM:
        return {
          ...state,
          contactFields: [],
          cFormId: ""
        };
    case GET_A_FORM_SUCCESS:
      return {
        ...state,
        form: payload.form || {},
        fields: payload.fields || [],
        formData: payload.form_data || '',
        styleData: payload.style_data || '',
        embedCode: payload.embed || '',
        notchValue: payload.notch || '',
        loading: false
      };
    case GET_CONTACT_FORM_SUCCESS:
      return {
        ...state,
        contactFields: payload.fields || [],
        cFormId: payload.form && payload.form["id"] || ""
      };
    case GET_FORMS_SUCCESS:
      return {
        ...state,
        forms: payload.result || [],
        total: Number(payload.count || "0"),
        loading: false
      };
    case GET_A_FORM_FAIL:
    case GET_FORMS_FAIL:
        return {
            ...state,
            loading: false
        };
    case GET_ALL_FORMLIST_S:
      return {
        ...state,
        formsList: payload.result || [],
        totalForms: Number(payload.count || "0"),
      };
    case SET_FORMLIST_PAGE:
      return {
        ...state,
        formPage: action.page
      };
    default:
      return state;
  }
}

// ACTION CREATORS
export function setPage(page) {
  return {
    type: SET_PAGE,
    page
  };
}

export function setRowsPerPage(rowsPerPage) {
  return {
    type: SET_ROWS_PER_PAGE,
    rowsPerPage
  };
}

export function getForms() {
  return (dispatch, getState) => {
    const { forms: { page, rowsPerPage } } = getState();

    return dispatch({
      types: [GET_FORMS, GET_FORMS_SUCCESS, GET_FORMS_FAIL],
      promise: fetch => fetch.get('api/formlisting', {
        params: {
            limit: rowsPerPage,
            offset: page
        }
      })
    });
  }
}

export function getAForm(form_id, origin = "") {
  return {
    types: [GET_A_FORM, GET_A_FORM_SUCCESS, GET_A_FORM_FAIL],
    promise: fetch => fetch.get('api/Form', {
      params: {
          form_id,
          origin
      }
    })
  };
}

export function getAContactForm(form_id) {
  return {
    types: [GET_CONTACT_FORM, GET_CONTACT_FORM_SUCCESS, GET_CONTACT_FORM_FAILED],
    promise: fetch => fetch.get('api/Form', {
      params: {
          form_id,
          origin: ""
      }
    })
  };
}

export function resetContactForm() {
  return {
    type: GET_CONTACT_FORM
  };
}

export function getAFormAndReturnPromise(form_id) {
  return (dispatch, getState) => dispatch(getAForm(form_id)).then(() => {
    return getState().forms;
  })
}

export function deleteAForm(form_id) {
  return dispatch => dispatch({
    types: [DELETE_A_FORM, DELETE_A_FORM_SUCCESS, DELETE_A_FORM_FAIL],
    promise: fetch => fetch.delete('api/form/formdelete', {
      data: {
        id: form_id
      }
    })
  }).then(() => {
    dispatch(setAlert("Form deleted successfully.", "success"));

    return dispatch(getForms());
  });
}

export function createAForm(fields) {
  return dispatch => dispatch({
    types: [CREATE_FORM, CREATE_FORM_SUCCESS, CREATE_FORM_FAIL],
    promise: fetch => fetch.post('/api/form/createform', {
      data: fields
    })
  }).then((res) => {
    if (res.status === 1) {
      dispatch(setAlert("Form created successfully.", "success"));

      return dispatch(getForms());
    } else if (res.status === 0) {
      dispatch(setAlert(`[Error] ${res.message}`, "error"));

      return Promise.reject();
    }

  });
}

export function updateAForm(fields) {
  return dispatch => dispatch({
    types: [UPDATE_FORM, UPDATE_FORM_SUCCESS, UPDATE_FORM_FAIL],
    promise: fetch => fetch.put('/api/form/formupdation', {
      data: fields
    })
  }).then((res) => {
    if (res.status === 1) {
      dispatch(setAlert("Form updated successfully.", "success"));

      return dispatch(getForms());
    } else if (res.status === 0) {
      dispatch(setAlert(`[Error] ${res.message}`, "error"));

      return Promise.reject();
    }
  });
}

export function getAllFormsList() {
  return (dispatch, getState) => {
    const { forms: { formPage } } = getState();

    return dispatch({
      types: [GET_ALL_FORMLIST, GET_ALL_FORMLIST_S, GET_ALL_FORMLIST_F],
      promise: fetch => fetch.get('api/formlisting', {
        params: {
          limit: 10,
          offset: formPage
        }
      })
    });
  }
}

export function setFormlistPage(page) {
  return {
    type: SET_FORMLIST_PAGE,
    page
  };
}