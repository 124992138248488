import React, { PureComponent, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Box,
  Card,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  MenuItem,
  Popover,
  Select,
  Switch,
  Tabs,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { AddCircleOutline as AddCircleOutlineIcon } from "@mui/icons-material";
import {
  getRolePermissions,
  getRoles,
  addNewRole,
  updateRole,
  deleteRole,
  updateRolesPermissions,
} from "../../../redux/modules/settings";
import DialogTitle from "../../../components/DialogTitle";

function isRolePermissionsEqual(a, b) {
  return a
    .map((perm, i) => {
      return perm.rights
        .map((right, j) => {
          return right.access === b[i]["rights"][j]["access"];
        })
        .filter((v) => v === false).length;
    })
    .filter((w) => w > 0).length;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ margin: "20px 15px" }}>{children}</Box>}
    </div>
  );
}

const styles = {
  root: {
    flexGrow: 1,
    display: "flex",
  },
  tabs: {
    borderRight: "1px solid #e0e0e0",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "right",
    margin: 15,
  },
};

function RoleItem({
  role,
  index,
  cancelNew,
  isNew,
  addNew,
  update,
  remove,
  updateRoleState,
  total,
}) {
  const [editable, setEditable] = useState(isNew);
  const [name, setName] = useState(role.name);
  const [disableSave, setDisableSave] = useState(!name);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleRoleNameChange = (e) => {
    const value = e.target.value;
    const save = !value || value === role.name;

    if (disableSave != save) {
      setDisableSave(save);
    }

    setName(value);
  };

  const onEdit = () => {
    setEditable(true);
    setDisableSave(true);
  };

  const onCancel = () => {
    if (isNew) {
      cancelNew(index);
    } else {
      setEditable(false);
      setName(role.name);
    }
  };

  const onDelete = (e) => {
    if ((role.users && Number(role.users) > 0) || total === 1) {
      setAnchorEl(e.currentTarget);
    } else {
      remove(role.role_id).then(() => {
        updateRoleState();
      });
    }
  };

  const onSave = () => {
    if (isNew) {
      addNew({ name }).then(() => {
        setEditable(false);
        updateRoleState();
      });
    } else {
      update({ ...role, name }).then(() => {
        setEditable(false);
        updateRoleState();
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="role_item">
      {editable ? (
        <TextField
          size="small"
          className="text-field"
          name="item"
          label="Role"
          variant="outlined"
          onChange={handleRoleNameChange}
          value={name}
          required
        />
      ) : (
        <Typography className="role_name">{role.name}</Typography>
      )}
      {editable ? (
        <div className="save_cancel_wrapper">
          <Button onClick={onSave} disabled={disableSave}>
            Save
          </Button>
          <Button onClick={onCancel} style={{ color: "#000" }}>
            Cancel
          </Button>
        </div>
      ) : (
        <div className="save_cancel_wrapper">
          <Button className="bdr-btn-style-green" onClick={onEdit}>
            Edit
          </Button>
          <Button className="bdr-btn-style-chu" onClick={onDelete}>
            Delete
          </Button>
          <Popover
            id={role.role_id}
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography style={{ padding: 15, maxWidth: 200 }}>
              {total === 1
                ? "You should have minimum one role."
                : `You cannot delete this role, since ${`${role.users} ${
                    Number(role.users) === 1 ? "staff is" : "staffs are"
                  }`} using this role.`}
            </Typography>
          </Popover>
        </div>
      )}
    </div>
  );
}

class RolePermissions extends PureComponent {
  state = {
    openEdit: false,
    tab: 0,
    disableSave: true,
    role: "",
    permissions: null,
    openRoleEdit: false,
    roles: [],
    loading: false,
  };

  componentDidUpdate(_prevProps, prevState) {
    if (
      !this.state.role &&
      this.props.roles.length &&
      this.props.rolePermissions.length
    ) {
      const firstRole = this.props.roles[0] || {};
      const role = this.props.rolePermissions.find(
        (role) => role.role_id === firstRole.role_id
      );

      this.setState({
        roles: this.props.roles,
        role: this.props.roles[0]["role_id"],
        permissions: role.permissions,
      });
    }

    if (this.props.roles.length && !this.state.roles.length) {
      this.setState({ roles: this.props.roles });
    }

    const accessEditOpen = this.state.openEdit && !prevState.openEdit;

    const promises = [];

    if (
      (this.state.openRoleEdit && !prevState.openRoleEdit) ||
      accessEditOpen
    ) {
      this.setState({ loading: true });

      if (
        (this.state.openRoleEdit || this.state.openEdit) &&
        !this.props.loadingRoles
      ) {
        promises.push(
          this.props.getRoles().then(() => {
            this.setState({
              roles: this.props.roles,
              role: this.props.roles[0] && this.props.roles[0]["role_id"],
            });
          })
        );
      }

      if (accessEditOpen && !this.state.openRoleEdit && !this.props.loading) {
        promises.push(
          this.props.getRolePermissions().then(() => {
            const role = this.props.rolePermissions.find(
              (role) => role.role_id === this.state.role
            );

            this.setState({ permissions: !!role && role.permissions });
          })
        );
      }

      Promise.all(promises).then(() => {
        this.setState({ loading: false });
      });
    }

    if (this.props.rolePermissions.length && !this.state.permissions) {
      const role = this.props.rolePermissions.find(
        (role) => role.role_id === this.state.role
      );

      this.setState({
        permissions: !!role && role.permissions,
        disableSave: true,
      });
    }
  }

  onChangeClick = () => {
    this.setState({ openEdit: true });
  };

  onChangeRoleClick = () => {
    this.setState({ openRoleEdit: true });
  };

  onRoleClose = () => {
    this.setState({ openRoleEdit: false, roles: this.props.roles });
  };

  onClose = () => {
    const firstRole = this.props.roles[0] || {};
    const role = this.props.rolePermissions.find(
      (role) => role.role_id === firstRole.role_id
    );

    this.setState({
      openEdit: false,
      role: firstRole.role_id,
      permissions: role.permissions || this.state.permissions,
    });
  };

  handleTabChange = (e, value) => {
    this.setState({ tab: value });
  };

  savePermissions = () => {
    let newPermissions = [];
    this.state.permissions
      .map((permi) => permi.rights)
      .forEach((functions) => {
        newPermissions = [...newPermissions, ...functions];
      });
    const updatedPermissions = {
      role_id: this.state.role,
      rights: newPermissions,
    };

    this.setState({ loading: true });
    this.props.updateRolesPermissions(updatedPermissions).then(() => {
      this.props.getRolePermissions().then(() => {
        this.setState({
          roles: this.props.roles,
          disableSave: true,
          loading: false,
        });
      });
    });
  };

  handleRoleChange = (value) => {
    const role = this.props.rolePermissions.find(
      (role) => role.role_id === value
    );

    this.setState({
      role: value,
      permissions: !!role && role.permissions,
      tab: 0,
    });
  };

  handlePermissionChange = (right, sectionName) => {
    const roleObjFromProps = this.props.rolePermissions.find(
      (role) => role.role_id === this.state.role
    );
    const permissions = [...(this.state.permissions || [])];
    const sectionIndex = permissions.findIndex(
      (sec) => sec.module_name === sectionName
    );
    const sectionUpdated = permissions.find(
      (sec) => sec.module_name === sectionName
    );
    const updatedRightIndex = sectionUpdated.rights.findIndex(
      (rObj) => rObj.function_id === right.function_id
    );
    const targetRight = sectionUpdated.rights[updatedRightIndex];
    const updatedRight = {
      ...targetRight,
      access: targetRight["access"] === "0" ? "1" : "0",
    };
    let newRights = [...sectionUpdated.rights];
    newRights[updatedRightIndex] = updatedRight;
    const updatedSection = { ...sectionUpdated, rights: newRights };
    permissions[sectionIndex] = updatedSection;
    const isEqual = isRolePermissionsEqual(
      roleObjFromProps && roleObjFromProps.permissions,
      permissions
    );

    this.setState({ permissions, disableSave: !isEqual });
  };

  onAddNewRole = () => {
    const roles = this.state.roles;
    const prevRoles = [...roles];
    const roleId = Number(roles[roles.length - 1]["role_id"]) + 1;
    prevRoles.push({
      role_id: roleId,
      name: "",
    });

    this.setState({ roles: prevRoles });
  };

  onCancelNew = (i) => {
    const prevRoles = [...this.state.roles];

    prevRoles.splice(i, 1);
    this.setState({ roles: prevRoles });
  };

  updateRoleState = () => {
    this.setState({ roles: this.props.roles });
  };

  render() {
    const permissions = this.state.permissions;

    return (
      <div>
        <Card className="list_paper">
          <List className="settings_list">
            <ListItem className="list_item">
              <ListItemText
                primary={<Typography>Roles</Typography>}
                secondary={
                  <Typography variant="caption">
                    You can add or edit user role here.
                  </Typography>
                }
              />
              <Button
                autoFocus
                onClick={this.onChangeRoleClick}
                color="primary"
                className="sec-button"
              >
                Change
              </Button>
            </ListItem>
            <ListItem className="list_item">
              <ListItemText
                primary={<Typography>Access</Typography>}
                secondary={
                  <Typography variant="caption">
                    You can edit user role access to ui elements.
                  </Typography>
                }
              />
              <Button
                autoFocus
                onClick={this.onChangeClick}
                color="primary"
                className="sec-button"
              >
                Change
              </Button>
            </ListItem>
          </List>
        </Card>
        <Dialog open={this.state.openEdit} onClose={this.onClose}>
          <DialogTitle onClose={this.onClose}>User Role Access</DialogTitle>
          <Divider />
          <DialogContent style={{ minWidth: 500 }}>
            <div className="role_change_header">
              <FormControl
                className="role_input"
                variant="outlined"
                error={false}
                style={{ minWidth: 80 }}
              >
                <InputLabel>Role</InputLabel>
                <Select
                  value={this.state.role}
                  margin="dense"
                  style={{ minWidth: 100, margin: 0, maxHeight: 40, }}
                  onChange={(e) => this.handleRoleChange(e.target.value)}
                  label="Role"
                >
                  {this.props.roles.map((role, key) => (
                    <MenuItem key={key} value={role.role_id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {this.state.loading ? <LinearProgress /> : null}
            <div style={styles.root}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={this.state.tab}
                onChange={this.handleTabChange}
                style={styles.tabs}
              >
                {permissions
                  ? permissions.map((section, i) => (
                      <Tab key={i} label={section.module_name} />
                    ))
                  : null}
              </Tabs>
              {permissions
                ? permissions.map((section, j) => (
                    <TabPanel
                      key={j}
                      className="rights_panel"
                      value={this.state.tab}
                      index={j}
                    >
                      {section.rights.map((right, k) => (
                        <div className="right_holder" key={k}>
                          <Typography>{right.function_name}</Typography>
                          <Switch
                            checked={!!Number(right.access)}
                            onChange={() =>
                              this.handlePermissionChange(
                                right,
                                section.module_name
                              )
                            }
                            color="primary"
                            name={right.function_name}
                          />
                        </div>
                      ))}
                    </TabPanel>
                  ))
                : null}
            </div>
            <div style={styles.buttonContainer}>
              <Button
                variant="contained"
                onClick={this.savePermissions}
                color="primary"
                disabled={this.state.disableSave}
                autoFocus
              >
                Save Changes
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog open={this.state.openRoleEdit} onClose={this.onRoleClose}>
          <DialogTitle onClose={this.onRoleClose}>User Roles</DialogTitle>
          <Divider />
          <DialogContent>
            {this.state.roles.map((role, i) => (
              <RoleItem
                key={role.role_id}
                index={i}
                role={role}
                isNew={!role.name}
                cancelNew={this.onCancelNew}
                addNew={this.props.addNewRole}
                update={this.props.updateRole}
                remove={this.props.deleteRole}
                updateRoleState={this.updateRoleState}
                total={this.state.roles.length}
              />
            ))}
            {this.props.loading || this.props.loadingRoles ? (
              <LinearProgress />
            ) : null}
            <Button className="role_add_button" onClick={this.onAddNewRole}>
              <AddCircleOutlineIcon />
            </Button>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    rolePermissions: state.settings.rolePermissions,
    roles: state.settings.roles,
    loading: state.settings.loading,
    loadingRoles: state.settings.loadingRoles,
  }),
  {
    getRolePermissions,
    getRoles,
    addNewRole,
    updateRole,
    deleteRole,
    updateRolesPermissions,
  }
)(RolePermissions);
