import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Divider,
  Paper,
  Typography,
  Grid,
  LinearProgress,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import AccessControl from "../../components/AccessControl";
import {
  getAllDealStages,
  getDeals,
  getCurrencies,
  getDealPipelines,
  setSelectedPipe,
  getAPipeline,
} from "../../redux/modules/deals";
import { getAllAccountAndSetFirst } from "../../redux/modules/leads";
import DealItem from "./components/DealItem";
import CreateDeal from "./components/CreateDeal";
import { Helmet } from "react-helmet";

function Deals() {
  const scrollContainer = useRef();
  const initialLoad = useRef(true);
  const dispatch = useDispatch();
  const dealStages = useSelector((state) => state.deals.dealStages);
  const selectedPipe = useSelector((state) => state.deals.selectedPipe);
  const deals = useSelector((state) => state.deals.deals);
  const accounts = useSelector((state) => state.leads.accounts);
  const pipelines = useSelector((state) => state.deals.pipelines);
  const selectedPipeStages = useSelector(
    (state) => state.deals.selectedPipeStages
  );
  const currencies = useSelector((state) => state.deals.currencies);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let initialPromises = [];
    setLoading(true);
    dispatch(setSelectedPipe('all'));

    initialPromises.push(dispatch(getAllDealStages()));
    initialPromises.push(dispatch(getDeals()));

    if (!pipelines.length) {
      initialPromises.push(dispatch(getDealPipelines()));
    }

    if (!accounts.length) {
      initialPromises.push(dispatch(getAllAccountAndSetFirst()));
    }

    if (!currencies.length) {
      initialPromises.push(dispatch(getCurrencies()));
    }

    Promise.all(initialPromises).then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!initialLoad.current) {
      setLoading(true);
      dispatch(getDeals(selectedPipe))
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }

    initialLoad.current = false;
  }, [selectedPipe]);

  const onPipeChange = (e) => {
    dispatch(setSelectedPipe(e.target.value || "all"));

    if (e.target.value) {
      dispatch(getAPipeline(e.target.value));
    }
  };

  const onWheel = (e) => {
    // scrollContainer.current.scrollLeft += e.deltaY;
  };

  const availableStages =
    selectedPipe != "all" ? selectedPipeStages : dealStages;

    console.log(selectedPipe, selectedPipeStages, dealStages)

  return (
    <>
      <Helmet>
        <title>Deals</title>
        <meta name="description" content="Deals page" />
      </Helmet>
      <AccessControl name="view_deals" message>
        <div className="deals_container">
          <div className="page_t_head">
            <div className="page_d_wrapper">
              <Typography className="page_title">Deals</Typography>
              <AccessControl name="create_deals">
                <CreateDeal />
              </AccessControl>
            </div>
            <div className="lp-deals">
              {loading ? <LinearProgress /> : null}
            </div>
          </div>
          <div className="deal-toolbar">
            <FormControl variant="outlined" color="primary">
              <Select
                style={{ maxHeight: 40 }}
                margin="dense"
                defaultValue="all"
                value={selectedPipe || "all"}
                onChange={onPipeChange}
              >
                <MenuItem value="all">All Pipelines</MenuItem>
                {pipelines.map((pl, l) => {
                  return (
                    <MenuItem key={l} value={pl.pipeline_id}>
                      {pl.pipeline_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className="deals-view-container">
            <div
              className="deals-view-funnel"
              ref={scrollContainer}
              onWheel={onWheel}
            >
              {availableStages.length ? (
                availableStages.map((stage, i) => {
                  const filteredDeals = deals.filter(
                    (d) => d.deal_stage === stage.stage_id
                  );

                  return (
                    <Paper className="deals-view-funnel-item" key={i}>
                      <Typography className="stage-name-head" variant="h6">
                        {stage.stage_name} ({filteredDeals.length})
                      </Typography>
                      <Divider
                        className="deals-head-divider"
                        style={{ marginBottom: 15 }}
                      />
                      <div className="deals-list">
                        {filteredDeals.length ? (
                          filteredDeals.map((ds, j) => {
                            const taggedAccount = accounts.find(
                              (acc) => acc.account_id === ds.contact_account
                            );

                            return (
                              <DealItem
                                key={j}
                                deal={ds}
                                stage={stage.stage_name}
                                account={taggedAccount}
                                currencies={currencies}
                              />
                            );
                          })
                        ) : (
                          <Typography className="no-deals-message">
                            No deals in this stage
                          </Typography>
                        )}
                      </div>
                    </Paper>
                  );
                })
              ) : loading ? (
                ""
              ) : (
                <Typography
                  className="empty-stages-message"
                  component="div"
                  variant="caption"
                >
                  No deals and deal stages available to show. Add new deal
                  stages and pipelines from settings.
                </Typography>
              )}
            </div>
          </div>
        </div>
      </AccessControl>
    </>
  );
}

export default Deals;
