import { FormControlLabel, Switch } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPrioritySwitch } from "../../../redux/modules/auth";

function PrioritySwitcher({ refresh }) {
  const dispatch = useDispatch();
  const [setting, setSetting] = useState(false);
  const priority = useSelector((state) => state.auth.prioritySwitch);
  const [checked, setChecked] = useState(!!Number(priority));

  const onSwitchPriority = () => {
    setSetting(true);
    dispatch(setPrioritySwitch(!checked ? 1 : 0)).then(() => {
      setChecked(!checked);
      setSetting(false);
      refresh();
    });
  };

  return (
    <div className="p_switcher">
      <FormControlLabel
        control={<Switch checked={checked} onChange={onSwitchPriority} />}
        label="Priority Sorting"
        disabled={setting}
      />
    </div>
  );
}

export default PrioritySwitcher;
