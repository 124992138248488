import { CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { loginForAccount, setAuthToken } from "../../redux/modules/auth";

function Home() {
  const params = useParams();
  const dispatch = useDispatch();
  const tokenSet = useSelector((state) => state.auth.tokenSet);

  useEffect(() => {
    const authToken = params.token;

    if (authToken) {
      dispatch(loginForAccount(authToken));
    }
  }, []);

  return (
    <div>
      <div className="pre-loader">
        <CircularProgress />
        {tokenSet ? <Navigate to="/leads" /> : null}
      </div>
    </div>
  );
}

export default Home;
