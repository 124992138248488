import React, { useState } from "react";
import {
  Button,
  Divider,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DeleteForever } from "@mui/icons-material";

function AddressForm(props) {
  const [accountName, setAccName] = useState(
    (props.edit && props.account.name) || ""
  );
  const [address, setAddress] = useState(
    (props.edit && props.account.address) || {}
  );
  const [phone, setPhone] = useState((props.edit && props.account.phone) || "");
  const [web, setWeb] = useState((props.edit && props.account.website) || "");
  const [loading, setLoading] = useState(false);
  const [custom, setCustom] = useState(null);
  const [savingC, setSavingC] = useState(false);
  const [customFields, setCF] = useState(props.account.custom || []);
  const [deleted, setDel] = useState([]);

  const disableSave =
    !accountName ||
    !address.line1 ||
    !address.city ||
    !address.state ||
    !address.zipcode ||
    !address.country;

  const onValueChange = (name) => (e) => {
    setAddress({ ...address, [name]: e.target.value });
  };

  const onCountryChange = (name) => (e) => {
    setAddress({
      ...address,
      [name]: e.target.value === "select" ? "" : e.target.value,
    });
  };

  const onACCNameChange = (e) => {
    setAccName(e.target.value);
  };

  const onPhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const onWebsiteChange = (e) => {
    setWeb(e.target.value);
  };

  const onAddCustomField = () => {
    setCustom({});
  };

  const onCustomAdd = () => {
    if (custom.field_value) {
      setCF([...customFields, custom]);
    }

    setCustom(null);
  };

  const onCustomCancel = () => {
    setCustom(null);
  };

  const onCChange = (field_name) => (e) => {
    setCustom({ ...custom, [field_name]: e.target.value });
  };

  const onCustomFieldChange = (field_name, index) => (e) => {
    const cusFields = [...customFields];
    cusFields[index] = { ...cusFields[index], field_value: e.target.value };
    setCF(cusFields);
  };

  const onRemoveField = (index) => {
    const cusFields = [...customFields];
    const deletedField = cusFields[index];

    if (deletedField.custom_field_id) {
      setDel([...deleted, { custom_field_id: deletedField.custom_field_id }]);
    }

    cusFields.splice(index, 1);
    setCF(cusFields);
  };

  const onCancel = () => {
    setAccName((props.edit && props.account.name) || "");
    setAddress((props.edit && props.account.address) || {});
    setPhone((props.edit && props.account.phone) || "");

    props.cancel();
  };

  const onSaveClick = () => {
    if (props.edit && props.update) {
      setLoading(true);
      props
        .update({
          ...props.account,
          name: accountName,
          address,
          phone,
          website: web,
          custom: [...customFields, ...deleted],
        })
        .then(() => {
          setLoading(false);
          props.cancel();
        })
        .catch(() => {
          setLoading(false);
        });
    } else if (props.save) {
      setLoading(true);
      props
        .save({
          name: accountName,
          address,
          phone,
          website: web,
          custom: customFields,
        })
        .then(() => {
          setLoading(false);
          props.cancel();
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="address-form">
      {props.account ? (
        <>
          {!props.page ? (
            <>
              <Typography variant="h6">
                {props.edit
                  ? "Edit account details"
                  : "Add new account details"}
              </Typography>
              <Divider className="address-form-divider" />
            </>
          ) : null}
          <Typography>Account Name*</Typography>
          <TextField
            size="small"
            className="address-input"
            margin="dense"
            variant="outlined"
            onChange={onACCNameChange}
            value={accountName}
          />
        </>
      ) : null}

      <Typography>Address Line 1*</Typography>
      <TextField
        size="small"
        className="address-input"
        margin="dense"
        variant="outlined"
        onChange={onValueChange("line1")}
        value={address.line1}
      />
      <Typography>Address Line 2</Typography>
      <TextField
        size="small"
        className="address-input"
        margin="dense"
        variant="outlined"
        onChange={onValueChange("line2")}
        value={address.line2}
      />
      <Typography>City*</Typography>
      <TextField
        size="small"
        className="address-input"
        margin="dense"
        variant="outlined"
        onChange={onValueChange("city")}
        value={address.city}
      />
      <Typography>State/Province/Region*</Typography>
      <TextField
        size="small"
        className="address-input"
        margin="dense"
        variant="outlined"
        onChange={onValueChange("state")}
        value={address.state}
      />
      <Typography>ZIP/Postal Code*</Typography>
      <TextField
        size="small"
        className="address-input"
        margin="dense"
        variant="outlined"
        onChange={onValueChange("zipcode")}
        value={address.zipcode}
      />
      <Typography>Country*</Typography>
      <Select
        className="address-input"
        margin="dense"
        variant="outlined"
        onChange={onCountryChange("country")}
        value={address.country || "select"}
      >
        <MenuItem value="select">----- Select a Country -----</MenuItem>
        {props.countries.map((cou, key) => (
          <MenuItem key={key} value={cou.name}>
            {cou.name}
          </MenuItem>
        ))}
      </Select>
      <Typography>Phone</Typography>
      <TextField
        size="small"
        className="address-input"
        margin="dense"
        variant="outlined"
        onChange={onPhoneChange}
        value={phone}
      />
      <Typography>Website</Typography>
      <TextField
        size="small"
        className="address-input"
        margin="dense"
        variant="outlined"
        onChange={onWebsiteChange}
        value={web}
      />
      {customFields.map((cf, c) => (
        <div key={c}>
          <div className="cf_label_bar">
            <Typography>{cf.field_name}</Typography>
            <IconButton
              className="remove__icon"
              onClick={() => onRemoveField(c)}
            >
              <DeleteForever fontSize="small" />
            </IconButton>
          </div>
          <TextField
            size="small"
            className="address-input"
            margin="dense"
            variant="outlined"
            onChange={onCustomFieldChange(cf.field_name, c)}
            value={cf.field_value}
          />
        </div>
      ))}
      <div className="custom__fields">
        {custom ? (
          <>
            <Typography>Field label*</Typography>
            <TextField
              size="small"
              className="address-input"
              margin="dense"
              variant="outlined"
              onChange={onCChange("field_name")}
            />
            <Typography>Field value*</Typography>
            <TextField
              size="small"
              className="address-input"
              margin="dense"
              variant="outlined"
              onChange={onCChange("field_value")}
            />
          </>
        ) : null}
        <div className="lp__holder">{savingC ? <LinearProgress /> : null}</div>
        {custom ? (
          <>
            <Button onClick={onCustomAdd}>Add</Button>
            <Button onClick={onCustomCancel} style={{ color: "#000" }}>
              Cancel
            </Button>
          </>
        ) : (
          <Button color="primary" onClick={onAddCustomField}>
            Add custom field
          </Button>
        )}
      </div>
      <Divider />
      {loading ? <LinearProgress /> : null}
      <div className="address-actions">
        <Button
          className="action-btns"
          style={{ color: "#000" }}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Divider orientation="vertical" flexItem />
        <Button
          className="action-btns"
          onClick={onSaveClick}
          color="primary"
          disabled={disableSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default AddressForm;
