import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import {
  MoreVert as MoreVertIcon,
  Archive as ArchiveIcon,
  Unarchive as UnArchiveIcon,
  Email as EmailIcon,
} from "@mui/icons-material";
import {
  getLeadDetails,
  postLeadNotes,
  setActiveLead,
  updateActiveStatus,
} from "../../../redux/modules/leads";
import getAccessFor from "../../../helpers/getAccessFor";
import FollowUp from "./FollowUp";
import AccessControl from "../../../components/AccessControl";
import { getBTemplates, getEBDraft } from "../../../redux/modules/email";
import Assign from "./Assign";
import { getAContactForm } from "../../../redux/modules/forms";

function More({ leadId, lead, activeStatus, getFUB, setLoading }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openFU, setOpenFU] = useState(false);
  const inactive = activeStatus === "0";
  const [loading, setLoad] = useState(false);
  const [loadingFU, setLoadingFU] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onArchiveClick = () => {
    const newActiveStatus = inactive ? "1" : "0";
    setLoading(true);
    setLoad(true);
    dispatch(updateActiveStatus(leadId, lead.name, newActiveStatus))
      .then(() => {
        dispatch(
          postLeadNotes(
            leadId,
            null,
            newActiveStatus === "1" ? "Activated" : "Archived",
            "log"
          )
        );
        setLoading(false);
        setLoad(false);
      })
      .catch(() => {
        setLoading(false);
        setLoad(false);
      });
    handleClose();
  };

  const onFollowUpClick = () => {
    let fuPromises = [];
    if (leadId) {
      dispatch(setActiveLead(leadId));
    }

    setLoadingFU(true);
    fuPromises.push(dispatch(getAContactForm(lead.form_id)));
    fuPromises.push(getFUB());
    fuPromises.push(dispatch(getBTemplates()));
    fuPromises.push(dispatch(getEBDraft({ lead_id: leadId })));
    fuPromises.push(dispatch(getLeadDetails(leadId)));
    Promise.all(fuPromises)
      .then(() => {
        setLoadingFU(false);
      })
      .catch(() => {
        setLoadingFU(false);
      });
    setOpenFU(true);
    setAnchorEl(null);
  };

  const onFollowUpClose = () => {
    if (leadId) {
      dispatch(setActiveLead(""));
    }
    setOpenFU(false);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {inactive ? null : (
          <AccessControl name="lead_follow_up">
            <MenuItem onClick={onFollowUpClick} disabled={loading}>
              <ListItemIcon>
                <EmailIcon fontSize="small" />
                <Typography variant="inherit" style={{ marginLeft: 5 }}>
                  Follow up
                </Typography>
              </ListItemIcon>
            </MenuItem>
          </AccessControl>
        )}
        {inactive ? null : (
          <AccessControl name="staff_assign">
            <Assign
              leadId={leadId}
              lead={lead}
              setAnchorEl={setAnchorEl}
              loading={loading}
            />
          </AccessControl>
        )}
        {(!inactive && getAccessFor("archive")) ||
        (inactive && getAccessFor("activate_leads")) ? (
          <MenuItem onClick={onArchiveClick} disabled={loading}>
            <ListItemIcon>
              {inactive ? (
                <UnArchiveIcon fontSize="small" />
              ) : (
                <ArchiveIcon fontSize="small" />
              )}
              <Typography variant="inherit" style={{ marginLeft: 5 }}>
                {inactive ? "Activate" : "Archive"}
              </Typography>
            </ListItemIcon>
          </MenuItem>
        ) : null}
      </Menu>
      <FollowUp
        open={openFU}
        close={onFollowUpClose}
        lead={lead}
        loadingFU={loadingFU}
      />
    </>
  );
}

export default More;
