import React, { useState, Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { connect, useSelector } from "react-redux";
import { logout } from "../../../../redux/modules/auth";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  Typography,
  Box,
  Popper,
  Fade,
  Card,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";
import {
  Menu as MenuIcon,
  PowerSettingsNew as PowerSettingsNewIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from "@mui/icons-material";
import { getULDomain } from "../../../../utils";
import config from "../../../../config";
import NotiBell from "./NotiBell";


function AccountSwitcher({ clientName }) {
  const classes = "useStyles()";
  const [openAcc, setOpenAcc] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = useSelector((state) => state.auth.aToken);
  const sd = useSelector((state) => state.auth.subDomain);
  const accounts = useSelector((state) => state.auth.accounts);

  const notiClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenAcc((prev) => !prev);
  };

  const getAccURL = (cn) =>
    process.env.REACT_APP_STAGING
      ? `${
          window.location.protocol
        }//${cn}.${getULDomain()}/staging/home/${token}`
      : `${window.location.protocol}//${cn}.${getULDomain()}/home/${token}`;

  return (
    <>
      <Typography
        className={"client-name"}
        variant="h6"
        onClick={notiClick}
      >
        {clientName}
        {openAcc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Typography>
      <Popper
        className="acc-popper"
        open={openAcc}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Card className="list_paper">
              <List className="settings_list">
                {accounts.map((acc, a) => {
                  return (
                    <ListItem
                      key={a}
                      className={`list_item ${
                        sd === acc.domain ? "active" : ""
                      }`}
                      component="a"
                      href={getAccURL(acc.domain)}
                      target="_blank"
                    >
                      <ListItemText
                        primary={<Typography>{acc.name}</Typography>}
                        secondary={
                          <Typography variant="caption">
                            {config.userTypes[acc.user_type]}
                          </Typography>
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Card>
          </Fade>
        )}
      </Popper>
    </>
  );
}

const Topbar = (props) => {
  const {
    isAuthenticated,
    loading,
    staffname,
    clientName,
    className,
    onSidebarOpen,
    logout,
    ...rest
  } = props;

  const classes = "useStyles()";

  const authLinks = (
    <div className={"user-identity"}>
      <Typography  className="staff-name" variant="h6">
        {staffname ? staffname : "Admin"}
      </Typography>
      {clientName ? <AccountSwitcher clientName={clientName} /> : null}
      <NotiBell />
      <Tooltip title="Logout">
        <IconButton
          className={classes.signOutButton}
          color="inherit"
          onClick={logout}
        >
          <PowerSettingsNewIcon />
        </IconButton>
      </Tooltip>
    </div>
  );

  return (
    <Fragment>
      <AppBar
        {...rest} 
        classes={clsx(className)}
        className="topbar-main"
        >
        <Toolbar>
          <Hidden lgUp>
            <IconButton
              className={"hamburger"}
              color="inherit"
              onClick={onSidebarOpen}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <RouterLink to="/dashboard">
            <Box
              component="img"
              className="logo_bdr"
              style={{ height: 37 }}
              src="/fl-logo-w.png"
            />
          </RouterLink>
          <div
          style={{ flexGrow: 1}}
           />
          {!loading && (isAuthenticated ? authLinks : null)}
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  staffname: state.auth.staffname,
  clientName: state.auth.clientName,
});

export default connect(mapStateToProps, { logout })(Topbar);
