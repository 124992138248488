import { combineReducers } from 'redux';
import alert from './modules/alert';
import auth from './modules/auth';
import settings from './modules/settings';
import staffs from './modules/staffs';
import forms from './modules/forms';
import leads from './modules/leads';
import deals from './modules/deals';
import email from './modules/email';
import dashboard from './modules/dashboard';
import noauth from './modules/noauth';
import manage from './modules/manage';

export default combineReducers({
    alert,
    auth,
    settings,
    staffs,
    forms,
    leads,
    deals,
    email,
    dashboard,
    noauth,
    manage
});
