import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";

const FollowUpEmailInput = (props) => {
  const { lead, allEmails, emailField, setEmailField, validateEmail } = props;
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (inputValue && inputValue.endsWith(",")) {
      if (validateEmail(inputValue.slice(0, -1))) {
        setEmailField(emailField.concat(inputValue.slice(0, -1)));
        setInputValue("");
      }
    }
  }, [inputValue]);

  return (
    <>
      <Autocomplete
        size="small"
        multiple
        freeSolo
        filterSelectedOptions
        id="tags-outlined"
        options={[...(lead.emails || []), ...allEmails]}
        value={emailField}
        onChange={(e, newval) => {
          setEmailField(newval);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            variant="outlined"
            {...params}
            inputProps={{ ...params.inputProps, value: inputValue }}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                e.stopPropagation();

                if (e.target.value && validateEmail(e.target.value)) {
                  setEmailField(emailField.concat(e.target.value));
                  setInputValue("");
                }
              } else if (
                e.key === "Backspace" &&
                inputValue === "" &&
                emailField.length
              ) {
                setEmailField(emailField.slice(0, -1));
              }
            }}
          />
        )}
        onBlur={(event) => {
          if (inputValue && inputValue.endsWith(",")) {
            if (validateEmail(inputValue.slice(0, -1))) {
              setEmailField(emailField.concat(inputValue.slice(0, -1)));
              setInputValue("");
            }
          } else {
            if (validateEmail(inputValue)) {
              setEmailField(emailField.concat(inputValue));
              setInputValue("");
            }
          }
        }}
      />
    </>
  );
};

export default FollowUpEmailInput;
