import React, { PureComponent } from "react";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import {
  Dialog,
  DialogContent,
  NativeSelect,
  Button,
  LinearProgress,
  Typography,
  FormControl,
  Divider,
} from "@mui/material";
import {
  getPriorityList,
  getStatusList,
  getPriorityTimes,
  getPriorityMappings,
  updatePriorityMappings,
} from "../../../redux/modules/settings";
import DialogTitle from "../../../components/DialogTitle";

function isPriorityMappingsEqual(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((arr, index) => {
      const row = b[index];

      return (
        Array.isArray(arr) &&
        Array.isArray(row) &&
        arr.length === row.length &&
        arr.every((o, i) => o === row[i])
      );
    })
  );
}

function isNotMapped(m) {
  const notMapped = m.filter((i) => !!i.filter((p) => !p).length);

  return !!notMapped.length;
}

const styles = {
  buttonContainer: {
    textAlign: "left",
    marginTop: 15,
  },
  titleCap: {
    marginLeft: 10,
  },
  errorShow: {
    marginLeft: 10,
    color: "#FF0000",
  },
};

const tableOptions = {
  filter: false,
  print: false,
  search: false,
  sort: false,
  download: false,
  pagination: false,
  viewColumns: false,
  selectableRows: "none",
  textLabels: {
    body: {
      noMatch: "",
    },
  },
};

// The formating method to format the raw data from API to input in MUI-DataTable component.
function formatData(priorityMappings, statusList, priorityTimes, timeUnits) {
  if (priorityMappings.length && statusList.length && priorityTimes.length) {
    return priorityTimes.map((times, k) => {
      const pMap = priorityMappings[k] || {};
      const mainData = statusList
        .map((status) => {
          return (
            pMap.mapped &&
            pMap.mapped.find((item) => item.status_id === status.status_id)
          );
        })
        .map((obj) => (obj && obj.priority_id) || "");

      return [times.time_value + " " + timeUnits[times.time_unit], ...mainData];
    });
  }

  return [];
}

function formatForAPI(priorityMappings, statusList, priorityTimes, timeUnits) {
  return priorityMappings.map((pMap) => {
    const timeData = priorityTimes.find(
      (t) => t.time_value + " " + timeUnits[t.time_unit] === pMap[0]
    );

    return {
      time_id: timeData.time_id,
      mapped: statusList.map((s, i) => {
        return {
          status_id: s.status_id,
          priority_id: pMap[i + 1],
        };
      }),
    };
  });
}

class PriorityMappingDialog extends PureComponent {
  state = {
    priorityMappings: formatData(
      this.props.priorityMappings,
      this.props.statusList,
      this.props.priorityTimes,
      this.props.timeUnits
    ),
    disableSave: true,
  };

  componentDidUpdate(preProps) {
    const { open, priorityList, statusList, priorityTimes, priorityMappings } =
      this.props;
    if (open && !preProps.open) {
      const initialData = formatData(
        priorityMappings,
        statusList,
        priorityTimes,
        this.props.timeUnits
      );

      if (!isPriorityMappingsEqual(initialData, this.state.priorityMappings)) {
        this.setState({ priorityMappings: initialData, disableSave: true });
      }

      let apiCalls = [];

      if (!priorityList.length) {
        apiCalls.push(this.props.fetchPriorityList());
      } else {
        apiCalls.push(Promise.resolve({ result: this.props.priorityList }))
      }

      if (!statusList.length) {
        apiCalls.push(this.props.fetchStatusList());
      } else {
        apiCalls.push(Promise.resolve({ result: this.props.statusList }))
      }

      if (!priorityTimes.length) {
        apiCalls.push(this.props.fetchPriorityTimes());
      } else {
        apiCalls.push(Promise.resolve({ result: this.props.priorityTimes }))
      }

      apiCalls.push(this.props.fetchPriorityMappings());

      Promise.all(apiCalls).then((res) => {
        this.setState({
          priorityMappings: formatData(
            res[3],
            res[1].result,
            res[2].result,
            this.props.timeUnits
          ),
        });
      });
    }
  }

  handlePriorityMapChange = (e, { rowIndex, columnIndex }) => {
    const fullData = this.state.priorityMappings;
    const rData = fullData[rowIndex];
    const newData = [
      ...fullData.slice(0, rowIndex),
      [
        ...rData.slice(0, columnIndex),
        e.target.value,
        ...rData.slice(columnIndex + 1),
      ],
      ...fullData.slice(rowIndex + 1),
    ];
    const isEqual = isPriorityMappingsEqual(
      formatData(
        this.props.priorityMappings,
        this.props.statusList,
        this.props.priorityTimes,
        this.props.timeUnits
      ),
      newData
    );
    const isEmpty = isNotMapped(newData);

    this.setState({
      priorityMappings: newData,
      disableSave: isEqual || isEmpty,
    });
  };

  onClose = () => {
    this.setState({
      priorityMappings: formatData(
        this.props.priorityMappings,
        this.props.statusList,
        this.props.priorityTimes,
        this.props.timeUnits
      ),
      disableSave: true,
    });
    this.props.onClose();
  };

  saveMappings = () => {
    this.props
      .updatePriorityMappings(
        formatForAPI(
          this.state.priorityMappings,
          this.props.statusList,
          this.props.priorityTimes,
          this.props.timeUnits
        )
      )
      .then(() => {
        this.setState({ disableSave: true });
      });
  };

  render() {
    const columns = [
      {
        name: "",
        label: "Time Period",
        options: {
          setCellProps: () => ({
            style: {
              whiteSpace: "nowrap",
              position: "sticky",
              left: "0",
              background: "white",
              fontWeight: "bold",
              zIndex: 100,
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: "nowrap",
              position: "sticky",
              left: 0,
              background: "white",
              color: "green",
              zIndex: 101,
            },
          }),
        },
      },
      ...this.props.statusList.map((status, i) => {
        return {
          name: status.name,
          label: status.name,
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <FormControl error={!value}>
                  <NativeSelect
                    value={value}
                    onChange={(e) => this.handlePriorityMapChange(e, tableMeta)}
                  >
                    <option value="">-- Select --</option>
                    {this.props.priorityList.map((priority, j) => (
                      <option key={j} value={priority.priority_id}>
                        {priority.priority_label}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              );
            },
          },
        };
      }),
    ];

    return (
      <Dialog
        onClose={this.onClose}
        aria-labelledby="customized-dialog-title"
        open={this.props.open}
        maxWidth={false}
        scroll="body"
      >
        <DialogTitle id="customized-dialog-title" onClose={this.onClose}>
          Edit Priority Mapping
          <Typography variant="caption" style={styles.titleCap}>
            ( Priority Time X Lead Statuses )
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {this.props.loading ? <LinearProgress /> : null}
          <MUIDataTable
            options={tableOptions}
            columns={columns}
            data={this.state.priorityMappings}
          />
          <div style={styles.buttonContainer}>
            <Button
              variant="contained"
              onClick={this.saveMappings}
              color="primary"
              disabled={this.state.disableSave}
              autoFocus
            >
              Save Changes
            </Button>
            {isNotMapped(this.state.priorityMappings) ? (
              <Typography variant="caption" style={styles.errorShow}>
                Please fill the empty values
              </Typography>
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  priorityMappings: state.settings.priorityMappings,
  priorityList: state.settings.priorityList,
  statusList: state.settings.statusList,
  priorityTimes: state.settings.priorityTimes,
  timeUnits: state.settings.timeUnits,
  loading: state.settings.loading,
});

export default connect(mapStateToProps, {
  fetchPriorityMappings: getPriorityMappings,
  fetchPriorityList: getPriorityList,
  fetchStatusList: getStatusList,
  fetchPriorityTimes: getPriorityTimes,
  updatePriorityMappings,
})(PriorityMappingDialog);
