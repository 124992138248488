import {
  Divider,
  LinearProgress,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Pagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAForm, setFormlistPage, getAllFormsList } from '../../../redux/modules/forms';
import DialogTitle from "../../../components/DialogTitle";

function CreateLead() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const forms = useSelector((state) => state.forms.formsList);
  const formData = useSelector((state) => state.forms.formData);
  const styleData = useSelector((state) => state.forms.styleData);
  const [selectedForm, setSelectedForm] = useState("");
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState(65);
  const formPage = useSelector((state) => state.forms.formPage);
  const formTotal = useSelector((state) => state.leads.totalForms);

  const contentFrame = React.useRef();

    useEffect(() => {
        if (open) {
            dispatch(getAllFormsList()); 
        } else {
            dispatch(setFormlistPage(0));
        }
    }, [open]);

    const onAddClick = () => {
        setOpen(true);
    }

  const onClose = () => {
    setOpen(false);
    setSelectedForm("");
    setHeight(55);
  };

    const setFrameHeight = () => {
        setHeight((contentFrame.current && contentFrame.current.contentWindow.document.body.clientHeight || 0) + 90);
    }

    const onFormChange = (e) => {
        const selectValue = e.target.value === "select";
        setSelectedForm(selectValue ? "" : e.target.value);
        setHeight(6);

        if (!selectValue) {
            setLoading(true);
            dispatch(getAForm(e.target.value, "leads")).then(() => {
                setLoading(false);
            });
        } else {
            setHeight(55); 
        }
    }

    const onFrameLoad = () => {
        setFrameHeight();
    };

    const onFormPageChange = (e, value) => {
        e.stopPropagation();
        dispatch(setFormlistPage(value - 1));
        dispatch(getAllFormsList());
    }

    return (
        <>
            <Button variant="contained" className="btn-style" color="primary" disableElevation onClick={onAddClick} >
                Create Lead
            </Button>
            <Dialog open={open} onClose={onClose} scroll="body" >
                <DialogTitle style={{fontSize: 18, fontWeight: 500}} onClose={onClose} >
                    Create a lead
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <FormControl variant="outlined" color="primary" className="selectStyle" >
                        <Select 
                          size="small" 
                          value={selectedForm || "select"} 
                          margin="dense" 
                          onChange={onFormChange} 
                          style={{ minWidth: 150}} 
                          MenuProps={{
                            transformOrigin: {
                                horizontal: "left"
                            },
                            sx: {
                                marginLeft:-11.5
                            }
                          }}
                           >
                            <MenuItem value="select" >----- Select a form -----</MenuItem>
                            {
                                forms.map((form, f) => {
                                    const formName = `${form.title.slice(0,24)}${form.title.length > 24 ? "..." : ""}`;

                                    return <MenuItem key={f} value={form.id} >{formName}</MenuItem>;
                                })
                            }
                            <ListSubheader>
                                <Pagination
                                    color="primary"
                                    variant="outlined"
                                    count={Math.ceil(formTotal/10)}
                                    page={formPage + 1}
                                    onChange={onFormPageChange}
                                    siblingCount={0}
                                    boundaryCount={1}
                                />
                            </ListSubheader>
                        </Select>
                    </FormControl>
                    <div className='lead-form-container' style={{ height }} >
                        {
                            selectedForm && !loading
                            ? <iframe onLoad={onFrameLoad} style={{ height: "100%" }} ref={contentFrame} className='form-frame' srcDoc={`<html>${styleData}<body>${formData}</body></html>`}></iframe>
                            : (
                                loading
                                ? <LinearProgress />
                                : <Typography variant='caption' >Select a form above to show...</Typography>
                            )
                        }
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default CreateLead;
