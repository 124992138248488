import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  TextField,
  Typography,
  Divider,
  Collapse,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCommuniEmail,
  getCommuniEmail,
} from "../../../redux/modules/auth";
import { validateEmail } from "../../../utils/validators";
import DialogTitle from "../../../components/DialogTitle";

function ChangeProfile() {
  const dispatch = useDispatch();
  const emailId = useSelector((state) => state.auth.communicationEmail);
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);

  const onChangeEmailClick = () => {
    setOpen(true);
    setEmail("");
    setLoading(true);
    dispatch(getCommuniEmail())
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onClose = () => {
    setOpen(false);
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onEmailSave = () => {
    setLoading(true);
    dispatch(changeCommuniEmail(email)).then((res) => {
      if (res.status === 1) {
        setOpen(false);
        //    setChanged(true);
      }

      setLoading(false);
    });
  };

  // const onOkClick = () => {
  //     setOpen(false);
  // }

  const disableSave = !validateEmail(email) || email === emailId;

  return (
    <>
      <Card className="list_paper">
        <List className="settings_list">
          <ListItem className="list_item">
            <ListItemText
              primary={<Typography>Communication Email</Typography>}
              secondary={
                <Typography variant="caption">
                  You can change profile communication email id here.
                </Typography>
              }
            />
            <Button
              autoFocus
              onClick={onChangeEmailClick}
              color="primary"
              className="sec-button"
            >
              Change
            </Button>
          </ListItem>
        </List>
      </Card>
      <Dialog
        open={open}
        onClose={onClose}
        className="email-replace-dialog"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle onClose={onClose}>
          Change Communication Email ID
        </DialogTitle>
        <Divider />
        <DialogContent>
          {/* <Collapse in={!changed} > */}
          <div className="emailDataWrapper">
            <Typography
              className="email-r-form-label"
              variant="h6"
              component="label"
              color="primary"
            >
              Email id
            </Typography>
            {emailId ? (
              <Typography>{emailId}</Typography>
            ) : (
              <Typography
                className="empty-message"
                component="div"
                variant="caption"
              >
                Not set yet...
              </Typography>
            )}
          </div>
          <Typography
            className="email-r-form-label"
            variant="h6"
            color="primary"
            component="label"
          >
            Change to
          </Typography>
          <TextField
            size="small"
            className="email-replace-input"
            margin="dense"
            variant="outlined"
            placeholder={emailId}
            value={email}
            onChange={onEmailChange}
          />
          {/* </Collapse>
                    <Collapse in={changed} >
                        <Typography className="email_change_message" >
                            An email has been sent to <b>{emailId}</b>. Please check your inbox for the confirmation email and confirm to change the email id to <b>{email}</b>.
                        </Typography>
                        <div className="center-text" >
                            <Button variant="contained" onClick={onOkClick} color="primary" >Ok</Button>
                        </div>
                    </Collapse> */}
          {loading ? <LinearProgress /> : null}
          <Button
            className="email-update-btn"
            variant="contained"
            color="primary"
            onClick={onEmailSave}
            disabled={disableSave}
          >
            Update
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ChangeProfile;
