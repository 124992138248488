const multipliers = {
  1: 1,
  2: 60,
  3: 3600,
  4: 86400,
  5: 604800,
  6: 2592000,
  7: 31104000,
};

export function getSeconds(timeValue, timeUnit) {
  return +timeValue * multipliers[timeUnit];
}

export function secondsToUnit(timeValue, timeUnit) {
  return +timeValue / multipliers[timeUnit];
}

export function convertTime(timeValueInSecs) {
  var seconds = Number(timeValueInSecs);
  var d = Math.floor(seconds / (3600*24));
  var h = Math.floor(seconds % (3600*24) / 3600);
  var m = Math.floor(seconds % 3600 / 60);
  
  var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days") + (h > 0 ? ", " : "") : "";
  var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours") + (m > 0 ? ", " : "") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " minute" : " minutes") : "";
  
  return dDisplay + hDisplay + mDisplay; 
}
