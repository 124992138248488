import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  Dialog,
  Button,
  LinearProgress,
  Divider,
  TextField,
  IconButton,
  Select,
  MenuItem,
  Collapse,
  Tooltip,
  DialogContent,
} from "@mui/material";
import {
  getLeadDetails,
  setActiveLead,
  updateLeadData,
} from "../../../redux/modules/leads";
import getAccessFor from "../../../helpers/getAccessFor";
import { maskEmail } from "../../../utils";
import {
  getAContactForm,
  resetContactForm,
} from "../../../redux/modules/forms";
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import DialogTitle from "../../../components/DialogTitle";

function LeadFields({ leadData, contact, field }) {
  const dispatch = useDispatch();
  const formId = useSelector((state) => state.leads.selectedFormId);
  let value = leadData[field.label];
  const [edit, setEdit] = useState(false);
  const [fieldValue, setFValue] = useState(value);
  const [loading, setLoading] = useState(false);

  const onFVChange = (e) => {
    setFValue(e.target.value);
  };

  const onFVSChange = (e) => {
    setFValue(e.target.value === "select" ? "" : e.target.value);
  };

  const onEditClick = () => {
    setEdit(true);
  };

  const onSaveField = () => {
    setLoading(true);
    dispatch(
      updateLeadData(
        {
          form_id: leadData.form_id,
          lead_id: leadData.lead_id,
          fields_edit: [{ field_id: field.field_id, field_value: fieldValue }],
        },
        field.field_name === "name"
      )
    )
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    setEdit(false);
  };

  const onCloseField = () => {
    setEdit(false);
  };

  if (field.type === "email") {
    if (!getAccessFor("view_full_email_id") && value && !contact) {
      value = maskEmail(value);
    }
  }

  return (
    <tr className="lead-details">
      <td>
        <Typography className="lead-key" variant="h6">
          {field.label}
        </Typography>
      </td>
      <td>
        <div className="lf-value">
          {edit ? (
            field.type === "select" ? (
              <Select
                className="lv-input"
                onChange={onFVSChange}
                value={fieldValue || "select"}
              >
                <MenuItem value="select">#select</MenuItem>
                {field.options.map((ff) => (
                  <MenuItem value={ff}>{ff}</MenuItem>
                ))}
              </Select>
            ) : (
              <TextField
                size="small"
                className="lv-input"
                value={fieldValue}
                onChange={onFVChange}
                variant="outlined"
                multiline
              />
            )
          ) : (
            <Typography className="lead-value">{value}</Typography>
          )}
          {/* {
                        loading
                        ? <LinearProgress />
                        : null
                    } */}
          {field.editable === "1" && !contact ? (
            edit ? (
              <div className="saveCloseBtns">
                <IconButton onClick={onSaveField}>
                  <SaveIcon fontSize="small" />
                </IconButton>
                <IconButton onClick={onCloseField}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            ) : (
              <IconButton onClick={onEditClick}>
                <EditIcon fontSize="small" />
              </IconButton>
            )
          ) : null}
        </div>
      </td>
    </tr>
  );
}

export default function LeadDetails(props) {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const leadDetails = useSelector((state) => state.leads.leadDetails) || {};
  const leadData = { ...props.leadDetails, ...leadDetails };
  // const fields = useSelector(state => state.forms.fields) || [];
  const contactFields = useSelector((state) => state.forms.contactFields);
  const accountsList = useSelector((state) => state.leads.accounts);

  const fieldsUsed = contactFields;

  useEffect(() => {
    if (leadData.form_id && open) {
      setIsLoading(true);
      dispatch(getAContactForm(leadData.form_id)).then(() => {
        if (!props.contact) {
          dispatch(getLeadDetails(props.leadId))
            .then(() => {
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
            });
        } else {
          setIsLoading(false);
        }
      });
    }
  }, [open]);

  const handleOpenDetails = () => {
    setOpen(true);
    if (props.leadId) {
      dispatch(setActiveLead(props.leadId));
    }
  };

  const onClose = () => {
    if (props.leadId) {
      dispatch(setActiveLead(""));
    }
    setOpen(false);
    setTimeout(() => {
      dispatch(resetContactForm());
    }, 400);
  };

  const account =
    accountsList.find((acc) => acc.account_id === leadData.account_id) || {};

  return (
    <div>
      <Button
        className="bdr-btn-style-green"
        variant="outlined"
        color="primary"
        onClick={handleOpenDetails}
      >
        Details
      </Button>
      <Dialog
        open={open}
        onClose={onClose}
        scroll="body"
        maxWidth="xs"
        fullWidth
      >
        {isLoading ? (
          <DialogContent>
            <LinearProgress style={{ margin: 3 }} />
          </DialogContent>
        ) : (
          <>
            <DialogTitle style={{fontSize: 18, fontWeight: 500}} onClose={onClose}>
              {props.contact ? "CONTACT" : "LEAD"} DETAILS
            </DialogTitle>
            <Divider />
            <div style={{ minWidth: 400, padding: 15, paddingTop: 0 }}>
              <div className="lead-details-heading">
                <Typography variant="caption" style={{ fontSize: 11 }}>
                  {leadData.contact_id
                    ? `Contact ID: ${leadData.contact_id}`
                    : `Lead ID: ${leadData.lead_id}`}
                </Typography>
                {leadData.lead_id && leadData.contact_id ? (
                  <Typography
                    style={{ fontSize: 11 }}
                    variant="caption"
                    className="ld-snip"
                  >{`Lead ID: ${leadData.lead_id}`}</Typography>
                ) : null}
              </div>
              <div className="lead-main-data">
                {leadData.lead_id && !props.contact ? (
                  <>
                    <div className="lead-main-wrapper">
                      <Typography className="lead-head fnt19" variant="h6">
                        Name
                      </Typography>
                      <Typography className="lead-data">
                        {leadData.name || "NA"}
                      </Typography>
                    </div>
                    <Divider
                      orientation="vertical"
                      className="dvdr-color"
                      flexItem
                    />
                  </>
                ) : null}
                {props.contact ? null : (
                  <div className="lead-main-wrapper">
                    <Typography className="lead-head fnt19" variant="h6">
                      Priority
                    </Typography>
                    <Typography className="lead-data">
                      {leadData.priority_label}
                    </Typography>
                  </div>
                )}
                {props.contact && leadData.contact_name ? (
                  <>
                    <div className="lead-main-wrapper">
                      <Typography className="lead-head fnt19" variant="h6">
                        Contact Name
                      </Typography>
                      <Typography
                        className="lead-data fnt19 contact__name"
                        varient="h6"
                      >
                        {leadData.contact_name}
                      </Typography>
                    </div>
                  </>
                ) : null}
                {props.contact && leadData.account_id ? (
                  <>
                    <Divider
                      orientation="vertical"
                      className="dvdr-color"
                      flexItem
                    />
                    <div className="lead-main-wrapper">
                      <Typography className="lead-head fnt19" variant="h6">
                        Account
                      </Typography>
                      <Typography
                        className="lead-data fnt19 contact__name"
                        varient="h6"
                      >
                        {account.name}
                      </Typography>
                    </div>
                  </>
                ) : null}
              </div>
              <table className="lead-list" cellPadding={0} cellSpacing={0}>
                <tbody>
                  {props.contact && leadData.emails.length ? (
                    <tr className="lead-details">
                      <td>
                        <Typography className="lead-key" variant="h6">
                          Contact Emails
                        </Typography>
                      </td>
                      <td>
                        <div className="lf-value">
                          <Typography className="lead-value" varient="h6">
                            {leadData.emails.join(", ")}
                          </Typography>
                        </div>
                      </td>
                    </tr>
                  ) : null}
                  {props.contact &&
                  leadData.mobiles &&
                  leadData.mobiles.length ? (
                    <tr className="lead-details">
                      <td>
                        <Typography className="lead-key" variant="h6">
                          Contact Numbers
                        </Typography>
                      </td>
                      <td>
                        <div className="lf-value">
                          <Typography className="lead-value" varient="h6">
                            {leadData.mobiles.join(", ")}
                          </Typography>
                        </div>
                      </td>
                    </tr>
                  ) : null}
                  {fieldsUsed.map((field, i) => (
                    <LeadFields
                      key={i}
                      leadData={leadData}
                      contact={props.contact}
                      field={field}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </Dialog>
    </div>
  );
}
