import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  Button,
  LinearProgress,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import {
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
  AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";
import DialogTitle from "../../../components/DialogTitle";
import {
  getLeadNotes,
  postLeadNotes,
  resetLeadNotes,
  setActiveLead,
  updateLastActivity,
} from "../../../redux/modules/leads";
import format_date from "../../../utils/format_date";
import AccessControl from "../../../components/AccessControl";
import getAccessFor from "../../../helpers/getAccessFor";
import config from "../../../config";

function NoteText({ item }) {
  const [readM, setReadM] = useState(false);
  const noteContent = readM ? item.lead_note : item.lead_note.slice(0, 240);
  const isLong = item.lead_note.length > 240;
  const readMore =
    isLong && !readM ? (
      <>
        ...{" "}
        <span className="read-more-btn" onClick={() => setReadM(true)}>
          Read more
        </span>
      </>
    ) : isLong ? (
      <span className="read-more-btn" onClick={() => setReadM(false)}>
        {" "}
        Read less
      </span>
    ) : null;

  return (
    <Typography className="note-text">
      {noteContent}
      {readMore}
    </Typography>
  );
}

const Notes = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [note, setNote] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const lnCount = useSelector((state) => state.leads.noteCount);
  const notesList = useSelector((state) => state.leads.notesList);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      dispatch(
        getLeadNotes(
          props.leadId,
          props.contactId,
          config.leadNotes.limit,
          page - 1
        )
      ).then(() => {
        setIsLoading(false);
      });
    }
  }, [page]);

  const handleNoteClick = () => {
    setIsLoading(true);
    setOpen(true);
    if (props.leadId) {
      dispatch(setActiveLead(props.leadId));
    }

    dispatch(
      getLeadNotes(
        props.leadId,
        props.contactId,
        config.leadNotes.limit,
        page - 1
      )
    ).then(() => {
      setIsLoading(false);
    });
  };

  const handleClose = () => {
    if (props.leadId) {
      dispatch(setActiveLead(""));
    }
    setOpen(false);
    setNote("");
    dispatch(resetLeadNotes());
  };

  const onSaveNote = () => {
    setIsLoading(true);
    dispatch(
      postLeadNotes(
        props.leadId,
        props.contactId,
        note,
        "",
        props.leadName,
        config.leadNotes.limit,
        page - 1
      )
    ).then(() => {
      setNote("");
      setIsLoading(false);
      dispatch(
        updateLastActivity(
          props.contactId ? null : props.leadId,
          props.contactId,
          "New note added"
        )
      );
    });
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const prePage = () => {
    setPage(page - 1);
  };

  const onNoteChange = (e) => {
    setNote(e.target.value);
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleNoteClick}
        className="bdr-btn-style-blue"
      >
        Notes
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        scroll="body"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {props.contact
            ? `Notes of contact ${props.contactId}`
            : `Notes of Lead ${props.leadId}`}
        </DialogTitle>
        {getAccessFor("create_notes") ||
        (props.contact && getAccessFor("contact_notes_add")) ? (
          <DialogContent dividers>
            <textarea
              className="add-note"
              placeholder="Enter note"
              value={note}
              onChange={onNoteChange}
            ></textarea>
            <div className="add-note-button">
              <Button
                autoFocus
                onClick={onSaveNote}
                variant="contained"
                color="primary"
                disabled={!note || isLoading}
              >
                Add Note
              </Button>
            </div>
          </DialogContent>
        ) : null}
        <DialogContent>
          {isLoading ? <LinearProgress /> : null}
          <div className="notes-list-container">
            {notesList.length ? (
              notesList.map((item, i) => (
                <div className="note-item" key={i}>
                  <div className="leadcardbg">
                    <NoteText key={item.created_time} item={item} />
                  </div>
                  <div className="note-bottom-panel">
                    <Typography variant="caption">
                      {format_date(item.created_time)}
                    </Typography>
                    <div className="notes-owner-sec">
                      <AccountCircleIcon
                        color="primary"
                        className="notes-owner-img"
                      />
                      <Typography variant="caption">{item.user}</Typography>
                    </div>
                  </div>
                </div>
              ))
            ) : !isLoading ? (
              <Typography className="no-info">No notes yet.</Typography>
            ) : null}
            {notesList.length ? (
              <>
                <Divider />
                <div className="dn-nav-cont">
                  <Typography variant="caption">
                    {page}/{Math.ceil(lnCount / 3)}
                  </Typography>
                  <div className="dn-nav-wrapper">
                    <IconButton onClick={prePage} disabled={page === 1}>
                      <NavigateBeforeIcon />
                    </IconButton>
                    <IconButton
                      onClick={nextPage}
                      disabled={!(lnCount - page * config.dNotes.limit > 0)}
                    >
                      <NavigateNextIcon />
                    </IconButton>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Notes;
