import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  LinearProgress,
  Paper,
  Chip,
  TextField,
  Tooltip,
  Typography,
  IconButton,
  Collapse,
  Select,
  MenuItem,
  DialogContentText,
  DialogActions,
  Autocomplete,
  Popover,
} from "@mui/material";
import {
  Send as SendIcon,
  CheckCircle as CheckCircleIcon,
  RotateLeft as RotateLeftIcon,
  Drafts as DraftsIcon,
  FileCopyTwoTone as FileCopyTwoToneIcon,
  EditTwoTone as EditTwoToneIcon,
  AddCircleTwoTone as AddCircleTwoToneIcon,
  DeleteForeverOutlined as DeleteForeverOutlinedIcon,
  SaveAltOutlined as SaveAltOutlinedIcon,
  ViewList as ViewListIcon,
  Email as EmailIcon,
  SaveOutlined as SaveOutlinedIcon,
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
  Schedule as ScheduleIcon,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  getTemplateVariables,
  postBTemplate,
  postTempVar,
  sendFUMail,
  setEBDraft,
  updateTempVar,
  updateBTemplate,
  removeTempVar,
  deleteBTemplate,
} from "../../../redux/modules/email";
import DialogTitle from "../../../components/DialogTitle";
import config from "../../../config";
import { updateLastActivity } from "../../../redux/modules/leads";
import { validateEmail } from "../../../utils/validators";
import {
  makeFirstLetterCaps,
  normalizeHtml,
  truncateString,
} from "../../../utils";
import ContentEditable from "react-contenteditable";
import { Link } from "react-router-dom";
import { getCurrentDatetime, getUTCDatetime } from "../../../utils/format_date";
import FollowUpEmailInput from "./FollowUpEmailInput";
const withoutWhiteSpaces = /\s/;

function TempVarItem({ varItem, load, setLoading, loading, setCopiedVar }) {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [varData, setVarData] = useState({
    var_id: varItem.tb_id,
    var_name: varItem.var_name || "",
    var_value: varItem.var_value || "",
  });
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setVarData({
      var_id: varItem.tb_id,
      var_name: varItem.var_name || "",
      var_value: varItem.var_value || "",
    });
  }, [varItem]);

  const scrollToRef = useRef();
  const copyRef = useRef();

  useEffect(() => {
    if (copyRef.current) {
      clearTimeout(copyRef.current);
    }

    if (copied) {
      copyRef.current = setTimeout(() => {
        clearTimeout(copyRef.current);
        setCopied(false);
      }, 3000);
    }

    return () => {
      clearTimeout(copyRef.current);
    };
  }, [copied]);

  const onEditClick = () => {
    setEdit(true);
    let scrollTimeout = setTimeout(() => {
      scrollToRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
      clearTimeout(scrollTimeout);
    }, 300);
  };

  const onCancel = () => {
    setEdit(false);
    setVarData({ ...varData, var_value: varItem.var_value || "" });
  };

  const onValueChange = (name) => (el) => {
    setVarData({ ...varData, [name]: el.target.value });
    let scrollTimeout = setTimeout(() => {
      scrollToRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
      clearTimeout(scrollTimeout);
    }, 300);
  };

  const onSaveUpdate = () => {
    setLoading(true);
    dispatch(updateTempVar(varData))
      .then(() => {
        setLoading(false);
        setEdit(false);
        load();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onDelete = () => {
    setLoading(true);
    dispatch(removeTempVar(varItem.tb_id))
      .then(() => {
        setLoading(false);
        setEdit(false);
        load();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onCopying = () => {
    navigator.clipboard.writeText(`{${varItem.var_name}}`);
    setCopiedVar(varItem.var_name);
    setCopied(true);
  };

  return (
    <div className="temp_var_container">
      <div className="temp_var_item">
        <div className="var_data">
          <Tooltip title={varItem.var_name}>
            <Typography>{`{${truncateString(
              varItem.var_name,
              20
            )}}`}</Typography>
          </Tooltip>
        </div>
        <div className="var_tools">
          <Tooltip title="Edit Variable">
            <IconButton
              style={{ padding: 0, marginRight: 6 }}
              onClick={onEditClick}
            >
              <EditTwoToneIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={copied ? "Copied" : "Copy Variable"}>
            <IconButton
              className="copy_variable_btn"
              style={{ padding: 0 }}
              onClick={onCopying}
            >
              <FileCopyTwoToneIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <Collapse in={edit}>
        <div className="variable_input_wrapper">
          {/* <TextField placeholder='Variable Name' value={varData.var_name} onChange={onValueChange('var_name')} helperText="Variable name should be without white spaces." /> */}
          <Divider />
          <TextField
            variant="standard"
            placeholder="Variable Value"
            value={varData.var_value}
            onChange={onValueChange("var_value")}
            maxRows={6}
            multiline
          />
          <div className="var_save_wrapper update">
            <div className="var_save_btns">
              <Button color="primary" onClick={onCancel}>
                cancel
              </Button>
              <Divider orientation="vertical" flexItem />
              <Button
                ref={scrollToRef}
                color="primary"
                onClick={onSaveUpdate}
                disabled={
                  loading ||
                  !varData.var_value ||
                  varItem.var_value === varData.var_value
                }
              >
                update
              </Button>
            </div>
            <IconButton style={{ padding: 0 }} onClick={onDelete}>
              <DeleteForeverOutlinedIcon color="error" fontSize="small" />
            </IconButton>
          </div>
        </div>
      </Collapse>
    </div>
  );
}

function TempVars({ vars, load, setLoading, loading, setCopiedVar, rerender }) {
  const dispatch = useDispatch();
  const [add, setAdd] = useState(false);
  const [varData, setVarData] = useState({ var_name: "", var_value: "" });
  const [maxHeight, setMaxHeight] = useState(200);

  const onAddClick = () => {
    setMaxHeight(150);
    setAdd(true);
  };

  const onCancel = () => {
    setMaxHeight(200);
    setAdd(false);
    setVarData({ var_name: "", var_value: "" });
  };

  const onValueChange = (name) => (el) => {
    rerender();
    setVarData({ ...varData, [name]: el.target.value });
  };

  const onAddNewVar = () => {
    setLoading(true);
    dispatch(postTempVar(varData))
      .then(() => {
        setLoading(false);
        setMaxHeight(200);
        setAdd(false);
        setVarData({ var_name: "", var_value: "" });
        load();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ width: 250 }}>
      <Collapse in={!add}>
        <div
          style={{
            padding: 15,
            maxHeight,
            overflow: "auto",
            transition: "max-height 1s cubic-bezier(0.82, 0.93, 0.55, 0.95) 0s",
          }}
        >
          {vars.length ? (
            vars.map((tv, v) => (
              <TempVarItem
                key={v}
                varItem={tv}
                load={load}
                setLoading={setLoading}
                loading={loading}
                setCopiedVar={setCopiedVar}
              />
            ))
          ) : (
            <Typography variant="caption">No variables available...</Typography>
          )}
        </div>
        <Divider />
        <div style={{ textAlign: "center", paddingBottom: 15, paddingTop: 10 }}>
          <Tooltip title="Add a variable">
            <IconButton style={{ padding: 0 }} onClick={onAddClick}>
              <AddCircleTwoToneIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      </Collapse>
      <Collapse in={add}>
        <div className="variable_input_container">
          <div className="variable_input_wrapper">
            <TextField
              variant="standard"
              size="small"
              placeholder="Variable Name"
              value={varData.var_name}
              onChange={onValueChange("var_name")}
              helperText="Variable name should be without white spaces."
            />
            <TextField
              variant="standard"
              size="small"
              placeholder="Variable Value"
              value={varData.var_value}
              onChange={onValueChange("var_value")}
              maxRows={6}
              multiline
            />
            <div className="var_save_wrapper">
              <Button color="primary" onClick={onCancel}>
                cancel
              </Button>
              <Divider orientation="vertical" flexItem />
              <Button
                color="primary"
                onClick={onAddNewVar}
                disabled={
                  loading ||
                  !(
                    varData.var_name &&
                    varData.var_value &&
                    !withoutWhiteSpaces.test(varData.var_name)
                  )
                }
              >
                save
              </Button>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
}

function FollowUp({ open, close, lead, contact, loadingFU, multiple }) {
  const dispatch = useDispatch();
  // const fields = useSelector(state => state.forms.fields);
  const contactFields = useSelector((state) => state.forms.contactFields);
  const [emails, setEmails] = useState(
    contactFields.filter((field) => field.type === "email")
  );
  const [cEmails, setCEmails] = useState(lead.emails || []);
  const followUpBody = useSelector((state) => state.email.followUpBody);
  const [email, setE] = useState({
    email_sub: "",
    email_body: followUpBody || "",
  });
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [ccEmails, setccEmails] = useState([]);
  const [openSS, setOpenSS] = useState(false);
  const multipleEmails = useSelector((state) => state.leads.emails);
  const communicationEmail = useSelector(
    (state) => state.auth.communicationEmail
  );
  const clientName = useSelector((state) => state.auth.clientName);
  const [bccEmails, setbccEmails] = useState([]);
  const [opencc, setOpencc] = useState(false);
  const [openbcc, setOpenbcc] = useState(multiple);
  const bodyTemplates = useSelector((state) => state.email.bodyTemplates);
  const draftBody = useSelector((state) => state.email.draftBody);
  const staffname = useSelector((state) => state.auth.staffname);
  const [selectedBTemp, setBSelectedTemp] = useState("");
  const [openSave, setOpenSave] = useState(false);
  const [tempName, setTempName] = useState("");
  const [saving, setSaving] = useState(false);
  const [scheduleTime, setScheduleTime] = useState("");
  const availableEmails = multiple
    ? [communicationEmail]
    : emails.map((ems) => lead[ems.label]).filter((v) => v);
  const allEmails = contactFields
    .filter((field) => field.type === "email")
    .map((ems) => lead[ems.label])
    .filter((v) => v);
  const disableTempSave =
    ![...availableEmails, ...cEmails].length ||
    !normalizeHtml(email.email_body || "").trim() ||
    loading;
  const unSupportedVariable =
    multiple &&
    !!config.restrictedTV.find((rtv) => email.email_body.includes(rtv));
  const disableSend =
    disableTempSave || !(email.email_sub || "").trim() || unSupportedVariable;
  const leadName = (lead && lead.name) || lead.contact_name || "";
  const [anchorEl, setAnchorEl] = useState(null);
  const [tempVars, setTempVars] = useState([]);
  const [loadingVars, setLoadingVars] = useState(false);
  const [confirmUpdate, setConfirm] = useState(false);
  const [confirmDelete, setConDel] = useState(false);
  const [moved, setMoved] = useState(0);
  const [copiedVar, setCopiedVar] = useState(null);

  const copiedTimeout = useRef();
  const chipsRail = useRef();
  const totalScroll = useRef(5);

  const [render, rerender] = useState(false);
  const [templateErrMsg, setTemplateErrMsg] = useState(false)

  const rrFU = () => {
    rerender(!render);
  };

  useEffect(() => {
    if (copiedTimeout.current) {
      clearTimeout(copiedTimeout.current);
    }

    copiedTimeout.current = copiedVar
      ? setTimeout(() => {
          setCopiedVar(null);
          clearTimeout(copiedTimeout.current);
        }, 20000)
      : null;

    return () => {
      if (copiedTimeout.current) {
        clearTimeout(copiedTimeout.current);
      }
    };
  }, [copiedVar]);

  useEffect(() => {
    if (multiple) {
      setbccEmails(multipleEmails.map((me) => me.email));
    }
  }, [multipleEmails]);

  const handleVClick = (event) => {
    if (!tempVars.length) {
      setLoadingVars(true);
      dispatch(getTemplateVariables())
        .then((res) => {
          if (res.result) {
            setTempVars(res.result);
          }

          setLoadingVars(false);
        })
        .catch(() => {
          setLoadingVars(false);
        });
    }
    setAnchorEl(event.currentTarget);
  };

  const loadTempVariables = () => {
    setLoadingVars(true);
    dispatch(getTemplateVariables())
      .then((res) => {
        if (res.result) {
          setTempVars(res.result);
        }

        setLoadingVars(false);
      })
      .catch(() => {
        setLoadingVars(false);
      });
  };

  const handleVClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (open) {
      setEmails(contactFields.filter((field) => field.type === "email"));
      setCEmails(lead.emails || []);
    }
  }, [contactFields, open]);

  useEffect(() => {
    if (email.email_body != followUpBody) {
      setE({ ...email, email_body: followUpBody });
    }
  }, [followUpBody]);

  const handleDelete = (i) => () => {
    setEmails(emails.filter((f, j) => j != i));
  };

  const handleCEDelete = (i) => () => {
    setCEmails(cEmails.filter((f, j) => j != i));
  };

  const onChangeContent = (name) => (e) => {
    setE({ ...email, [name]: e.target.value });
    if (name === "email_body") {
      let draftObj = { draft_body: e.target.value };
      if (lead && lead.lead_id) {
        draftObj["lead_id"] = lead.lead_id || "";
      }

      if (contact) {
        draftObj["contact_id"] = lead.contact_id || "";
      }

      dispatch(setEBDraft(draftObj));
    }
  };

  const onMailSend = () => {
    let emailObj = {
      email_to: [...availableEmails, ...cEmails],
      email_cc: ccEmails,
      email_bcc: bccEmails,
      ...email,
      email_body: normalizeHtml(email.email_body),
    };
    if (lead && lead.lead_id) {
      emailObj["lead_ids"] = [lead.lead_id] || [];
    }

    const leadIds = multipleEmails
      .filter((me) => bccEmails.includes(me.email))
      .map((em) => em.lead_id);

    if (multiple) {
      emailObj["lead_name"] = "";
      emailObj["lead_ids"] = leadIds;
    }

    if (leadName) {
      emailObj["lead_name"] = leadName;
    }

    if (contact) {
      emailObj["contact_id"] = lead.contact_id || "";
    }

    if (scheduleTime) {
      emailObj["schedule_time"] = getUTCDatetime(scheduleTime);
    }

    if (contactFields.length) {
      emailObj["fields"] = contactFields.map((cf) => ({
        field_name: cf.field_name,
        field_value: lead[cf.label] || "",
      }));
    }

    setLoading(true);
    return dispatch(sendFUMail(emailObj))
      .then((res) => {
        if (res.status === 1) {
          setTemplateErrMsg(false)
          setSent(true);
          setLoading(false);

          if (multiple && leadIds.length) {
            leadIds.forEach((leadID) => {
              dispatch(
                updateLastActivity(
                  leadID,
                  null,
                  "Sent a follow up email to the lead."
                )
              );
            });
          }

          if (lead.lead_id && !lead.contact_id) {
            dispatch(
              updateLastActivity(
                lead.lead_id,
                null,
                "Sent a follow up email to the lead."
              )
            );
          } else if (lead.contact_id) {
            dispatch(
              updateLastActivity(
                null,
                lead.contact_id,
                "Sent a follow up email to the contact."
              )
            );
          }
        } else if (res.status === 0) {
          setLoading(false)
          setTemplateErrMsg(true)
        } 
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onCloseDialog = () => {
    close();
    setSent(false);
    setE({ email_sub: "", email_body: followUpBody });
    setccEmails([]);
    setbccEmails([]);
    setOpencc(false);
    setBSelectedTemp("");
    setScheduleTime("");
    setMoved(0);
    totalScroll.current = 5;
    if (!multiple) {
      setOpenbcc(false);
    }
  };

  const onChipsReset = () => {
    setEmails(contactFields.filter((field) => field.type === "email"));
    setCEmails(lead.emails || []);
  };

  const onBTempChange = (e) => {
    const eValue = e?.value || "";
    setBSelectedTemp(eValue);

    if (eValue !== "") {
      const selectedBodyTemplate = bodyTemplates.find(
        (bt) => bt.tb_id === eValue
      );

      setE({
        ...email,
        email_sub: selectedBodyTemplate["subject"],
        email_body: selectedBodyTemplate["body"],
      });
    } else {
      setE({
        email_sub: "",
        email_body: followUpBody || "",
      });
    }
  };

  const onDraftClick = () => {
    setE({ ...email, email_body: draftBody });
  };

  const onSaveAndSend = () => {
    setTempName(email.email_sub);
    setOpenSave(true);
  };

  const onTempNameChange = (e) => {
    setTempName(e.target.value);
  };

  const onTempSave = (send) => () => {
    setSaving(true);
    dispatch(
      postBTemplate({
        name: tempName,
        email_subject: email.email_sub,
        email_body: normalizeHtml(email.email_body),
      })
    ).then(() => {
      setOpenSave(false);
      setSaving(false);
      if (send) {
        onMailSend();
      }
    });
  };

  const onVariableClick = (variable) => () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(variable);
    }
  };

  const onTemplateUpdate = () => {
    setConfirm(true);
  };

  const onUpdateConfirm = () => {
    setSaving(true);
    dispatch(
      updateBTemplate({
        template_id: selectedBTemp,
        email_subject: email.email_sub,
        email_body: normalizeHtml(email.email_body),
      })
    )
      .then(() => {
        setConfirm(false);
        setSaving(false);
      })
      .catch(() => {
        setSaving(false);
      });
  };

  const onTemplateDelete = () => {
    setConDel(true);
  };

  const onDeleteConfirm = () => {
    setSaving(true);
    dispatch(deleteBTemplate(selectedBTemp))
      .then(() => {
        setBSelectedTemp("");
        setE({ ...email, email_sub: "", email_body: followUpBody });
        setConDel(false);
        setSaving(false);
      })
      .catch(() => {
        setSaving(false);
      });
  };

  const onSSClose = () => {
    setOpenSS(false);
    setScheduleTime("");
  };

  const onDateTimeChange = (e) => {
    setScheduleTime(e.target.value);
  };

  const onScheduleMail = () => {
    onMailSend()
      .then(() => {
        setOpenSS(false);
      })
      .catch(() => {
        setOpenSS(false);
      });
  };

  const scrollChips = (direction) => () => {
    const count = Math.ceil(chipsRail.current.scrollWidth / 180 - 1);
    totalScroll.current = count;

    if (direction === "left") {
      chipsRail.current.scrollLeft -= 180;
      if (moved != 0) {
        setMoved(moved - 1);
      }
    } else if (direction === "right") {
      chipsRail.current.scrollLeft += 180;
      if (moved != count) {
        setMoved(moved + 1);
      }
    }
  };

  const emailsAvailable = availableEmails.length || cEmails.length;
  const inValidDatetime = scheduleTime.replace("T", " ") < getCurrentDatetime();

  return (
    <Dialog 
    open={open} 
    onClose={onCloseDialog} 
    maxWidth="sm" 
    fullWidth
    PaperProps={{
      sx:{overflowY: "hidden"}
    }}
    >
      {loadingFU ? (
        <DialogContent>
          <LinearProgress style={{ margin: 3 }} />
        </DialogContent>
      ) : (
        <>
          <DialogTitle onClose={onCloseDialog}>
            <div className="fu-email-title">
              <EmailIcon color="primary" />
              <Typography variant="h6" className="fu-email-t-text">
                New Mail
              </Typography>
              {!multiple ? (
                <Typography variant="caption" className="fu-email-client">
                  {leadName}
                  {leadName ? ", " : ""}Lead #{lead.lead_id}
                </Typography>
              ) : null}
            </div>
          </DialogTitle>
          <Divider />
          <DialogContent style={{ paddingBottom: 16 }}>
            <Collapse in={!sent}>
              <div className="fu-email-to">
                <Typography variant="h6" style={{ fontSize: 16 }}>To</Typography>
                <div className="fu-email-cc-wrapper">
                  {opencc ? null : (
                    <Typography
                      variant="h6"
                      style={{ marginRight: 5, fontSize: 16 }}
                      onClick={() => setOpencc(true)}
                    >
                      Cc
                    </Typography>
                  )}
                  {openbcc ? null : (
                    <Typography variant="h6" style={{ fontSize: 16 }} onClick={() => setOpenbcc(true)}>
                      Bcc
                    </Typography>
                  )}
                </div>
              </div>
              <Paper component="ul" className="email-chips-paper">
                <div className="email-chips-wrapper">
                  {emailsAvailable ? (
                    <>
                      {availableEmails.length
                        ? (multiple ? availableEmails : emails).map(
                            (data, i) => {
                              const emailValue = multiple
                                ? data
                                : lead[data.label];

                              if (emailValue) {
                                return (
                                  <li key={i}>
                                    <Chip
                                      size="small"
                                      className="email-chip"
                                      label={emailValue}
                                      onDelete={
                                        !cEmails.length &&
                                        availableEmails.length === 1
                                          ? null
                                          : handleDelete(i)
                                      }
                                    />
                                  </li>
                                );
                              }

                              return null;
                            }
                          )
                        : null}
                      {cEmails.length
                        ? cEmails.map((eml, i) => {
                            return (
                              <li key={i}>
                                <Chip
                                  size="small"
                                  className="email-chip"
                                  label={eml}
                                  onDelete={
                                    !availableEmails.length &&
                                    cEmails.length === 1
                                      ? null
                                      : handleCEDelete(i)
                                  }
                                />
                              </li>
                            );
                          })
                        : null}
                    </>
                  ) : (
                    <Typography variant="caption">
                      No email ids available.
                    </Typography>
                  )}
                </div>
                <div className="email-chips-reset">
                  <Tooltip title="Reset Emails">
                    <IconButton onClick={onChipsReset}>
                      <RotateLeftIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </Paper>
              <Collapse in={opencc}>
                <Typography variant="h6" style={{ fontSize: 16 }}>Cc</Typography>
                <FollowUpEmailInput
                  lead={lead}
                  allEmails={allEmails}
                  emailField={ccEmails}
                  setEmailField={setccEmails}
                  validateEmail={validateEmail}
                />
              </Collapse>
              <Collapse in={openbcc}>
                <div className="bcc-container">
                  <Typography variant="h6" style={{ fontSize: 16 }}>Bcc</Typography>

                  <FollowUpEmailInput
                    lead={lead}
                    allEmails={allEmails}
                    emailField={bccEmails}
                    setEmailField={setbccEmails}
                    validateEmail={validateEmail}
                  />
                </div>
              </Collapse>
              <div className="fu-email-sub-wrap">
                <Typography variant="h6" style={{ fontSize: 16 }}>Subject</Typography>
                <TextField
                  size="small"
                  variant="outlined"
                  className="fu-email-sub"
                  margin="dense"
                  onChange={onChangeContent("email_sub")}
                  value={email.email_sub}
                />
              </div>

              <div className="fu-email-tools">
                <IconButton
                  className="draft-btn"
                  disabled={!draftBody}
                  onClick={onDraftClick}
                >
                  <DraftsIcon style={{ color: "#546e7a" }}/>
                  <Typography
                    className={`draft-txt ${!draftBody ? "disabled" : ""}`}
                  >
                    Draft
                  </Typography>
                </IconButton>
                <div className="fu-right-tool">
                  <Autocomplete
                    size="small"
                    autoHighlight={true}
                    options={bodyTemplates.map((bodyTemplate) => ({
                      label: bodyTemplate.name,
                      value: bodyTemplate.tb_id,
                    }))}
                    getOptionLabel={(options) => options.label}
                    onChange={(e, bodyTemplate) => onBTempChange(bodyTemplate)}
                    style={{ minWidth: 220 }}
                    renderInput={(templateLists) => (
                      <TextField
                        {...templateLists}
                        label="Select a template"
                        variant="outlined"
                        InputLabelProps={{
                          style: {
                            color: "#263238"
                          }
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="e_body_bar">
                <Typography variant="h6" style={{ fontSize: 16 }}>Body</Typography>
                <div className="temp_var_holder">
                  {contactFields.length ? (
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={scrollChips("left")}
                      disabled={moved === 0}
                    >
                      <ChevronLeftIcon fontSize="medium" />
                    </IconButton>
                  ) : null}
                  {multiple ? null : (
                    <div className="chips-container">
                      <div className="chips-rail" ref={chipsRail}>
                        {config.templateVariables.map((tv, t) => (
                          <Tooltip key={t} title="click to copy">
                            <Chip
                              className="var_chip"
                              size="small"
                              label={tv}
                              onClick={onVariableClick(tv)}
                            />
                          </Tooltip>
                        ))}
                        {contactFields.map((cf, t) => (
                          <Tooltip key={t} title="click to copy">
                            <Chip
                              className="var_chip"
                              size="small"
                              label={`{field-${cf.field_name}}`}
                              onClick={onVariableClick(
                                `{field-${cf.field_name}}`
                              )}
                            />
                          </Tooltip>
                        ))}
                      </div>
                    </div>
                  )}
                  {contactFields.length ? (
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={scrollChips("right")}
                      disabled={moved === totalScroll.current}
                    >
                      <ChevronRightIcon fontSize="medium" />
                    </IconButton>
                  ) : null}

                  {copiedVar ? (
                    <Tooltip title="Copied variable">
                      <Chip
                        className="var_chip"
                        size="small"
                        label={truncateString(copiedVar, 20)}
                        variant="outlined"
                        color="primary"
                      />
                    </Tooltip>
                  ) : null}
                  <Tooltip title="Custom variables">
                    <IconButton style={{ padding: 0 }} onClick={handleVClick}>
                      <ViewListIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={handleVClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Typography
                      style={{ padding: "8px 15px", fontWeight: "bold" }}
                    >
                      Email variables
                    </Typography>
                    <Divider />
                    <div>{loadingVars ? <LinearProgress /> : null}</div>
                    <TempVars
                      vars={tempVars}
                      load={loadTempVariables}
                      setLoading={setLoadingVars}
                      loading={loadingVars}
                      setCopiedVar={setCopiedVar}
                      rerender={rrFU}
                    />
                  </Popover>
                </div>
              </div>
              <div className="fu-eml-body">
                <Paper className="fu-eml-bd-paper">
                  <ContentEditable
                    className="fu-email-ta"
                    onChange={onChangeContent("email_body")}
                    html={email.email_body}
                  />
                </Paper>
                {loading ? <LinearProgress /> : null}
                <div>
                  {unSupportedVariable ? (
                    <Typography variant="caption" color="error">
                      Unsupported variables [ {config.restrictedTV.toString()} ]
                      used in the email body. Please remove those to send email.
                    </Typography>
                  ) : null}
                  {templateErrMsg ? (<Typography variant="caption" color="error">
                  Error sending email. Some undefined variables found.
                  </Typography>) : null}
                  
                </div>
              </div>
              <div className="mail-actions">
                <div>
                  <div className="template-actions">
                    <Typography style={{ padding: "5px 10px" }}>
                      Template
                    </Typography>
                    <Divider orientation="vertical" flexItem />
                    {selectedBTemp && !staffname ? (
                      <Button
                        className="split-button-menu"
                        onClick={onTemplateUpdate}
                        disabled={disableTempSave}
                      >
                        <SaveAltOutlinedIcon
                          fontSize="small"
                          color={disableTempSave ? "disabled" : "primary"}
                        />
                        <span>Update</span>
                      </Button>
                    ) : null}
                    {selectedBTemp && !staffname ? (
                      <Divider orientation="vertical" flexItem />
                    ) : null}
                    <Button
                      className="split-button-menu"
                      onClick={onSaveAndSend}
                      disabled={
                        disableTempSave || email.email_body.includes("{field-")
                      }
                    >
                      <SaveOutlinedIcon
                        fontSize="small"
                        color={
                          disableTempSave ||
                          email.email_body.includes("{field-")
                            ? "disabled"
                            : "primary"
                        }
                      />
                      <span>Save</span>
                    </Button>
                    {selectedBTemp && !staffname ? (
                      <Divider orientation="vertical" flexItem />
                    ) : null}
                    {selectedBTemp && !staffname ? (
                      <Button
                        className="split-button-menu"
                        onClick={onTemplateDelete}
                        disabled={disableTempSave}
                      >
                        <DeleteForeverOutlinedIcon
                          fontSize="small"
                          color={disableTempSave ? "disabled" : "error"}
                        />
                        <span>Delete</span>
                      </Button>
                    ) : null}
                  </div>
                  {email.email_body.includes("{field-") ? (
                    <Typography
                      className="template_error_msg"
                      variant="caption"
                    >
                      You can't save this as template, since it contains field
                      variables.
                    </Typography>
                  ) : null}
                </div>

                <div>
                  <Tooltip title="Schedule mail">
                    <span>
                      <IconButton
                        style={{ padding: 6 }}
                        onClick={() => setOpenSS(true)}
                        disabled={disableSend}
                      >
                        <ScheduleIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: 5 }}
                    disabled={disableSend}
                    onClick={onMailSend}
                  >
                    SEND MAIL
                  </Button>
                </div>
              </div>
            </Collapse>
            <Dialog  open={openSave} onClose={() => setOpenSave(false)}>
              <DialogTitle onClose={() => setOpenSave(false)}>
                Save Template
              </DialogTitle>
              <Divider />
              <DialogContent>
                <TextField
                  size="small"
                  margin="dense"
                  variant="outlined"
                  placeholder="Template Name"
                  value={tempName}
                  onChange={onTempNameChange}
                />
                {saving ? <LinearProgress /> : null}
                <div className="temp-save-btn-grp">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onTempSave()}
                    disabled={!tempName}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onTempSave(true)}
                    disabled={!tempName || disableSend}
                  >
                    Save & Send
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
            <Collapse in={sent}>
              <div className="action-success">
                <CheckCircleIcon className="success-color" fontSize="large" />
                <Typography className="success-container marginT15">
                  {scheduleTime
                    ? "Email scheduled successfully"
                    : "Email sent successfully"}
                </Typography>
                {scheduleTime ? (
                  <Link className="click_link" to="/email">
                    See all schedules
                  </Link>
                ) : null}
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 15 }}
                  onClick={onCloseDialog}
                >
                  CLOSE
                </Button>
              </div>
            </Collapse>
          </DialogContent>
          <Dialog
            open={confirmUpdate || confirmDelete}
            onClose={() => setConfirm(false)}
            maxWidth="xs"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {confirmDelete
                  ? `Do you want to delete the current template?`
                  : ""}
                {confirmUpdate
                  ? `Do you want to update the current template?`
                  : ""}
              </DialogContentText>
              {confirmDelete ? (
                <Typography className="warning-container">
                  After deleting the template, you cannot recover it. Please
                  click on confirm, if you are sure to delete the template.
                  Otherwise you can cancel it.
                </Typography>
              ) : null}
              {confirmUpdate ? (
                <Typography className="warning-container">
                  Updating the template will override the existing. You can even
                  save the template in different name and use it.
                </Typography>
              ) : null}
            </DialogContent>
            {saving ? <LinearProgress /> : null}
            <DialogActions>
              <Button
                onClick={() =>
                  confirmDelete ? setConDel(false) : setConfirm(false)
                }
                color="primary"
              >
                Cancel
              </Button>
              <Button
                style={{ color: "red" }}
                onClick={confirmDelete ? onDeleteConfirm : onUpdateConfirm}
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openSS} maxWidth="xs" fullWidth>
            <DialogTitle onClose={onSSClose}>Pick a date and time</DialogTitle>
            <Divider />
            <DialogContent>
              <TextField
                label="Date & Time"
                type="datetime-local"
                defaultValue={getCurrentDatetime()}
                onChange={onDateTimeChange}
                style={{
                  marginBottom: 15,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {inValidDatetime ? (
                <Typography className="error-container">
                  Select a future date and time to schedule mail.
                </Typography>
              ) : null}
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button
                variant="contained"
                onClick={onScheduleMail}
                disabled={inValidDatetime}
              >
                Schedule Send
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Dialog>
  );
}

export default FollowUp;
