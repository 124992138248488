import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Snackbar, Alert as MuiAlert } from "@mui/material";

const MAlert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertComp = ({ alert }) => {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(false);

  return (
    <Snackbar
      key={alert.id}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <MAlert severity={alert.alertType}>{alert.msg}</MAlert>
    </Snackbar>
  );
};

const Alert = ({ alerts }) =>
  alerts && alerts.length
    ? alerts.map((item, i) => <AlertComp key={i} alert={item} />)
    : null;

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);
