import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
  LinearProgress,
  Grid,
} from "@mui/material";
import { getStaffsList } from "../../redux/modules/staffs";
import { getRoles } from "../../redux/modules/settings";
import StaffForm from "./components/StaffForm";
import AccessControl from "../../components/AccessControl";
import getAccessFor from "../../helpers/getAccessFor";
import { Helmet } from "react-helmet";
import StaffItem from "./components/StaffItem";

class Staffs extends PureComponent {
  state = {
    openForm: false,
    new: false,
    staff: {},
  };

  componentDidMount() {
    if (getAccessFor("view_staffs")) {
      if (!this.props.roles.length) {
        this.props.getRoles();
      }

      if (!this.props.staffsList.length) {
        this.props.getStaffsList();
      }
    }
  }

  addNewStaff = () => {
    this.setState({ openForm: true, new: true, staff: {} });
  };

  onStaffEdit = (staff) => {
    this.setState({ openForm: true, staff });
  };

  onCloseForm = () => {
    this.setState({ openForm: false });

    setTimeout(() => {
      this.setState({ new: false });
    }, 500);
  };

  setUpdatedStaff = () => {
    const staffId = this.state.staff.staff_id;

    if (staffId) {
      const staff =
        this.props.staffsList.find((s) => s.staff_id === staffId) || {};

      this.setState({ staff });
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Staffs</title>
          <meta name="description" content="Staffs page" />
        </Helmet>
        <AccessControl name="view_staffs" message>
          <div className="page_t_head">
            <div className="page_t_wrapper">
              <Typography className="page_title">Staffs</Typography>
              <AccessControl name="add_staff">
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={this.addNewStaff}
                >
                  Add Staff
                </Button>
              </AccessControl>
            </div>
          </div>
          <div className="staff_wrapper">
            <Paper className="staff_paper bdr">
              {this.props.loading ? <LinearProgress /> : null}
              {this.props.staffsList.length ? (
                <List className="staff_list">
                  {this.props.staffsList.map((staff, i) => (
                    <StaffItem
                      key={i}
                      staff={staff}
                      roles={this.props.roles}
                      onStaffEdit={this.onStaffEdit}
                    />
                  ))}
                </List>
              ) : !this.props.loading ? (
                <Typography style={{ padding: 30 }}>
                  No staffs added yet!
                </Typography>
              ) : null}
            </Paper>
            <StaffForm
              open={this.state.openForm}
              staff={this.state.staff}
              roles={this.props.roles}
              onClose={this.onCloseForm}
              onSave={this.setUpdatedStaff}
              new={this.state.new}
            />
          </div>
        </AccessControl>
      </>
    );
  }
}

export default connect(
  (state) => ({
    staffsList: state.staffs.staffsList,
    loading: state.staffs.loading,
    roles: state.settings.roles,
  }),
  { getStaffsList, getRoles }
)(Staffs);
