// ACTION TYPES
const GET_SUP_DOCS = 'GET_SUP_DOCS';
const GET_SUP_DOCS_S = 'GET_SUP_DOCS_S';
const GET_SUP_DOCS_F = 'GET_SUP_DOCS_F';

const GET_CL = 'GET_CL';
const GET_CL_SUCCESS = 'GET_CL_SUCCESS';
const GET_CL_FAILED = 'GET_CL_FAILED';

const MARK_READ = 'MARK_READ';
const MARK_READ_S = 'MARK_READ_S';
const MARK_READ_F = 'MARK_READ_F';

// INITIAL
const initialState = {
    supportDocs: [],
    changeLogs: [],
    clCount: 0
};

// REDUCER
export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_SUP_DOCS_S:
            return {
                ...state,
                supportDocs: payload.result
            };
        case GET_CL_SUCCESS:
            return {
                ...state,
                changeLogs: payload.result || [],
                clCount: Number(payload.counts)
            };
        default:
            return state;
    }
}

// ACTION CREATORS
export function getSupportDocs() {
    return {
        types: [GET_SUP_DOCS, GET_SUP_DOCS_S, GET_SUP_DOCS_F],
        promise: fetch => fetch.get('api/Help_content')
    };
}

export function getChangeLogs(page = 0) {
    return {
        types: [GET_CL, GET_CL_SUCCESS, GET_CL_FAILED],
        promise: fetch => fetch.get('api/Change_log', {
            params: {
                limit: 3,
                offset: page
            }
        })
    };
}

export function markFeaturesAsRead(release_id) {
    return {
        types: [MARK_READ, MARK_READ_S, MARK_READ_F],
        promise: fetch => fetch.put('api/Change_log', {
            data: {
                release_id
            }
        })
    };
}