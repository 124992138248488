import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  IconButton,
  LinearProgress,
} from "@mui/material";
import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ErrorOutline as ErrorOutlineIcon,
  ReportProblemOutlined as ReportProblemOutlinedIcon,
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
} from "@mui/icons-material";
import { getDateTimeWith12R } from "../../../utils/format_date";
import { getLogs } from "../../../redux/modules/manage";

function Logs() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const activityLogs = useSelector((state) => state.manage.logs);
  const totalLogs = useSelector((state) => state.manage.totalLogs);

  useEffect(() => {
    setLoading(true);
    dispatch(getLogs(page, limit))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [page]);

  const onPrev = () => {
    setPage((p) => p - 1);
  };

  const onNext = () => {
    setPage((p) => p + 1);
  };

  return (
    <div className="tble-bdr log-l-cont">
      {loading ? <LinearProgress /> : null}
      <List className="log-list">
        {activityLogs.length
          ? activityLogs.map((log, l) => (
              <Fragment key={l}>
                <ListItem button>
                  <ListItemIcon>
                    {log.type === "success" ? (
                      <CheckCircleOutlinedIcon className="success__color" />
                    ) : null}
                    {log.type === "error" ? (
                      <ErrorOutlineIcon color="error" />
                    ) : null}
                    {log.type === "warning" ? (
                      <ReportProblemOutlinedIcon className="warning__color" />
                    ) : null}
                  </ListItemIcon>
                  <ListItemText primary={log.message} />
                  <ListItemSecondaryAction>
                    <Typography variant="caption">
                      {getDateTimeWith12R(log.datetime)}
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </Fragment>
            ))
          : null}
        {!activityLogs.length && !loading ? (
          <Typography
            variant="caption"
            className="empty-message"
            style={{ margin: "15px" }}
            component="div"
          >
            No logs found...
          </Typography>
        ) : null}
      </List>
      <div className="log-nav-cont">
        <Typography variant="caption">
          {totalLogs > 0 ? `${page + 1}/${Math.ceil(totalLogs / limit)}` : ""}
        </Typography>
        <div className="log-nav-wrapper">
          <IconButton onClick={onPrev} disabled={page === 0}>
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton
            onClick={onNext}
            disabled={!(totalLogs - (page + 1) * limit > 0)}
          >
            <NavigateNextIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default Logs;
