import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Divider,
  LinearProgress,
} from "@mui/material";
import { Info as InfoIcon } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { deleteAForm } from "../../../redux/modules/forms";

function DeleteForm(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onDeleteClick = () => {
    setOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
  };

  const removeForm = () => {
    setLoading(true);
    dispatch(deleteAForm(props.formId)).then(() => {
      setLoading(false);
      setOpen(false);
    });
  };

  return (
    <>
      <Button
        variant="outlined"
        className="bdr-btn-style-chu"
        onClick={onDeleteClick}
      >
        Delete
      </Button>
      <Dialog open={open} onClose={onCancel} maxWidth="xs">
        <DialogContent>
          <Typography>
            Do you want to delete the form #{props.formId} ({props.title})?
          </Typography>
          <Typography className="form-wc">
            <i>
              <InfoIcon style={{ marginRight: 5 }} color="action" />
            </i>
            Deleting the form will remove access to leads related to this form.
          </Typography>
        </DialogContent>
        {loading ? <LinearProgress /> : null}
        <Divider />
        <DialogActions >
          <Button onClick={onCancel} color="primary" style={{ fontSize: 14 }}>
            Cancel
          </Button>
          <Button onClick={removeForm} style={{ color: "red", fontSize: 14 }} autoFocus>
            {" "}
            Confirm{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeleteForm;
