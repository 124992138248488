import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Popper,
  Paper,
  LinearProgress,
  TextField,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmailTemplates,
  getTempDetails,
  updateTemplate,
} from "../../../redux/modules/email";
import {
  PhotoCamera,
  Add as AddIcon,
  Close as CloseIcon,
  Cancel as CancelIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import DialogTitle from "../../../components/DialogTitle";

function SocialEditPop({ selectedSo, close, saveLinks }) {
  const [sso, setSso] = useState(selectedSo);
  const [imgErr, setImagErr] = useState(false);

  const onSocialLogoUpload = () => {
    const file = document.querySelector("input[id=social-logo-upload]")[
      "files"
    ][0];
    const reader = new FileReader();

    setImagErr(file.type != "image/png");

    reader.onload = function () {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      setSso({ ...sso, image: base64String });
    };

    reader.readAsDataURL(file);
  };

  const onLinkChange = (e) => {
    setSso({ ...sso, link: e.target.value });
  };

  const saveSocialLink = () => {
    saveLinks(sso);
  };

  const onCloseSEP = () => {
    close();
    setImagErr(false);
  };

  return (
    <Paper className="social-paper">
      <div className="social-popper-title">
        <Typography>Edit link</Typography>
        <IconButton size="small" onClick={onCloseSEP}>
          <CloseIcon />
        </IconButton>
      </div>
      <div>
        <label htmlFor="social-logo-upload">
          <Box
            component="img"
            style={{ width: 24 }}
            src={`data:image/png;base64,${sso.image}`}
          />
          <PhotoCamera color="primary" />
        </label>
        <input
          id="social-logo-upload"
          className="social-logo-upload-input"
          type="file"
          accept="image/png"
          onChange={onSocialLogoUpload}
        ></input>
        {imgErr ? (
          <Typography variant="caption" component="div" color="error">
            Only *.png format is supported.
          </Typography>
        ) : null}
      </div>
      <div>
        <TextField
          variant="outlined"
          value={sso.link}
          onChange={onLinkChange}
        />
      </div>
      <Button color="primary" onClick={saveSocialLink} disabled={imgErr}>
        Save Link
      </Button>
    </Paper>
  );
}

function SocialLinks({ links, setV }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState("");
  const [social, setSocial] = useState(links || []);

  const onEditClick = (s) => (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(s);
  };

  const onAddClick = () => {
    setSocial([
      ...social,
      {
        image:
          "iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAADOSURBVEiJvdbRbcJAEATQ55OuBddhCWiKJqC/gEQVfFACP5sP2wpJjIOC1yON7m9mb3S7e01EeIamaVrssMVmOOGE83B+RMTtqUhE/CIqjrgj/uAdB9RJrQnxDpcXhH/ygm7WAPsXq567zX7SYKj8HfFHk+6bwZD5f2KZi6s+GhwXFB95GF9ou1A0U1G1Rf/Oq+VRsSu+micD26Lv0Cxs4Gr5/EdeS2L1oOgHVhZORT8Vs3BOvwHJjZY/KlYZdunjepWFs8rKzFj6Tfa35RNazegIUvOA2AAAAABJRU5ErkJggg==",
        link: "https://example.com",
      },
    ]);
  };

  const onRemoveClick = (s) => {
    const newSocials = social.filter((soc, i) => i != s);
    setSocial(newSocials);
    setV(newSocials);
  };

  const onClosePopper = () => {
    setAnchorEl(null);
    setOpen("");
  };

  const saveLinks = (s) => (obj) => {
    const newSocial = [...social];
    newSocial[s] = obj;

    setSocial(newSocial);
    setV(newSocial);
    setAnchorEl(null);
    setOpen("");
  };

  return (
    <>
      {social.map((so, s) => (
        <React.Fragment key={s}>
          <div className="social-logo-holder">
            <Box
              key={s}
              className="social-logo"
              component="img"
              src={`data:image/png;base64,${so.image}`}
            />
            <div className="social-tools">
              <IconButton
                className="social-remove-btn"
                size="small"
                onClick={onEditClick(s)}
              >
                <EditIcon className="social-remove" />
              </IconButton>
              <IconButton
                className="social-remove-btn"
                size="small"
                onClick={() => onRemoveClick(s)}
                color="primary"
              >
                <CancelIcon className="social-remove" />
              </IconButton>
            </div>
          </div>
          <Popper
            style={{ zIndex: 2000 }}
            open={open === s}
            anchorEl={anchorEl}
            placement="top"
          >
            <SocialEditPop
              selectedSo={so}
              close={onClosePopper}
              saveLinks={saveLinks(s)}
            />
          </Popper>
        </React.Fragment>
      ))}
      <IconButton size="small" onClick={onAddClick}>
        <AddIcon />
      </IconButton>
    </>
  );
}

function InputComp({ textarea, ...props }) {
  return textarea ? <textarea {...props} /> : <input {...props} />;
}

function InlineTextEdit(props) {
  const [value, setValue] = useState(props.value);
  const [edit, setEdit] = useState(false);

  const onValueChange = (e) => {
    setValue(e.target.value);
  };

  const onTextClick = () => {
    setEdit(true);
  };

  const onOut = () => {
    if (!value) {
      setValue(props.value);
    }

    props.setV(value);

    setEdit(false);
  };

  return (
    <>
      {edit ? (
        <InputComp
          style={props.style}
          className={`${props.inputClass} ${props.textClass} text-inline-input`}
          value={value}
          onChange={onValueChange}
          onBlur={onOut}
          textarea={props.textarea}
          autoFocus
        />
      ) : (
        <Typography
          style={props.style}
          className={props.textClass}
          onClick={onTextClick}
        >
          {value}
        </Typography>
      )}
    </>
  );
}

export function TemplateItem({
  temp,
  templates,
  index,
  setTemp,
  resetTemplates,
}) {
  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [nTemplate, setNTemplate] = useState(temp || {});
  const dispatch = useDispatch();
  const [imgErr, setImagErr] = useState(false);

  useEffect(() => {
    setNTemplate(temp);
  }, [temp]);

  const onEditClick = () => {
    setLoading(true);
    dispatch(getTempDetails(temp.temp_id)).then(() => {
      setLoading(false);
    });
    setOpenEdit(true);
  };

  const onCloseEdit = () => {
    setOpenEdit(false);
    setImagErr(false);
    // resetTemplates();
  };

  const onLogoUpload = (ind) => {
    const file = document.querySelector("input[id=logo-upload]")["files"][0];
    const reader = new FileReader();

    setImagErr(file.type != "image/png");

    reader.onload = function () {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");
      const newTemp = { ...nTemplate, header_logo: base64String };
      setNTemplate(newTemp);
    };

    reader.readAsDataURL(file);
  };

  const setV = (key) => (value) => {
    setNTemplate({ ...nTemplate, [key]: value });
  };

  const onSaveTemplate = () => {
    setSaving(true);
    dispatch(updateTemplate(nTemplate)).then(() => {
      setSaving(false);
    });
  };

  return (
    <div>
      <ListItem className="list_item">
        <ListItemText
          primary={<Typography>{nTemplate.name}</Typography>}
          secondary={
            <Typography variant="caption">
              You can edit the email template here.
            </Typography>
          }
        />
        <Button
          className="sec-button"
          variant="outlined"
          color="secondary"
          onClick={onEditClick}
        >
          Edit
        </Button>
      </ListItem>
      <Dialog
        open={openEdit}
        onClose={onCloseEdit}
        maxWidth="lg"
        scroll="body"
        disableEnforceFocus
      >
        <DialogTitle onClose={onCloseEdit}>
          Edit Template - [{nTemplate.name}]
        </DialogTitle>
        <Divider />
        <DialogContent>
          {loading ? (
            <LinearProgress />
          ) : (
            <div className="email-container">
              <div className="email-header">
                <div className="email-logo-section">
                  <Box
                    className="logo-in-email"
                    component="img"
                    src={`data:image/png;base64,${nTemplate.header_logo}`}
                  />
                  <IconButton className="logo-upload-btn" type="small">
                    <label htmlFor="logo-upload">
                      <PhotoCamera />
                    </label>
                    <input
                      id="logo-upload"
                      className="logo-upload-input"
                      type="file"
                      accept="image/png"
                      onChange={() => onLogoUpload(index)}
                    ></input>
                  </IconButton>
                  {imgErr ? (
                    <Typography variant="caption" component="div" color="error">
                      Only *.png format is supported.
                    </Typography>
                  ) : null}
                </div>
                <InlineTextEdit
                  textClass="email-header-text"
                  inputClass="email-header-input"
                  value={nTemplate.header_text}
                  setV={setV("header_text")}
                />
              </div>
              <div className="email-body">
                <Typography>
                  Lorem ipsum dolor sit amet. Aut nemo optio ut corporis vitae
                  sed veritatis asperiores ut amet voluptatibus eum voluptas
                  voluptas et optio autem. Non odit explicabo sed soluta minus
                  sit doloremque ratione nam autem quia. Nam error soluta aut
                  unde corporis aut autem sequi.
                </Typography>
                <div className="email-body-cover"></div>
              </div>
              <div className="email-footer-first">
                <div className="first-left">
                  <InlineTextEdit
                    textClass="footer-texts-bold"
                    value={nTemplate.text1}
                    setV={setV("text1")}
                  />
                  <InlineTextEdit
                    style={{ marginTop: 10 }}
                    inputClass="email-text-width"
                    value={nTemplate.text2}
                    setV={setV("text2")}
                    textarea
                  />
                </div>
                <div className="first-right">
                  <InlineTextEdit
                    textClass="footer-texts-bold"
                    inputClass="email-text-width"
                    value={nTemplate.text3}
                    setV={setV("text3")}
                  />
                  <InlineTextEdit
                    style={{ marginTop: 10 }}
                    textClass="footer-texts-bold"
                    inputClass="email-text-width"
                    value={nTemplate.text4}
                    setV={setV("text4")}
                  />
                </div>
              </div>
              <div className="email-footer-second">
                <InlineTextEdit
                  textClass="footer-texts email-center-text-input email-text-width"
                  value={nTemplate.text5}
                  setV={setV("text5")}
                />
                <InlineTextEdit
                  textClass="footer-text6 email-center-text-input email-text-width"
                  value={nTemplate.text6}
                  setV={setV("text6")}
                />
                <div className="social-links">
                  <SocialLinks links={nTemplate.social} setV={setV("social")} />
                </div>
                <InlineTextEdit
                  textClass="footer-text7 email-center-text-input email-text-width"
                  value={nTemplate.text7}
                  setV={setV("text7")}
                />
              </div>
            </div>
          )}
          {saving ? <LinearProgress /> : null}
        </DialogContent>
        <DialogActions>
          <Button
            style={{ marginRight: 15 }}
            variant="contained"
            color="primary"
            onClick={onSaveTemplate}
            disabled={imgErr}
          >
            Save Template
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function EmailTemplates() {
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.email.templates);
  const [tempOnStates, setTemp] = useState(templates || []);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!templates.length) {
      setLoading(true);
      dispatch(getEmailTemplates()).then(() => {
        setLoading(false);
      });
    }

    setTemp(templates);
  }, [templates]);

  const resetTemplates = () => {
    setTemp(templates);
  };

  return (
    <div className="e-template-list">
      <Paper className="staff_paper">
        {loading ? <LinearProgress /> : null}
        <List className="staff_list">
          {tempOnStates.map((temp, t) => (
            <TemplateItem
              key={t}
              temp={temp}
              templates={tempOnStates}
              setTemp={setTemp}
              resetTemplates={resetTemplates}
              index={t}
            />
          ))}
        </List>
      </Paper>
    </div>
  );
}

export default EmailTemplates;
