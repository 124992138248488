import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="notfound">
      <h1 className="notfound__heading">404</h1>
      <p className="notfound__content">Page Not Found</p>
      <Link className="notfound__link" to="/">
        Go to Home
      </Link>
    </div>
  );
};

export default NotFound;
