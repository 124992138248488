import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  IconButton,
  Tooltip,
  LinearProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getKeys } from "../../../redux/modules/settings";
import { FileCopy as FileCopyIcon } from "@mui/icons-material";

function Keys() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const keys = useSelector((state) => state.settings.keys);
  const { dkim_private_key, dkim_public_key, spf_key } = keys || {};

  useEffect(() => {
    if (!dkim_private_key) {
      setLoading(true);
      dispatch(getKeys())
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  const onCopyKey = (copying_key) => {
    navigator.clipboard.writeText(copying_key);
  };

  return (
    <Card className="list_paper keys_container">
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          <div className="key_head_bar">
            <Typography className="key_head" variant="h5">
              DKIM public key
            </Typography>
            <Tooltip title="Copy key">
              <IconButton onClick={() => onCopyKey(dkim_public_key)}>
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className="key_plate">
            <Typography>{dkim_public_key}</Typography>
          </div>
          <div className="key-add-steps">
            <Typography variant="h5">
              To add or update a DKIM record:
            </Typography>
            <ol start="1">
              <li>
                <Typography>
                  Go to DNS settings of your domain in any Domain Manager.
                </Typography>
              </li>
              <li>
                <Typography>Add a TXT (Text) record.</Typography>
              </li>
              <li>
                <Typography>
                  Copy and paste the string displayed above (DKIM public key) in
                  the TXT record value field.
                </Typography>
              </li>
              <li>
                <Typography>Save your changes.</Typography>
              </li>
            </ol>
            <Typography variant="caption">
              A DKIM record is an email authentication method. It adds a digital
              signature to your outgoing emails, allowing the receiver to verify
              that an email coming from a domain was indeed authorized by the
              owner of that domain. It can take up to 48 hours for email
              authentication to start.
            </Typography>
          </div>
          <div className="key_head_bar">
            <Typography className="key_head" variant="h5">
              SPF key
            </Typography>
            <Tooltip title="Copy key">
              <IconButton onClick={() => onCopyKey(spf_key)}>
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className="key_plate">
            <Typography>{spf_key}</Typography>
          </div>
          <div className="key-add-steps">
            <Typography variant="h5">To add or update a SPF record:</Typography>
            <ol start="1">
              <li>
                <Typography>
                  Go to DNS settings of your domain in any Domain Manager.
                </Typography>
              </li>
              <li>
                <Typography>Add a TXT (Text) record.</Typography>
              </li>
              <li>
                <Typography>
                  Copy and paste the string displayed above (SPF key) in the TXT
                  record value field.
                </Typography>
              </li>
              <li>
                <Typography>Save your changes.</Typography>
              </li>
            </ol>
            <Typography variant="caption">
              Use Sender Policy Framework (SPF) to help protect your domain
              against spoofing, and help prevent your outgoing messages from
              being marked as spam.
            </Typography>
          </div>
        </>
      )}
    </Card>
  );
}

export default Keys;
