// ACTION TYPES
const GET_STATS = 'GET_STATS';
const GET_STATS_SUCCESS = 'GET_STATS_SUCCESS';
const GET_STATS_FAILED = 'GET_STATS_FAILED';

const GET_STAFF_STATS = 'GET_STAFF_STATS';
const GET_STAFF_STATS_S = 'GET_STAFF_STATS_S';
const GET_STAFF_STATS_F = 'GET_STAFF_STATS_F';

const GET_S_LOGIN_STATS = 'GET_S_LOGIN_STATS';
const GET_S_LOGIN_STATS_S = 'GET_S_LOGIN_STATS_S';
const GET_S_LOGIN_STATS_F = 'GET_S_LOGIN_STATS_F';

// INITIAL
const initialState = {
    stats: null,
    staffLogins: [],
    staffLoginCounts: []
};

// REDUCER
export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_STATS_SUCCESS:
            return {
                ...state,
                stats: payload.result
            };
        case GET_STAFF_STATS_S:
            return {
                ...state,
                staffLogins: payload.result || []
            };
        case GET_S_LOGIN_STATS_S:
            return {
                ...state,
                staffLoginCounts: payload.result || []
            };
        default:
            return state;
    }
}

// ACTION CREATORS
export function getStats(days) {
    return {
        types: [GET_STATS, GET_STATS_SUCCESS, GET_STATS_FAILED],
        promise: fetch => fetch.get('api/Dashboarddetails', {
            params: {
                days
            }
        })
    };
}

export function getStaffStats(days) {
    return {
        types: [GET_STAFF_STATS, GET_STAFF_STATS_S, GET_STAFF_STATS_F],
        promise: fetch => fetch.get('api/Activity_login', {
            params: {
                days
            }
        })
    };
}

export function getStaffLoginCountFor(days) {
    return {
        types: [GET_S_LOGIN_STATS, GET_S_LOGIN_STATS_S, GET_S_LOGIN_STATS_F],
        promise: fetch => fetch.get('api/Activity_login_staff', {
            params: {
                days
            }
        })
    };
}