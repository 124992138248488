
import React, { useEffect } from 'react';
import { Routes, Route, Navigate, redirect } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { RouteWithLayout } from './components';
import NotFound from './components/NotFound';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import PusherClient from 'pusher-js';
import {
  Home as HomeView,
  Staffs as StaffsView,
  Dashboard as DashboardView,
  Forms as FormsView,
  Settings as SettingsView,
  Leads as LeadsView,
  Contacts as ContactsView,
  Reports as ReportsView,
  Accounts as AccountsView,
  Login as LoginView,
  Signup as SignupView,
  StaffProfile as StaffProfileView,
  LeadDetails as LeadDetailsView,
  ContactAccounts,
  CreateForm,
  EditForm,
} from './containers';
import Deals from './containers/Deals';
import Password from './containers/Password';
import Email from './containers/Email/Email';
import ConfirmEmail from './containers/ConfirmEmail';
import Docs from './containers/Docs';
import { setNotfication } from './redux/modules/auth';
import config from './config';
import Manage from './containers/Manage';
import ChangeLog from './containers/ChangeLog/ChangeLog';

const routesList = [
  {
    path: "/dashboard",
    component: DashboardView,
    layout: MainLayout,
    auth: true
  },
  {
    path: "/login",
    component: LoginView,
    layout: MinimalLayout,
    auth: true,
    login: true
  },
  {
    path: "/home/:token",
    component: HomeView,
    layout: MinimalLayout,
    auth: false
  },
  {
    path: "/forms",
    component: FormsView,
    layout: MainLayout,
    auth: true
  },
  {
    path: "/form/create",
    component: CreateForm,
    layout: MainLayout,
    auth: true
  },
  {
    path: "/form/edit/:id",
    component: EditForm,
    layout: MainLayout,
    auth: true
  },
  {
    path: "/leads",
    component: LeadsView,
    layout: MainLayout,
    auth: true
  },
  {
    path: "/contacts",
    component: ContactsView,
    layout: MainLayout,
    auth: true
  },
  {
    path: "/deals",
    component: Deals,
    layout: MainLayout,
    auth: true
  },
  {
    path: "/staffs",
    component: StaffsView,
    layout: MainLayout,
    auth: true
  },
  {
    path: "/accounts",
    component: AccountsView,
    layout: MinimalLayout,
    auth: true,
    noSD: true
  },
  {
    path: "/contact_accounts",
    component: ContactAccounts,
    layout: MainLayout,
    auth: true
  },
  {
    path: "/reports",
    component: ReportsView,
    layout: MainLayout,
    auth: true
  },
  {
    path: "/settings",
    component: SettingsView,
    layout: MainLayout,
    auth: true
  },
  {
    path: "/staff/:id",
    component: StaffProfileView,
    layout: MainLayout,
    auth: true
  },
  {
    path: "/lead/:id",
    component: LeadDetailsView,
    layout: MainLayout,
    auth: true
  },
  {
    path: "/email",
    component: Email,
    layout: MainLayout,
    auth: true
  },
  {
    path: "/manage",
    component: Manage,
    layout: MainLayout,
    auth: true
  },
  {
    path: "/change-log",
    component: ChangeLog,
    layout: MainLayout,
    auth: true
  },
  {
    path: "/password_reset/:token",
    component: Password,
    layout: MinimalLayout,
    auth: false
  },
  {
    path: "/create_account/:token",
    component: Password,
    layout: MinimalLayout,
    auth: false
  },
  {
    path: "/staff_invite/:token",
    component: Password,
    layout: MinimalLayout,
    auth: false
  },
  {
    path: "/confirm_email/:token",
    component: ConfirmEmail,
    layout: MinimalLayout,
    auth: false
  },
  {
    path: "/signup",
    component: SignupView,
    layout: MinimalLayout,
    auth: false
  },
  {
    path: "/docs",
    component: Docs,
    layout: MinimalLayout,
    auth: false
  },
  {
    path: "/docs",
    component: Docs,
    layout: MinimalLayout,
    auth: false
  }
];

const AppRoutes = () => {
  const dispatch = useDispatch();
  const channelName = useSelector(state => state.auth.channelName);
  const channelEvents = useSelector(state => state.auth.events);

  useEffect(() => {
    if (channelName) {
      const pusher = new PusherClient(config.pusher.app_key, {
        cluster: config.pusher.cluster
      });
  
      // PusherClient.logToConsole = true;
  
      const channel = pusher.subscribe(channelName);
      
      channelEvents.forEach((eventName) => {
        channel.bind(eventName, function(data) {
          dispatch(setNotfication(data));
        });
      })
    }
  }, [channelName]);

  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate to="/dashboard" />}
      />
      <Route
        path="*"
        element={<NotFound />}
      />
      {
        routesList.map(({ path, ...rest }, a) => <Route key={a} path={path} element={<RouteWithLayout {...rest} />} />)
      }
    </Routes>
  );
};

export default AppRoutes;
