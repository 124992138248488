import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Paper, IconButton, InputBase } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import {
  setSearchQuery,
  getLeads,
  resetLeadsPage,
  getContacts,
  getLastActivity,
} from "../../redux/modules/leads";

const root = {
  padding: "2px 4px",
  display: "flex",
  alignItems: "center",
  width: "200px",
  height: "36px",
};

function SearchInput(props) {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (searchValue != props.searchQuery) {
      if (!props.searchQuery) {
        handleSearchActions(true);
      }
      setSearchValue(props.searchQuery);
    }
  }, [props.searchQuery]);

  const handleSearchActions = (novalue) => {
    props.setSearchQuery(novalue ? "" : searchValue);

    if (!props.contact) {
      props.resetLeadsPage();
    }

    props.setIsLoading(true);

    if (props.contact) {
      props.getContacts().then(() => {
        props.setIsLoading(false);
      });
    } else {
      props
        .getLeads(props.formId)
        .then(({ result }) => {
          props.setIsLoading(false);

          dispatch(getLastActivity(result.map((r) => r.lead_id)));
        })
        .catch(() => {
          props.setIsLoading(false);
        });
    }
  };

  const handleSearchText = (e) => {
    setSearchValue(e.target.value);
    if (!e.target.value) {
      handleSearchActions(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSearchActions();
    }
  };

  return (
    <div>
      <Paper style={root}>
        <InputBase
          value={searchValue}
          className="leads_search_input"
          placeholder={props.contact ? "Search Contact..." : "Search Lead..."}
          onKeyDown={handleKeyDown}
          onChange={handleSearchText}
        />
        <IconButton
          style={{ padding: 10 }}
          aria-label="search"
          onClick={() => handleSearchActions()}
        >
          <SearchIcon />
        </IconButton>
      </Paper>
    </div>
  );
}

export default connect((state) => ({ searchQuery: state.leads.searchQuery }), {
  setSearchQuery,
  getLeads,
  resetLeadsPage,
  getContacts,
})(SearchInput);
