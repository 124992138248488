import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  TextField,
  Button,
  LinearProgress,
  Divider,
} from "@mui/material";
import {
  getStatusList,
  updateStatusList,
} from "../../../redux/modules/settings";
import { Remove, Add } from "@mui/icons-material";
import DialogTitle from "../../../components/DialogTitle";

function isStatusListEqual(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((obj, index) => obj.name === b[index]["name"])
  );
}

function isStatusListEmpty(s) {
  const emptyStatuses = s.filter((i) => !i.name);

  return !!emptyStatuses.length;
}

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "row",
  },
  buttonContainer: {
    textAlign: "left",
    marginTop: 15,
  },
};

class StatusDialog extends PureComponent {
  state = {
    statuses: this.props.statusList,
    disableSave: true,
    openInfo: false,
    leadCount: "",
  };

  componentDidUpdate(preProps) {
    if (
      isStatusListEqual(this.props.statusList, this.state.statuses) &&
      !this.state.disableSave
    ) {
      this.setState({ disableSave: true });
    }

    // Here the API call happens only when there is no data in the redux store.
    if (this.props.open && !preProps.open && !this.props.loading) {
      this.props.fetchStatusList().then((res) => {
        this.setState({ statuses: res.result || [], disableSave: true });
      });
    }
  }

  handleStatusChange = (j, p, value) => {
    const prevStatuses = [...this.state.statuses];
    const sInProps = this.props.statusList.find(
      (t) => t.status_id === p.status_id
    );
    prevStatuses[j] = { ...p, name: value };
    const isEqual = isStatusListEqual(this.props.statusList, prevStatuses);
    const isEmpty = isStatusListEmpty(prevStatuses);

    this.setState({
      statuses: prevStatuses,
      disableSave: isEqual || isEmpty || (!sInProps && !value),
    });
  };

  handleRemoveOptions = (j) => {
    const prevStatuses = [...this.state.statuses];
    const isEmpty = isStatusListEmpty(prevStatuses);
    const leadCount = Number(prevStatuses[j]["lead_count"] || 0);

    if (!!leadCount) {
      this.setState({ leadCount, openInfo: true });
    } else {
      prevStatuses.splice(j, 1);
      this.setState({ statuses: prevStatuses, disableSave: isEmpty });
    }
  };

  handleAddOptions = (p) => {
    const statuses = this.state.statuses;
    const prevStatuses = [...statuses];
    const statusId = Number(statuses[statuses.length - 1]["status_id"]) + 1;
    prevStatuses.push({
      status_id: statusId,
      name: "",
    });
    this.setState({ statuses: prevStatuses, disableSave: true });
  };

  onClose = () => {
    this.setState({ statuses: this.props.statusList, disableSave: true });
    this.props.onClose();
  };

  saveStatuses = () => {
    this.props.updateStatusList(this.state.statuses);
  };

  infoClose = () => {
    this.setState({ openInfo: false });
  };

  render() {
    return (
      <div>
        <Dialog
          onClose={this.onClose}
          aria-labelledby="customized-dialog-title"
          open={this.props.open}
          scroll="body"
        >
          <DialogTitle id="customized-dialog-title" onClose={this.onClose}>
            Edit Status List
          </DialogTitle>
          <Divider />
          <DialogContent>
            {this.state.statuses.map((status, i) => {
              const statusLength = this.state.statuses.length;

              return (
                <div style={styles.wrapper} key={i}>
                  <TextField
                    size="small"
                    className="text-field"
                    required
                    name="item"
                    label={`Status ${i + 1}`}
                    variant="outlined"
                    onChange={(e) =>
                      this.handleStatusChange(i, status, e.target.value)
                    }
                    value={status.name}
                    margin="dense"
                  />
                  <IconButton
                    disabled={statusLength === 1}
                    onClick={() => this.handleRemoveOptions(i)}
                  >
                    <Remove />
                  </IconButton>
                  <IconButton
                    disabled={statusLength !== i + 1}
                    onClick={() => this.handleAddOptions()}
                  >
                    <Add />
                  </IconButton>
                </div>
              );
            })}
            {this.props.loading ? <LinearProgress /> : null}
            <div style={styles.buttonContainer}>
              <Button
                variant="contained"
                onClick={this.saveStatuses}
                color="primary"
                disabled={this.state.disableSave}
                autoFocus
              >
                Save Changes
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog open={this.state.openInfo} onClose={this.infoClose}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Since ${this.state.leadCount} leads are using this status, you cannot delete this.`}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  statusList: state.settings.statusList,
  loading: state.settings.loading,
});

export default connect(mapStateToProps, {
  fetchStatusList: getStatusList,
  updateStatusList,
})(StatusDialog);
