import React from "react";
import PropTypes from "prop-types";

const Minimal = (props) => {
  const { children } = props;

  return (
    <div style={{ paddingTop: 64, height: "100%" }}>
      <main style={{ height: "100%" }}>{children}</main>
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Minimal;
