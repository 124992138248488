import { setAlert } from './alert';

// ACTION TYPES
const GET_STAFFS_LIST = 'GET_STAFFS_LIST';
const GET_STAFFS_LIST_SUCCESS = 'GET_STAFFS_LIST_SUCCESS';
const GET_STAFFS_LIST_FAILED = 'GET_STAFFS_LIST_FAILED';

const UPDATE_STAFF = 'UPDATE_STAFF';
const UPDATE_STAFF_SUCCESS = 'UPDATE_STAFF_SUCCESS';
const UPDATE_STAFF_FAILED = 'UPDATE_STAFF_FAILED';

const ADD_STAFF = 'UPDATE_STAFF';
const ADD_STAFF_SUCCESS = 'UPDATE_STAFF_SUCCESS';
const ADD_STAFF_FAILED = 'UPDATE_STAFF_FAILED';

const DELETE_STAFF = 'UPDATE_STAFF';
const DELETE_STAFF_SUCCESS = 'UPDATE_STAFF_SUCCESS';
const DELETE_STAFF_FAILED = 'UPDATE_STAFF_FAILED';

const RESEND_INVITE = 'RESEND_INVITE';
const RESEND_INVITE_S = 'RESEND_INVITE_S';
const RESEND_INVITE_F = 'RESEND_INVITE_F';

// INITIAL
const initialState = {
    staffsList: [],
    loading: false
};

// REDUCER
export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case UPDATE_STAFF:
        case GET_STAFFS_LIST:
            return {
                ...state,
                loading: true
            };
        case GET_STAFFS_LIST_SUCCESS:
            return {
                ...state,
                staffsList: payload.result || [],
                loading: false
            };
        case UPDATE_STAFF_SUCCESS:
        case UPDATE_STAFF_FAILED:
        case GET_STAFFS_LIST_FAILED:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}

// ACTION CREATORS
export function getStaffsList() {
    return {
        types: [GET_STAFFS_LIST, GET_STAFFS_LIST_SUCCESS, GET_STAFFS_LIST_FAILED],
        promise: fetch => fetch.get("api/Staff/")
    };
}

export function updateStaff(staff) {
    return dispatch => dispatch({
        types: [UPDATE_STAFF, UPDATE_STAFF_SUCCESS, UPDATE_STAFF_FAILED],
        promise: fetch => fetch.put("api/Staff/", { 
            data: staff
         })
    })
    .then((res) => {
        if (res.status === 1) {
            dispatch(setAlert("Staff updated successfully.", "success"));

            return dispatch(getStaffsList());
        } else if (res.status === 0) {
            dispatch(setAlert(`[Error] ${res.message}`, "error"));

            return Promise.reject();
        }
    });
}

export function addStaff(staff) {
    return dispatch => dispatch({
        types: [ADD_STAFF, ADD_STAFF_SUCCESS, ADD_STAFF_FAILED],
        promise: fetch => fetch.post("api/Staff/", { 
            data: staff
         })
    })
    .then((res) => {
        if (res.status === 1) {
            dispatch(setAlert("Staff added successfully.", "success"));
    
            return dispatch(getStaffsList());
        } else if (res.status === 0) {
            dispatch(setAlert(`[Error] ${res.message}`, "error"));

            return Promise.reject();
        }
    });
}

export function deleteStaff(staff_id) {
    return dispatch => dispatch({
        types: [DELETE_STAFF, DELETE_STAFF_SUCCESS, DELETE_STAFF_FAILED],
        promise: fetch => fetch.delete("api/Staff/", { 
            data: { staff_id }
         })
    })
    .then(() => {
        dispatch(setAlert("Staff deleted successfully.", "success"));

        return dispatch(getStaffsList());
    });
}

export function resendInvite(staff_id, email) {
    return dispatch => dispatch({
        types: [RESEND_INVITE, RESEND_INVITE_S, RESEND_INVITE_F],
        promise: fetch => fetch.post('api/Staff_mail_resend', {
            data: {
                staff_id,
                email
            }
        })
    }).then((res) => {
        if (res.status == 0) {
            dispatch(setAlert(`[Error] ${res.message}`, "error"));

            return Promise.reject();
        }

        return res;
    });
}