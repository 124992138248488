import React, { useState, useEffect } from "react";
import TableofContents from "./Components/TableofContents";
import DocContent from "./Components/DocContent";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Hidden, IconButton, Paper, Typography, Drawer } from "@mui/material";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { getSupportDocs } from "../../redux/modules/noauth";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet";


function Docs() {
  const dispatch = useDispatch();
  const [sidebarOpen, setSidebar] = useState(true);
  const supportDocs = useSelector((state) => state.noauth.supportDocs);

  useEffect(() => {
    dispatch(getSupportDocs());
  }, []);

  const toggleSidebar = (open) => (e) => {
    setSidebar(open);
  };

  return (
    <>
      <Helmet>
        <title>Helper Docs</title>
        <meta name="description" content="Helper Docs page" />
      </Helmet>
      {supportDocs.length ? (
        <>
          <header className="doc-header">
            <div className="brand-holder">
              <Hidden lgUp>
                <IconButton
                  className="doc-hb-menu-btn"
                  color="inherit"
                  onClick={toggleSidebar(!sidebarOpen)}
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>
              <div className="">
                <RouterLink to="/dashboard">
                  <img
                    className="MuiBox-root jss28 logo_bdr"
                    src="/fl-logo-w.png"
                  />
                </RouterLink>
              </div>
            </div>
            <div className="back-th">
              <RouterLink to="/dashboard">
                <Typography>Back to Home</Typography>
              </RouterLink>
            </div>
          </header>

          <main className="docs">
            <Hidden lgUp implementation="css">
              <Drawer
                className="docs-drawer"
                variant="persistent"
                anchor="left"
                open={sidebarOpen}
                classes={{
                  paper: "docs-drawer-paper"
                }}
              >
                <TableofContents content={supportDocs} />
              </Drawer>
            </Hidden>
            <Hidden mdDown implementation="css">
              <Drawer
                variant="permanent"
                className={"drawer"}
                classes={{
                  paper: "docs-drawer-paper"
                }}
              >
                <TableofContents content={supportDocs} />
              </Drawer>
            </Hidden>
            <div id="scrolling-content">
              <div className="page_t_head">
                <div className="page_t_wrapper">
                  <Typography className="page_title docs-page-title">
                    FormsLeads Docs
                  </Typography>
                </div>
              </div>
              <Paper
                className={`docs-content docs-content-shift}`}
              >
                <section className="doc-content">
                  <DocContent content={supportDocs} />
                </section>
              </Paper>
            </div>
          </main>
        </>
      ) : null}
    </>
  );
}

export default Docs;
