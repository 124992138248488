import { Typography } from '@mui/material';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import config from '../../config';

class AccessControl extends PureComponent {
    render() {
        const { name, names, accesses } = this.props;
        const namesAvailable = names && names.length;
        let access = false;
        if (namesAvailable > 0) {
            const functionIDs = names.map((n) => config.functions[n]);
            access = !!functionIDs.filter(id => !!Number(accesses[id])).length;
        } else  {
            const functionID = config.functions[name];
            access = !!Number(accesses[functionID]);
        }
        const Message = this.props.message ? <Typography type="h6" className='page-access-error' >You do not have permission to view this content</Typography> : null;

        return (
            <>
                {
                    access ? this.props.children : Message
                }
            </>
        );
    }
}

export default connect(state => ({ accesses: state.auth.accesses }), {})(AccessControl);