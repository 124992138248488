import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './sass/main.scss';
import { Provider } from "react-redux";
import Routes from './Routes';
import Alert from './components/Alert';
import theme from './theme';
import store from "./redux/store";

const baseName = process.env.REACT_APP_STAGING ? '/staging' : '/';

const App = () => {
  return (
    <Provider store={store}>
        <Router basename={baseName} >
          <Routes />
        </Router>
        <Alert />
    </Provider> 
  );
}

export default App;
