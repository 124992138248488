import React, { useState } from "react";
import { Typography, Tabs, Tab, Box } from "@mui/material";
import Backup from "./components/Backup";
import Logs from "./components/Logs";
import getAccessFor from "../../helpers/getAccessFor";
import AccessControl from "../../components/AccessControl";
import { Helmet } from "react-helmet";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}


export default function Manage() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dbb = getAccessFor("db_backup");
  const ntl = getAccessFor("notification_logs");

  return (
    <>
      <Helmet>
        <title>Manage</title>
        <meta name="description" content="Manage page" />
      </Helmet>
      <AccessControl names={["db_backup", "notification_logs"]} message>
        <div className="email_container">
          <div className="page_t_head">
              <div className="page_d_wrapper" >
                  <Typography className="page_title" >Manage</Typography>
              </div>
            </div>
          <Tabs className="manage-tabs" value={value} onChange={handleChange}>
            {dbb ? <Tab className="manage-tab" label="Backup" /> : null}
            {ntl ? <Tab className="manage-tab" label="Logs" /> : null}
          </Tabs>
          <TabPanel value={value} index={!dbb && ntl ? -1 : 0}>
            <Backup />
          </TabPanel>
          <TabPanel value={value} index={dbb ? 1 : 0}>
            <Logs />
          </TabPanel>
        </div>
      </AccessControl>
    </>
  );
}
