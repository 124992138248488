import React from "react";
import {
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

const DialogTitle = (props) => {
  const { children, className, classes, onClose, ...other } = props;

  return (
    <div
      className="dialog-title-wrapper"
      {...other}
    >
      <div className={className}>{children}</div>
      {onClose ? (
        <IconButton
          aria-label="close"
          className="dialog-title-close-btn"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </div>
  );
};

export default DialogTitle;
