import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  Box,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import {
  addNewPassword,
  createNewAccWithPass,
  postStaffInvite,
  verifyPasswordToken,
} from "../../redux/modules/auth";
import { useLocation, useParams, useNavigate } from "react-router-dom";

function Password(props) {
  const params = useParams();
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [notMatch, setNotMatch] = useState(false);
  const [changed, setChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [verifying, setVerifying] = useState(1);
  const [expired, setExpired] = useState(false);
  const emailId = useSelector((state) => state.auth.emailId);
  const subDomain = useSelector((state) => state.auth.subDomain);
  const currentLocation = useLocation();
  const navigate = useNavigate();

  const newAccountCreation =
    currentLocation.pathname.includes("create_account");
  const staffInvite = currentLocation.pathname.includes("staff_invite");

  useEffect(() => {
    setVerifying(2);
    dispatch(verifyPasswordToken(params.token))
      .then(() => {
        setVerifying(0);
      })
      .catch(() => {
        setVerifying(0);
        setExpired(true);
      });
  }, []);

  const onNewPasswordChange = (e) => {
    setNewPassword(e.target.value);

    if (notMatch) {
      setNotMatch(false);
    }
  };

  const onConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);

    if (notMatch) {
      setNotMatch(false);
    }
  };

  const onPasswordSave = () => {
    if (newPassword === confirmPassword) {
      setIsLoading(true);
      (newAccountCreation
        ? dispatch(createNewAccWithPass(newPassword, params.token))
        : dispatch(addNewPassword(newPassword, params.token))
      )
        .then(() => {
          setIsLoading(false);
          setChanged(true);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setNotMatch(true);
    }
  };

  const onAcceptInvite = () => {
    setIsLoading(true);
    dispatch(postStaffInvite(params.token))
      .then(() => {
        setIsLoading(false);
        setChanged(true);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const onGoToLogin = () => {
    navigate("/login");
  };

  const onGoToHome = () => {
    navigate("/");
  };

  return (
    <div className="auth" style={{ backgroundImage: "url(/login-bg.png)" }}>
      <Container maxWidth="xs">
        <div className="logodiv">
          <Box
            component="img"
            style={{ height: 81, marginBottom: 20 }}
            src="/logo-FL.png"
          />
        </div>
        <Paper className="auth_paper">
          {newAccountCreation ? (
            <Typography variant="h6" className="auth__lead">
              Create password for your new Account{" "}
              {emailId ? <span className="value__chip">{emailId}</span> : null}
            </Typography>
          ) : staffInvite ? (
            <Typography variant="h6" className="auth__lead">
              Staff account invite from <b>{subDomain}</b> for your email id
              {emailId ? <span className="value__chip">{emailId}</span> : null}
            </Typography>
          ) : (
            <Typography variant="h6" className="auth__lead">
              Change password for account with email id{" "}
              {emailId ? <span className="value__chip">{emailId}</span> : ""}
            </Typography>
          )}
          {changed ? (
            <div className="auth__form">
              <Typography className="password_sent_message">
                {newAccountCreation
                  ? "Your account created successfully."
                  : staffInvite
                  ? "You have accepted the invitation."
                  : "Password has been changed successfully."}
              </Typography>
              <Button color="primary" onClick={onGoToLogin}>
                {newAccountCreation ? "Go to sign in" : "Return to sign in"}
              </Button>
            </div>
          ) : (
            <div className="password_fields_wrapper">
              {verifying === 0 ? (
                <>
                  {expired ? (
                    <div>
                      <Typography className="password_sent_error">
                        {newAccountCreation
                          ? "Password creation link has been expired."
                          : "Password reset link has been expired."}
                      </Typography>
                      {newAccountCreation ? (
                        <Button color="primary" onClick={onGoToHome}>
                          Return to Home
                        </Button>
                      ) : (
                        <Button color="primary" onClick={onGoToLogin}>
                          Return to sign in
                        </Button>
                      )}
                    </div>
                  ) : staffInvite ? (
                    <Button
                      className="change_password_btn"
                      color="primary"
                      variant="contained"
                      style={{ marginTop: 15 }}
                      onClick={onAcceptInvite}
                    >
                      Accept Now
                    </Button>
                  ) : (
                    <>
                      <TextField
                        className="cred_input_textfield"
                        variant="outlined"
                        placeholder={
                          newAccountCreation ? "Password" : "New Password"
                        }
                        value={newPassword}
                        onChange={onNewPasswordChange}
                        type="password"
                      />
                      <TextField
                        className="cred_input_textfield"
                        variant="outlined"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={onConfirmPasswordChange}
                        type="password"
                      />
                      {isLoading ? <LinearProgress /> : null}
                      {notMatch ? (
                        <Typography color="error">
                          Passwords do not match
                        </Typography>
                      ) : null}
                      <Button
                        className="change_password_btn"
                        color="primary"
                        variant="contained"
                        style={{ marginTop: 15 }}
                        onClick={onPasswordSave}
                        disabled={!newPassword || !confirmPassword}
                      >
                        {newAccountCreation
                          ? "Create Password"
                          : "Change Password"}
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <CircularProgress />
              )}
            </div>
          )}
        </Paper>
      </Container>
    </div>
  );
}

export default Password;
