import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import {
  MoreVert as MoreVertIcon,
  Email as EmailIcon,
} from "@mui/icons-material";
import FollowUp from "../../Leads/components/FollowUp";
import AccessControl from "../../../components/AccessControl";
import { getBTemplates, getEBDraft } from "../../../redux/modules/email";

function ContactsMore({ lead, getFUB, getForm }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openFU, setOpenFU] = useState(false);
  const cFormId = useSelector((state) => state.forms.cFormId);
  const [loadingFU, setLoadingFU] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onFollowUpClick = () => {
    let fuPromises = [];
    setLoadingFU(true);

    if (lead.form_id && lead.form_id != cFormId && getForm) {
      fuPromises.push(getForm(lead.form_id));
    }

    fuPromises.push(getFUB());
    fuPromises.push(dispatch(getBTemplates()));
    fuPromises.push(dispatch(getEBDraft({ contact_id: lead.contact_id })));
    Promise.all(fuPromises)
      .then(() => {
        setLoadingFU(false);
      })
      .catch(() => {
        setLoadingFU(false);
      });
    setOpenFU(true);
    setAnchorEl(null);
  };

  const onFollowUpClose = () => {
    setOpenFU(false);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <AccessControl name="contact_follow_up">
          <MenuItem onClick={onFollowUpClick}>
            <ListItemIcon>
              <EmailIcon fontSize="small" />
              <Typography variant="inherit" style={{ marginLeft: 5 }}>
                Follow up
              </Typography>
            </ListItemIcon>
          </MenuItem>
        </AccessControl>
      </Menu>
      <FollowUp
        open={openFU}
        close={onFollowUpClose}
        lead={lead}
        contact
        loadingFU={loadingFU}
      />
    </>
  );
}

export default ContactsMore;
