import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MUIDataTable from "mui-datatables";
import {
  Select,
  MenuItem,
  LinearProgress,
  Typography,
  Button,
  Grid,
  ListSubheader,
  Pagination,
} from "@mui/material";
import {
  getContacts,
  getAllAccount,
  setSelectedAccountForContact,
  setSelectedFormId,
  setLeadsPage,
  setLeadsPerPage,
  getFormsList,
  setFormPage,
  setSearchQuery,
} from "../../redux/modules/leads";
import {
  getAContactForm,
  getAFormAndReturnPromise,
} from "../../redux/modules/forms";
import LeadDetails from "../Leads/components/LeadDetails";
import RemoveContact from "./components/RemoveContact";
import ImportContact from "./components/ImportContact";
import AccessControl from "../../components/AccessControl";
import getAccessFor from "../../helpers/getAccessFor";
import Notes from "../Leads/components/Notes";
import LeadTimeline from "../Leads/components/LeadTimeline";
import AddToContact from "../Leads/components/AddToContact";
import ContactsMore from "./components/ContactsMore";
import { getAFormatBody } from "../../redux/modules/email";
import { Helmet } from "react-helmet";
import { SearchInput } from "../../components/SearchInput";

function Contacts() {
  const dispatch = useDispatch();
  const selectedAccount = useSelector((state) => state.leads.saForContact);
  const selectedFormId = useSelector((state) => state.leads.selectedFormId);
  const accountsList = useSelector((state) => state.leads.accounts);
  const formsList = useSelector((state) => state.leads.formsList);
  const contacts = useSelector((state) => state.leads.contacts);
  const page = useSelector((state) => state.leads.page);
  const rowsPerPage = useSelector((state) => state.leads.rowsPerPage);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingFields, setLoadingFields] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const formPage = useSelector((state) => state.leads.formPage);
  const formTotal = useSelector((state) => state.leads.totalForms);
  const tableOptions = {
    filter: false,
    print: true,
    responsive: "standard",
    search: false,
    sort: false,
    download: true,
    pagination: true,
    viewColumns: false,
    selectableRows: "none",
    jumpToPage: true,
    serverSide: true,
    textLabels: {
      body: {
        noMatch:
          loading || loadingFields ? (
            "Loading..."
          ) : (
            <Typography
              className="empty-message"
              component="div"
              variant="caption"
            >
              No contacts has been saved yet...
            </Typography>
          ),
      },
    },
  };

  tableOptions.page = page;
  tableOptions.rowsPerPage = rowsPerPage;
  tableOptions.count = useSelector((state) => state.leads.totalContacts);
  tableOptions.onTableChange = (action, tableState) => {
    switch (action) {
      case "changePage":
        dispatch(setLeadsPage(tableState.page));
        break;
      case "changeRowsPerPage":
        dispatch(setLeadsPage(0));
        dispatch(setLeadsPerPage(tableState.rowsPerPage));
        break;
      default:
        break;
    }
  };
  tableOptions.customToolbar = () => <ImportContact onSuccess={onImportSuccess} />;

  const handleAccountSelection = (e) => {
    dispatch(setLeadsPage(0));
    dispatch(setSelectedAccountForContact(e.target.value));
  };

  const handleFormSelection = (e) => {
    dispatch(setSelectedFormId(e.target.value));
  };

  const getFUB = () => {
    return dispatch(getAFormatBody());
  };

  const getFormDetails = (formId) => {
    return dispatch(getAContactForm(formId));
  };

  const onImportSuccess = () => {
    setRefresh(refresh + 1);
  }

  useEffect(() => {
    dispatch(setSearchQuery(""));
    dispatch(setSelectedFormId("all"));
    if (getAccessFor("view_contacts")) {
      let promises = [];
      setLoading(true);
      promises.push(dispatch(getFormsList()));
      promises.push(dispatch(setSelectedAccountForContact("")));

      if (!accountsList.length) {
        promises.push(dispatch(getAllAccount()));
      }

      Promise.all(promises).then(() => {
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (
      (!!Number(selectedFormId) || selectedFormId === "all") &&
      accountsList.length &&
      getAccessFor("view_contacts")
    ) {
      setLoadingFields(true);
      dispatch(getAFormAndReturnPromise(selectedFormId)).then((forms) => {
        const col = [
          {
            name: "contact_id",
            label: "Contact Id",
            options: {
              filter: false,
              sort: true,
              setCellProps: () => ({
                style: {
                  whiteSpace: "nowrap",
                  position: "sticky",
                  left: "0",
                  background: "white",
                  zIndex: 100,
                },
              }),
              setCellHeaderProps: () => ({
                style: {
                  whiteSpace: "nowrap",
                  position: "sticky",
                  left: 0,
                  background: "white",
                  zIndex: 101,
                },
              }),
            },
          },
        ];

        col.push({
          name: "contact_name",
          label: "Name",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta) => {
              const account_id =
                tableMeta.tableData[tableMeta.rowIndex]["account_id"];
              const account =
                accountsList.find((acc) => acc.account_id === account_id) || {};

              return (
                <>
                  <Typography>{value}</Typography>
                  {!selectedAccount && account["name"] ? (
                    <Typography
                      variant="caption"
                      className="account_name_in_contact"
                    >
                      {account["name"]}
                    </Typography>
                  ) : null}
                </>
              );
            },
          },
        });

        const emailField = forms.fields.filter((f) => f.type === "email");

        if (emailField.length) {
          col.push({
            name: emailField[0]["label"],
            label: "Email",
            options: {
              filter: false,
              sort: false,
            },
          });
        }

        col.push({
          name: "last_activity",
          label: "Activities",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta) => {
              const lead_id =
                tableMeta.tableData[tableMeta.rowIndex]["lead_id"];
              const contact_id =
                tableMeta.tableData[tableMeta.rowIndex]["contact_id"];

              return (
                <LeadTimeline
                  leadId={lead_id}
                  contactId={contact_id}
                  last={value}
                  lead={tableMeta.tableData[tableMeta.rowIndex]}
                />
              );
            },
          },
        });

        if (getAccessFor("contact_notes_view")) {
          col.push({
            name: "Notes",
            options: {
              filter: false,
              sort: false,
              empty: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                const lead_id =
                  tableMeta.tableData[tableMeta.rowIndex]["lead_id"];
                const contact_id =
                  tableMeta.tableData[tableMeta.rowIndex]["contact_id"];

                return (
                  <Notes
                    key={lead_id}
                    leadId={lead_id}
                    contactId={contact_id}
                    contact
                  />
                );
              },
            },
          });
        }

        col.push({
          name: "Details",
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <LeadDetails
                  leadId={tableMeta.rowData[0]}
                  leadDetails={tableMeta.tableData[tableMeta.rowIndex]}
                  contact
                />
              );
            },
          },
        });

        if (getAccessFor("edit_contact")) {
          col.push({
            name: "Edit",
            options: {
              filter: false,
              sort: false,
              empty: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <AddToContact
                    leadDetails={tableMeta.tableData[tableMeta.rowIndex]}
                    edit
                  />
                );
              },
            },
          });
        }

        if (getAccessFor("remove_contacts")) {
          col.push({
            name: "Remove",
            options: {
              filter: false,
              sort: false,
              empty: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <RemoveContact
                    lead={tableMeta.tableData[tableMeta.rowIndex]}
                  />
                );
              },
            },
          });
        }

        if (getAccessFor("contact_follow_up")) {
          col.push({
            name: "",
            options: {
              filter: false,
              sort: false,
              empty: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <ContactsMore
                    lead={tableMeta.tableData[tableMeta.rowIndex]}
                    getFUB={getFUB}
                    getForm={getFormDetails}
                  />
                );
              },
            },
          });
        }

        setColumns(col);
        setLoadingFields(false);
      });
    }
  }, [selectedFormId, accountsList.length, selectedAccount]);

  useEffect(() => {
    if (selectedFormId && getAccessFor("view_contacts")) {
      setLoading(true);
      dispatch(getContacts(true)).then((res) => {
        setLoading(false);
      });
    }
  }, [selectedAccount, selectedFormId, page, rowsPerPage, refresh]);

  const onFormPageChange = (e, value) => {
    e.stopPropagation();
    dispatch(setSelectedFormId("all"));
    dispatch(setFormPage(value - 1));
    dispatch(getFormsList());
  };

  return (
    <>
      <Helmet>
        <title>Contacts</title>
        <meta name="description" content="Contacts page" />
      </Helmet>
      <AccessControl name="view_contacts" message>
        <div className="page_t_head">
          <div className="page_t_wrapper">
            <Typography className="page_title">Contacts</Typography>
            <AccessControl name="contact_creation">
              <AddToContact leadDetails={{}} edit create />
            </AccessControl>
          </div>
        </div>
        <div className="customers-page contacts_container">
          <div className="contacts_header">
            <div className="filter_section">
              <div className="form_selection_text">
                {/* <Typography style={{ marginRight: 15 }} variant="h6">Forms</Typography> */}
                <Select
                  className="selectStyle"
                  id="grouped-select"
                  name="form"
                  margin="dense"
                  value={selectedFormId}
                  onChange={handleFormSelection}
                  variant="outlined"
                  displayEmpty
                  style={{ minWidth: 200 }}
                >
                  <MenuItem value="all">All</MenuItem>
                  {formsList.map((item, i) => (
                    <MenuItem key={i} value={item.id}>
                      {" "}
                      <em>{item.title}</em>
                    </MenuItem>
                  ))}
                  <ListSubheader>
                    <Pagination
                      color="primary"
                      variant="outlined"
                      count={Math.ceil(formTotal / 10)}
                      page={formPage + 1}
                      onChange={onFormPageChange}
                      siblingCount={0}
                      boundaryCount={1}
                    />
                  </ListSubheader>
                </Select>
              </div>
              <div className="form_selection_text">
                {/* <Typography style={{ marginRight: 15, marginLeft: 15 }} variant="h6" >Accounts</Typography> */}
                <Select
                  className="selectStyle"
                  id="grouped-select"
                  name="form"
                  margin="dense"
                  value={selectedAccount}
                  onChange={handleAccountSelection}
                  variant="outlined"
                  displayEmpty
                  style={{ minWidth: 200 }}
                >
                  <MenuItem value="">All Accounts</MenuItem>
                  {accountsList.map((item, i) => (
                    <MenuItem key={i} value={item.account_id}>
                      {" "}
                      <em>{item.name}</em>
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <SearchInput setIsLoading={setLoading} contact />
            </div>
          </div>
          {loading || loadingFields ? <LinearProgress /> : null}
          <MUIDataTable
            options={tableOptions}
            columns={columns}
            data={contacts}
            className={`tble-bdr ${
              loading || loadingFields ? "tble-on-load" : ""
            }`}
          />
        </div>
      </AccessControl>
    </>
  );
}

export default Contacts;
