import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Divider,
  LinearProgress,
  Select,
  MenuItem,
  Collapse,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  InfoTwoTone as InfoTwoToneIcon,
} from "@mui/icons-material";
import DialogTitle from "../../../components/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { uploadLeads } from "../../../redux/modules/leads";
import { getCsvHeaders, truncateString } from "../../../utils";
import { setAlert } from '../../../redux/modules/alert';

function ImportLeads({ formId }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState("");
  const formsList = useSelector((state) => state.leads.formsList);
  const formFields = useSelector((state) => state.forms.fields);
  const formSelected = formsList.find((fl) => fl.id === formId) || {};
  const [csvFields, setCSVFields] = useState([]);
  const [mappedData, setMapData] = useState([]);
  const [success, setSuccess] = useState(false);

  const uploadRef = useRef();

  const resetMapping = () => {
    setMapData(
      formFields.map((ff) => ({ ...ff, columnId: "", columnName: "" }))
    );
  };

  useEffect(() => {
    if (open) {
      resetMapping();
    }
  }, [open]);

  useEffect(() => {
    if (!mappedData.length) {
      resetMapping();
    }
  }, [formFields]);

  const onOpen = () => {
    setOpen(true);
    setFile("");
  };

  const onClose = () => {
    setOpen(false);
    setFile("");
    resetMapping();
    setSuccess(false);
  };

  const onFileChange = (e) => {
    setFile("");
    resetMapping();
    const input = e.target.files[0];
    
    if (input.type === "text/csv" || input.type === "application/vnd.ms-excel") {
      const reader = new FileReader();

      reader.onload = function (e) {
          const text = e.target.result;
          setFile(input);
          setCSVFields(getCsvHeaders(text).map(((head, i) => ({ columnId: i + 1, columnName: head }))));
      };
  
      if (input) {
        reader.readAsText(input);
      }
    } else {
      dispatch(setAlert(`Unsupported file format selected.`, "error"))
      uploadRef.current.value = "";
    }

  }

  const onChangeField = (ff) => (e) => {
    setMapData(
      mappedData.map((md) =>
        md.field_id === ff.field_id
          ? { ...md, columnId: e.target.value === "none" ? "" : e.target.value }
          : md
      )
    );
  };

  const onUpload = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("formId", formId);

    const nameField = mappedData.find((md) => md.field_name === "name") || {};
    data.append(
      "nameField",
      JSON.stringify({
        fieldId: nameField.field_id || "",
        fieldName: nameField.field_name || "",
        columnId: nameField.columnId || "",
      })
    );
    data.append(
      "mapped",
      JSON.stringify(
        mappedData.map((md) => ({
          fieldId: md.field_id,
          fieldName: md.field_name,
          columnId: md.columnId,
        }))
      )
    );
    data.append("file", file);

    setUploading(true);
    dispatch(uploadLeads(data))
      .then(() => {
        setUploading(false);
        setSuccess(true);
      })
      .catch(() => {
        setUploading(false);
      });
  };

  const allFieldsMapped = !mappedData.find(md => md.is_required === "true" && !md.columnId) && mappedData.find(md => md.field_name === "name" && md.columnId);

  return (
    <>
      {formId != "all" ? (
        <div className="toolbar-btn">
          <Button variant="outlined" style={{ order: 3, color: "#263238", border: "1px solid #c1c2c4" }} onClick={onOpen}>
            Import
          </Button>
        </div>
      ) : null}

      <Dialog open={open} scroll="body" maxWidth="xs" fullWidth>
        <DialogTitle onClose={onClose} style={{ fontSize: 18, fontWeight: 500 }}>
          Import leads to{" "}
          {
            <span className="form-name">
              {truncateString(formSelected.title, 10)}
            </span>
          }{" "}
          form
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Collapse in={!success}>
            <div className={file ? "" : "disable-field-map-grid"}>
              <div className="mapping-field-head">
                <Typography>Form fields</Typography>
                <Typography>Column fields</Typography>
              </div>

              <Divider />
              {
                mappedData.map((md, m) => <div key={m} className='mapping-field' >
                    <Typography>{md.label}{md.is_required === "true" ? <span className='required-field' >*</span> : ''}</Typography>
                    <Select
                      onChange={onChangeField(md)}
                      style={{ width: 200 }}
                      variant="outlined"
                      margin="dense"
                      value={md.columnId || "none"}
                    >
                      <MenuItem value="none">
                        <em>None</em>
                      </MenuItem>
                      {csvFields.map((cf) => (
                        <MenuItem value={cf.columnId}>{cf.columnName}</MenuItem>
                      ))}
                    </Select>
                  </div>
                )
              }
            </div>
            <Divider />
            <div className="import-info">
              <InfoTwoToneIcon fontSize="small" />
              {file ? (
                <Typography variant="caption">
                  {" "}
                  Match all required{
                    <span className="required-field">*</span>
                  }{" "}
                  form fields with the appropriate column fields to import.
                </Typography>
              ) : (
                <Typography variant="caption" style={{ marginTop: 3 }}>
                  {" "}
                  Choose a csv file with maximum of 1000 entries to import as
                  leads.
                </Typography>
              )}
            </div>
            <form className='lead-upload-form' onSubmit={onUpload} >
              <input ref={uploadRef} type="file" id="csvFile" accept=".csv" onChange={onFileChange} />
              <Button variant="outlined" type="submit" disabled={!allFieldsMapped} >Import</Button>
            </form>
          </Collapse>
          <Collapse in={success}>
            <div className="action-success">
              <CheckCircleIcon className="success-color" fontSize="large" />
              <Typography className="success-container marginT15">
                Leads imported successfully.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: 15 }}
                onClick={onClose}
              >
                CLOSE
              </Button>
            </div>
          </Collapse>
        </DialogContent>
        <Divider />
        <div className="lp-deals">{uploading ? <LinearProgress /> : null}</div>
      </Dialog>
    </>
  );
}

export default ImportLeads;
