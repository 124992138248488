import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  Button,
  Dialog,
  CardContent,
  CardActions,
  LinearProgress,
  Collapse,
  DialogActions,
  IconButton,
  Paper,
  Popover,
  Divider,
  DialogContent,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import {
  getDealPipelines,
  getAllDealStages,
  addDealStage,
  updateDealStage,
  deleteDealStage,
  savePipeline,
  getAPipeline,
  deleteAPipeline,
  updatePipeline,
} from "../../../redux/modules/deals";
import AccessControl from "../../../components/AccessControl";
import DialogTitle from "../../../components/DialogTitle";

function checkEmptyIn(stages) {
  return !!stages.filter((s) => !s.stage_name).length;
}

function StageItem({
  stage,
  index,
  cancelNew,
  isNew,
  addNew,
  update,
  remove,
  updateDealStagesState,
}) {
  const [editable, setEditable] = useState(isNew);
  const [name, setName] = useState(stage.stage_name);
  const [disableSave, setDisableSave] = useState(!name);
  const [anchorEl, setAnchorEl] = useState(null);
  const [count, setCount] = useState(0);

  const handleStageNameChange = (e) => {
    const value = e.target.value;
    const save = !value.trim() || value.trim() === stage.stage_name;
    if (disableSave != save) {
      setDisableSave(save);
    }
    setName(value);
  };

  const onEdit = () => {
    setEditable(true);
    setDisableSave(true);
  };

  const onCancel = () => {
    if (isNew) {
      cancelNew(index);
    } else {
      setEditable(false);
      setName(stage.stage_name);
    }
  };

  const onDelete = (e) => {
    e.persist();
    setAnchorEl(e.currentTarget);
    remove(stage.stage_id)
      .then((dss) => {
        updateDealStagesState(dss);
      })
      .catch((error) => {
        if (error.status === 0) {
          setCount(error.count);
        }
      });
  };

  const onSave = () => {
    if (isNew) {
      addNew(name).then((dss) => {
        setEditable(false);
        updateDealStagesState(dss);
      });
    } else {
      update({ ...stage, stage_name: name }).then((dss) => {
        setEditable(false);
        updateDealStagesState(dss);
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="role_item">
      {editable ? (
        <TextField
          size="small"
          className="text-field"
          name="item"
          label="Stage"
          variant="outlined"
          onChange={handleStageNameChange}
          value={name}
          required
        />
      ) : (
        <Typography className="role_name">{stage.stage_name}</Typography>
      )}
      {editable ? (
        <div className="save_cancel_wrapper">
          <Button onClick={onSave} disabled={disableSave}>
            Save
          </Button>
          <Button onClick={onCancel} style={{ color: "#000" }}>
            Cancel
          </Button>
        </div>
      ) : (
        <div className="save_cancel_wrapper">
          <Button className="bdr-btn-style-green" onClick={onEdit}>
            Edit
          </Button>
          <Button className="bdr-btn-style-chu" onClick={onDelete}>
            Delete
          </Button>
          <Popover
            id={stage.stage_id}
            open={!!count && !!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography
              style={{ padding: 15, maxWidth: 200 }}
            >{`You cannot delete this deal stage, since ${`${count} ${
              Number(count) === 1 ? "deal pipeline is" : "deal pipelines are"
            }`} using this stage.`}</Typography>
          </Popover>
        </div>
      )}
    </div>
  );
}

function PipelineForm({
  cancel,
  pipeline_id,
  dealStages,
  pipeline_stages,
  pipeline_name,
  default: defaultValue,
  deal_expiry,
  newPipe,
}) {
  const [name, setName] = useState(newPipe ? "" : pipeline_name || "");
  const [dealExpiry, setDealExpiry] = useState(deal_expiry || 0);
  const [defaultPipe, setDefaultPipe] = useState(!!Number(defaultValue));
  const [dStages, setDStages] = useState(
    pipeline_stages || [{ stage_name: "", probability: 0 }]
  );
  const [disableAS, setDisableAS] = useState(checkEmptyIn(dStages));
  const [disableSave, setDisableSave] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();

  const onStageNameChange = (value, s) => {
    const existInOptions = dealStages.find((ds) => ds.stage_name == value);
    if (existInOptions) {
      const uStages = [...dStages];
      uStages[s] = { ...dStages[s], ...existInOptions };

      return setDStages(uStages);
    }
    const valueNotChanged =
      pipeline_stages &&
      pipeline_stages[s] &&
      pipeline_stages[s]["stage_name"] === dStages[s]["stage_name"];
    const newStageID = !valueNotChanged ? { stage_id: "" } : {};

    const updatedStages = [...dStages];
    updatedStages[s] = { ...dStages[s], ...newStageID, stage_name: value };
    setDStages(updatedStages);
    setDisableAS(checkEmptyIn(updatedStages));
    setDisableSave(!name || checkEmptyIn(updatedStages));
  };

  const onStageNameSelect = (e, v, s) => {
    const updatedStages = [...dStages];
    updatedStages[s] = {
      ...dStages[s],
      ...(v || { stage_name: "", stage_id: "" }),
    };
    setDisableAS(checkEmptyIn(updatedStages));
    setDStages(updatedStages);
    setDisableSave(checkEmptyIn(updatedStages));
  };

  const onAddNewStage = () => {
    const updatedStages = [...dStages];
    updatedStages.push({ stage_name: "", probability: 0 });
    setDStages(updatedStages);
    setDisableAS(checkEmptyIn(updatedStages));
    setDisableSave(!name || checkEmptyIn(updatedStages));
  };

  const onStageRemove = (s) => {
    const updatedStages = [...dStages];
    updatedStages.splice(s, 1);
    setDStages(updatedStages);
    setDisableAS(checkEmptyIn(updatedStages));

    setDisableSave(
      !name || !updatedStages.length || checkEmptyIn(updatedStages)
    );
  };

  const onProbChange = (value, s) => {
    const updatedStages = [...dStages];
    updatedStages[s] = { ...dStages[s], probability: value };

    setDStages(updatedStages);
    setDisableAS(checkEmptyIn(updatedStages));
  };

  const onPipelineNameChange = (e) => {
    const newName = e.target.value;

    setName(newName);
    setDisableSave(!newName || checkEmptyIn(dStages));
  };

  const onDefaultCheck = (e) => {
    setDefaultPipe(!!e.target.checked);
  };

  const onExpiryChange = (e) => {
    setDealExpiry(e.target.value);
  };

  const onFormCancel = () => {
    cancel();
    setDStages(pipeline_stages || [{ stage_name: "", probability: 0 }]);
  };

  const resetState = () => {
    setName("");
    setDefaultPipe(false);
    setDealExpiry(0);
    setDStages([{ stage_name: "", probability: 0 }]);
  };

  const onSaveNewPipeline = () => {
    // setIsSaving(true);
    const newPipeline = {
      pipeline_name: name,
      deal_expiry: dealExpiry,
      default: defaultPipe ? 1 : 0,
      pipeline_stages: dStages,
    };
    let promise = Promise.resolve();

    if (newPipe) {
      promise = dispatch(savePipeline(newPipeline)).then(() => resetState());
    } else {
      promise = dispatch(updatePipeline({ pipeline_id, ...newPipeline }));
    }

    promise.then(() => {
      // setIsSaving(false);

      dispatch(getAllDealStages());
      cancel();
    });
  };

  const autoSuggestStages = dealStages.filter(
    (dsf) => !dStages.filter((dss) => dss.stage_id == dsf.stage_id).length
  );

  return (
    <Card style={{ marginBottom: 15 }}>
      <CardContent>
        <Typography variant="h6" style={{ marginBottom: 15 }}>
          {newPipe ? "Add new pipeline" : "Update pipeline"}
        </Typography>
        <Typography>Pipeline Name</Typography>
        <TextField
          size="small"
          variant="outlined"
          margin="dense"
          style={{ width: "100%" }}
          onChange={onPipelineNameChange}
          value={name}
        />
        <Checkbox onChange={onDefaultCheck} checked={defaultPipe} />
        <Typography component="span">Mark as default pipeline</Typography>
        <Typography>Deal Expiry</Typography>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            size="small"
            variant="outlined"
            margin="dense"
            type="number"
            onChange={onExpiryChange}
            value={dealExpiry}
          />
          <Typography component="span" style={{ marginLeft: 15 }}>
            Days
          </Typography>
        </div>
        <Typography>Deal Stages</Typography>
        <Paper
          style={{
            display: "grid",
            gridTemplateColumns: "65% 25% 10%",
            padding: 16,
            marginTop: 10,
          }}
        >
          <Typography variant="caption">Stages</Typography>
          <Typography variant="caption" style={{ marginLeft: 10 }}>
            Probability (%)
          </Typography>
          <Typography variant="caption"></Typography>
          {dStages.map((st, s) => (
            <React.Fragment key={s}>
              <Autocomplete
                onChange={(e, v) => onStageNameSelect(e, v, s)}
                onInputChange={(e, v) => onStageNameChange(v, s)}
                options={autoSuggestStages}
                getOptionLabel={(option) => option.stage_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    margin="dense"
                  />
                )}
                value={st}
                freeSolo
              />
              <TextField
                size="small"
                variant="outlined"
                margin="dense"
                type="number"
                style={{ marginLeft: 10 }}
                onChange={(e) => onProbChange(e.target.value, s)}
                value={st.probability}
              />
              <IconButton
                style={{ marginLeft: 10 }}
                onClick={() => onStageRemove(s)}
                disabled={Number(st.used) > 0}
              >
                <RemoveCircleOutline />
              </IconButton>
            </React.Fragment>
          ))}
          <div>
            <IconButton
              className="stages_add_wrapper"
              onClick={onAddNewStage}
              style={{ padding: 0 }}
              disabled={disableAS}
            >
              <AddCircleOutline />
              <Typography variant="caption" style={{ marginLeft: 5 }}>
                Add Stage
              </Typography>
            </IconButton>
          </div>
        </Paper>
        {isSaving ? <LinearProgress /> : null}
        <CardActions>
          <Button color="primary" onClick={onFormCancel}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={onSaveNewPipeline}
            disabled={disableSave}
          >
            Save Pipeline
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}

function PipelineComp({ pipeline, dealStages }) {
  const [openPipe, setOpenPipe] = useState(false);
  const [editPipe, setEditPipe] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onCancelEdit = () => {
    setEditPipe(false);
  };

  const onDeletePipeline = (e) => {
    const deleteBtn = e.currentTarget;

    dispatch(deleteAPipeline(pipeline.pipeline_id)).catch((error) => {
      setCount(error.count);
      setAnchorEl(deleteBtn);
    });
  };

  const onOpnPipelineClick = () => {
    if (!pipeline.pipeline_stages) {
      setLoading(true);
      dispatch(getAPipeline(pipeline.pipeline_id)).then(() => {
        setLoading(false);
      });
    }

    setOpenPipe(!openPipe);
    setEditPipe(false);
  };

  const onPipelineEditClick = () => {
    if (!pipeline.pipeline_stages) {
      setLoading(true);
      dispatch(getAPipeline(pipeline.pipeline_id)).then(() => {
        setLoading(false);
      });
    }

    setEditPipe(!editPipe);
    setOpenPipe(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card className="pipeline_card">
      <div className="pipeline_contents">
        <CardContent>
          <Typography gutterBottom variant="h6" component="h2">
            {pipeline.pipeline_name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Deal rots after {pipeline.deal_expiry} days
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" onClick={onOpnPipelineClick}>
            {openPipe ? "Close" : "View"}
          </Button>
          <Button size="small" color="primary" onClick={onPipelineEditClick}>
            Edit
          </Button>
          <Button size="small" color="primary" onClick={onDeletePipeline}>
            Delete
          </Button>
          <Popover
            id={pipeline.pipeline_id}
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography style={{ padding: 15, maxWidth: 200 }}>
              Since {count} {count === "1" ? "deal is" : "deals are"} using this
              Pipeline, you cannot delete this pipeline right now.
            </Typography>
          </Popover>
        </CardActions>
      </div>
      <CardContent style={{ padding: "0 16px" }}>
        <Collapse in={openPipe}>
          <Paper className="pipeline_list_wrapper">
            <List className="settings_list">
              {pipeline.pipeline_stages ? (
                pipeline.pipeline_stages.map((stage, s) => (
                  <ListItem key={s} className="list_item">
                    <ListItemText
                      primary={<Typography>{stage.stage_name}</Typography>}
                      secondary={
                        <Typography variant="caption">
                          Probability {stage.probability}%
                        </Typography>
                      }
                    />
                    {/* <Button autoFocus onClick={() => {}} color="primary">Change</Button> */}
                  </ListItem>
                ))
              ) : (
                <LinearProgress style={{ margin: 3 }} />
              )}
            </List>
          </Paper>
        </Collapse>
        <Collapse in={editPipe}>
          {pipeline.pipeline_stages ? (
            <PipelineForm
              dealStages={dealStages}
              {...pipeline}
              cancel={onCancelEdit}
            />
          ) : null}
          {loading ? <LinearProgress style={{ margin: 3 }} /> : null}
        </Collapse>
      </CardContent>
    </Card>
  );
}

function DealsPipeline() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openStages, setOpenStages] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const pipelines = useSelector((state) => state.deals.pipelines);
  const dealStages = useSelector((state) => state.deals.dealStages);
  const [dStages, setDStages] = useState(dealStages);
  const loading = useSelector((state) => state.deals.loading);

  useEffect(() => {
    if (openStages) {
      dispatch(getAllDealStages()).then((dealStagesFromStore) => {
        setDStages(dealStagesFromStore);
      });
    }

    if (open) {
      let stagePromise = Promise.resolve();

      if (!dealStages.length) {
        stagePromise = dispatch(getAllDealStages()).then(
          (dealStagesFromStore) => {
            setDStages(dealStagesFromStore);

            return Promise.resolve();
          }
        );
      }

      stagePromise.then(() => {
        dispatch(getDealPipelines());
      });
    }
  }, [open, openStages]);

  const onChangePipelineClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onAddNewClick = () => {
    setAddNew(true);
  };

  const cancelAddNew = () => {
    setAddNew(false);
  };

  const onOpenStagesClick = () => {
    setOpenStages(true);
  };

  const onStagesClose = () => {
    setOpenStages(false);
  };

  const updateDealStagesState = (dss) => {
    setDStages(dss);
  };

  const onCancelNew = (i) => {
    const updatedStages = [...dStages];
    updatedStages.splice(i, 1);

    setDStages(updatedStages);
  };

  const onAddNewStage = () => {
    const updatedStages = [...dStages];
    updatedStages.push({
      stage_name: "",
    });

    setDStages(updatedStages);
  };


  return (
    <div>
      <Card className="list_paper">
        <List className="settings_list">
          <AccessControl name="add_edit_deal_stages">
            <ListItem className="list_item">
              <ListItemText
                primary={<Typography>Deal Stages</Typography>}
                secondary={
                  <Typography variant="caption">
                    You can add or edit Deal stages here.
                  </Typography>
                }
              />
              <Button
                autoFocus
                onClick={onOpenStagesClick}
                color="primary"
                className="sec-button"
              >
                Change
              </Button>
            </ListItem>
          </AccessControl>
          <AccessControl name="add_edit_deal_pipelines">
            <ListItem className="list_item">
              <ListItemText
                primary={<Typography>Pipeline</Typography>}
                secondary={
                  <Typography variant="caption">
                    You can add or edit Pipelines here.
                  </Typography>
                }
              />
              <Button
                autoFocus
                onClick={onChangePipelineClick}
                color="primary"
                className="sec-button"
              >
                Change
              </Button>
            </ListItem>
          </AccessControl>
        </List>
      </Card>
      <Dialog open={openStages} onClose={onStagesClose}>
        <DialogTitle onClose={onStagesClose}>Deal Stages</DialogTitle>
        <Divider />
        <DialogContent>
          {dStages?.length ? (
            dStages.map((ds, l) => (
              <StageItem
                key={l}
                index={l}
                stage={ds}
                isNew={!ds.stage_name}
                cancelNew={onCancelNew}
                addNew={(name) => dispatch(addDealStage(name))}
                update={(stageDetails) =>
                  dispatch(updateDealStage(stageDetails))
                }
                remove={(id) => dispatch(deleteDealStage(id))}
                updateDealStagesState={updateDealStagesState}
                total={dealStages?.length}
              />
            ))
          ) : (
            <Typography
              variant="caption"
              component="div"
              className="empty-message"
            >
              No deal stages available to show...Add one now.
            </Typography>
          )}
          {loading ? <LinearProgress /> : null}
          <div className="role_add_btn_wrapper">
            <Button
              className="role_add_button"
              onClick={onAddNewStage}
              disabled={dealStages?.length !== dStages?.length}
            >
              <AddCircleOutline />
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={open} onClose={onClose} scroll="body">
        <DialogTitle onClose={onClose}>Pipelines</DialogTitle>
        <Divider />
        <DialogContent>
          {pipelines.length ? (
            pipelines.map((pl, i) => (
              <PipelineComp key={i} pipeline={pl} dealStages={dealStages} />
            ))
          ) : loading || addNew ? null : (
            <Typography
              variant="caption"
              component="div"
              className="empty-message"
            >
              No pipelines to show...Add one now.
            </Typography>
          )}
          <Collapse in={addNew}>
            <PipelineForm
              dealStages={dealStages}
              cancel={cancelAddNew}
              newPipe
            />
          </Collapse>
        </DialogContent>
        {loading ? <LinearProgress /> : null}
        <DialogActions className="pipeline_add_button_wrapper">
          <IconButton onClick={onAddNewClick} disabled={addNew}>
            <AddCircleOutline fontSize="large" />
          </IconButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DealsPipeline;
