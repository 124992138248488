import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  DialogContent,
  Divider,
  Avatar,
  Chip,
  Tooltip,
  Popover,
  Collapse,
  Button,
  LinearProgress,
  IconButton,
  Dialog,
} from "@mui/material";
import DialogTitle from "../../../components/DialogTitle";
import {
  Business as BusinessIcon,
  Face as FaceIcon,
  ArrowRightAlt as ArrowRightAltIcon,
  Description as DescriptionIcon,
  Send as SendIcon,
  Timeline as TimelineIcon,
} from "@mui/icons-material";
import Options from "./Options";
import DealForm from "../../../components/DealForm/DealForm";
import { useDispatch, useSelector } from "react-redux";
import { clearDealTL, getADeal } from "../../../redux/modules/deals";
import AccessControl from "../../../components/AccessControl";
import getAccessFor from "../../../helpers/getAccessFor";
import DealNotes from "./DealNotes";
import DealTimeline from "./DealTimeline";
import { getAContactDetails } from "../../../redux/modules/leads";

function TaggedContacts({ contacts }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const contactDetails = useSelector((state) => state.leads.contactDetails);
  const [loading, setLoading] = useState(false);

  const handleClick = (event, cont) => {
    setLoading(true);
    dispatch(getAContactDetails(cont.contact_id))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="related-contacts-group">
      {contacts.map((cont, c) => (
        <Chip
          key={c}
          variant="outlined"
          color="primary"
          size="small"
          label={cont.name}
          avatar={
            <Avatar className="contact-avatar">{cont.name.slice(0, 1)}</Avatar>
          }
          onClick={(e) => handleClick(e, cont)}
          clickable
        />
      ))}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="contact-details-popover">
          {loading ? (
            <LinearProgress />
          ) : (
            <>
              <Typography className="contact__head" component="h3">
                Contact Name
              </Typography>
              <Typography className="contact__value">
                {(contactDetails && contactDetails.contact_name) || ""}
              </Typography>
              {contactDetails && contactDetails.emails.length ? (
                <Typography className="contact__head" component="h3">
                  Emails
                </Typography>
              ) : null}
              {contactDetails &&
                contactDetails.emails.map((email, t) => (
                  <Typography key={t} className="contact__value">
                    {email}
                  </Typography>
                ))}
              {contactDetails && contactDetails.mobiles.length ? (
                <Typography className="contact__head" component="h3">
                  Phone Numbers
                </Typography>
              ) : null}
              {contactDetails &&
                contactDetails.mobiles.map((mobile, m) => (
                  <Typography key={m} className="contact__value">
                    {mobile}
                  </Typography>
                ))}
            </>
          )}
        </div>
      </Popover>
    </div>
  );
}

function DealItem({ deal, stage, account = {}, currencies }) {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const dealDetails = useSelector((state) => state.deals.dealDetails);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [openNotes, setON] = useState(false);
  const [openTL, setTL] = useState(false);

  const onOpenDetails = () => {
    setLoading(true);
    dispatch(getADeal(deal.deal_id)).then(() => {
      setLoading(false);
      setOpen(true);
    });
  };

  const onDetailsClose = () => {
    setOpen(false);
    setEdit(false);
    setON(false);
    setTL(false);
    dispatch(clearDealTL());
  };

  const onNotesClick = () => {
    setON(!openNotes);
    setTL(false);
  };

  const onTimelineClick = () => {
    setTL(!openTL);
    setON(false);
  };

  const owner =
    ((dealDetails && dealDetails.tagged_contacts) || []).find(
      (tc) => tc.owner === "1"
    ) || {};
  const currency =
    currencies.find((c) => c.code === deal.deal_value_currency) || {};

  return (
    <>
      <Paper>
        <div className="deal-list-item">
          <div onClick={onOpenDetails} style={{ flexGrow: 4 }}>
            <Typography className="deal-title" variant="h6">
              {deal.deal_name}
            </Typography>
            <div className="deal-content">
              <Typography
                className="deal-value"
                variant="h6"
                style={{ marginTop: 10 }}
              >
                {currency.symbol}
                {deal.deal_value}
              </Typography>
              <Divider orientation="vertical" flexItem />
              <Typography className="deal-account" variant="caption">
                {account.name}
              </Typography>
            </div>
          </div>
          <div>
            {getAccessFor("delete_deals") ? (
              <Options deal_id={deal.deal_id} setLoading={setLoading} />
            ) : null}
          </div>
        </div>
        {loading ? <LinearProgress /> : null}
      </Paper>
      <Dialog open={open} onClose={onDetailsClose} scroll="body" maxWidth="xs">
        <Collapse in={!edit}>
          <DialogTitle onClose={onDetailsClose}>Deal Summary</DialogTitle>
          <Divider />
          <DialogContent>
            <div className="deal-name-wrapper">
              <Typography className="deal-summary-name" variant="h6">
                {deal.deal_name}
              </Typography>
              <div>
                <AccessControl name="edit_deals">
                  <Button
                    className="bdr-btn-style-green"
                    color="primary"
                    onClick={() => setEdit(true)}
                  >
                    Edit Deal
                  </Button>
                </AccessControl>
              </div>
            </div>
            <div className="deal-summary-basic">
              <BusinessIcon />
              <Tooltip title="Account" placement="top">
                <Typography className="deal-account icon-text">
                  {account.name}
                </Typography>
              </Tooltip>
              <Divider
                className="summary-divider"
                orientation="vertical"
                flexItem
              />
              <FaceIcon />
              <Tooltip title="Owner" placement="top">
                <Typography className="icon-text">{owner.name}</Typography>
              </Tooltip>
              <Divider
                className="summary-divider"
                orientation="vertical"
                flexItem
              />
              <Tooltip title="Deal Value" placement="top">
                <Typography variant="h5">
                  <span className="currency_sbl">{currency.symbol}</span>
                  {deal.deal_value}
                </Typography>
              </Tooltip>
            </div>
            <div className="related-contacts">
              <Typography className="related-contacts-text">
                Related contacts
              </Typography>
              <TaggedContacts
                contacts={(dealDetails && dealDetails.tagged_contacts) || []}
              />
            </div>
            <div className="deal-summary-basic deal-stage-show">
              <Typography>Deal stage</Typography>
              <ArrowRightAltIcon />
              <Typography variant="subtitle2"> {stage}</Typography>
            </div>
            <Divider />
            <div>
              <IconButton onClick={onNotesClick}>
                <DescriptionIcon
                  className={openNotes ? "window-active-icon" : ""}
                />
              </IconButton>
              <IconButton onClick={onTimelineClick}>
                <TimelineIcon className={openTL ? "window-active-icon" : ""} />
              </IconButton>
            </div>
            <Collapse in={openTL}>
              <DealTimeline dealId={deal.deal_id} open={openTL} />
            </Collapse>
            <Collapse in={openNotes}>
              <Divider />
              <DealNotes dealId={deal.deal_id} />
            </Collapse>
          </DialogContent>
        </Collapse>
        <Collapse in={edit}>
          <DialogTitle onClose={onDetailsClose}>Edit Deal</DialogTitle>
          <Divider />
          <DialogContent>
            <DealForm
              deal={{ ...deal, ...(dealDetails || {}) }}
              cancel={() => setEdit(false)}
              edit
            />
          </DialogContent>
        </Collapse>
      </Dialog>
    </>
  );
}

export default DealItem;
