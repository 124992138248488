import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  clearNotifications,
  deleteAllNotifications,
  deleteANoti,
} from "../../../../redux/modules/auth";
import {
  Badge,
  IconButton,
  Typography,
  Popper,
  Fade,
  Paper,
  Divider,
  Button,
  Tooltip,
} from "@mui/material";
import {
  Notifications as NotificationsIcon,
  Close as CloseIcon,
  DeleteOutline as DeleteOutlineIcon,
} from "@mui/icons-material";
import { getDateTimeWith12R } from "../../../../utils/format_date";
import { getNotifications } from "../../../../redux/modules/auth";
import { Link } from "react-router-dom";

export default function NotiBell() {
  const channelName = useSelector((state) => state.auth.channelName);
  const notifications = useSelector((state) => state.auth.notifications);
  const aToken = useSelector((state) => state.auth.aToken);
  const [openNoti, setOpenNoti] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    let notiTimer = null;

    if (!notifications.length && aToken && channelName && !notiTimer) {
      dispatch(getNotifications());
      notiTimer = setInterval(() => dispatch(getNotifications()), 10000);
    }

    if (!aToken && notiTimer) {
      clearInterval(notiTimer);
    }

    return () => {
      if (notiTimer) {
        clearInterval(notiTimer);
      }
    };
  }, [channelName, aToken]);

  const notiClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenNoti((prev) => !prev);
  };

  const onClearNoti = () => {
    dispatch(clearNotifications());
    dispatch(deleteAllNotifications());
    setOpenNoti(false);
  };

  const onEachNotiClear = (noti) => {
    dispatch(
      deleteANoti(
        noti.notification_id || "",
        noti.error_log_id || "",
        noti.release_id || ""
      )
    ).then(() => {
      dispatch(getNotifications());
    });
  };

  return (
    <>
      <Popper
        className="noti-popper"
        open={openNoti}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className="noti-list">
              <div className="notis-container">
                {notifications.length ? (
                  notifications.map((noti, n) => (
                    <div key={n} className="noti-wrapper">
                      {noti.link ? (
                        <Link
                          className="each-noti-msg link-noti"
                          onClick={() => {
                            setOpenNoti(false);
                          }}
                          to={noti.link}
                        >
                          {noti.message}
                        </Link>
                      ) : (
                        <Typography key={n} className="each-noti-msg">
                          {noti.message}
                        </Typography>
                      )}
                      {noti.created_time ? (
                        <Typography
                          variant="caption"
                          className="noti-datetime"
                          component="div"
                        >
                          {getDateTimeWith12R(noti.created_time)}
                          <Tooltip title="Remove this Notification">
                            <IconButton
                              className="each-clear-btn"
                              onClick={() => onEachNotiClear(noti)}
                            >
                              <DeleteOutlineIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      ) : null}
                    </div>
                  ))
                ) : (
                  <Typography
                    className="empty-noti-msg"
                    component="div"
                    variant="caption"
                  >
                    No notifications available...
                  </Typography>
                )}
              </div>
              <div className="noti-tools">
                {notifications.length ? (
                  <Button className="noti-clr-btn" onClick={onClearNoti}>
                    Clear
                  </Button>
                ) : null}
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>
      <IconButton color="inherit" style={{ marginLeft: 8 }} onClick={notiClick}>
        <Badge badgeContent={notifications.length} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
    </>
  );
}
