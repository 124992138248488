import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Typography, Link, Grid as Box } from "@mui/material";

const Footer = (props) => {
  const { className, ...rest } = props;
  
  return (
    <div 
    {...rest} 
    className={clsx(className)}
    style={{paddingBottom: 100}}
    >
      <Box component="img" style={{ height: 96 }} src="/footer-img.png" />
      <Typography sx={{fontSize: 14, minWidth: "150px"}}>&copy; FormsLeads, 2021</Typography>
      <Typography variant="caption">
        Developed by{" "}
        <Link
          component="a"
          href="https://incrediblevisibility.com/"
          target="_blank"
        >
          IVS
        </Link>
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
