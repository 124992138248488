import React from "react";
import { Helmet } from "react-helmet";
import {
  Typography,
  Container,
  Paper,
  Box,
  Card,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/modules/auth";
import config from "../../config";

const Accounts = () => {
  const dispatch = useDispatch();
  const emailId = useSelector((state) => state.auth.emailId);
  const accounts = useSelector((state) => state.auth.accounts);
  const token = useSelector((state) => state.auth.token);

  return (
    <div className="auth" style={{ backgroundImage: "url(/login-bg.png)" }}>
      <Helmet>
        <title>Accounts</title>
        <meta name="description" content="Accounts" />
      </Helmet>
      <Container maxWidth="xs">
        <div className="logodiv">
          <Box
            component="img"
            style={{ height: 81, marginBottom: 20 }}
            src="/logo-FL.png"
          />
        </div>
        <Paper className="auth_paper">
          <Typography variant="h6" className="auth__lead">
            Accounts for <span className="value__chip">{emailId}</span>
          </Typography>
          <div className="auth__form" style={{ paddingTop: 25 }}>
            <Card className="list_paper">
              <List className="settings_list">
                {accounts.map((acc, a) => {
                  const accountURL = process.env.REACT_APP_STAGING
                    ? `${window.location.protocol}//${acc.domain}.${window.location.host}/staging/home/${token}`
                    : `${window.location.protocol}//${acc.domain}.${window.location.host}/home/${token}`;

                  return (
                    <ListItem className="list_item" key={a}>
                      <ListItemText
                        primary={<Typography>{acc.name}</Typography>}
                        secondary={
                          <Typography variant="caption">
                            {config.userTypes[acc.user_type]}
                          </Typography>
                        }
                      />
                      <a
                        color="primary"
                        className="sec-button"
                        href={accountURL}
                        target="_blank"
                      >
                        Launch
                      </a>
                    </ListItem>
                  );
                })}
              </List>
            </Card>
            <Button
              className="auth__form__button"
              type="submit"
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => dispatch(logout())}
            >
              LOG OUT
            </Button>
          </div>
        </Paper>
        <p className="copyright">
          &copy; 2022 Form Leads | All Rights Reserved
        </p>
      </Container>
    </div>
  );
};

export default Accounts;
